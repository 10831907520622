import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { StepContent } from '~/components'

import JornadaNormal from './components/JornadaNormal'
import JornadaSabadoFeriado from './components/JornadaSabadoFeriado'
import JornadaSemanaFeriado from './components/JornadaSemanaFeriado'

import useDialogNotification from '~/hooks/useDialogNotification'

import { diaHorarioConsts } from '~/values/diaHorarioValues'
import { getDateCurrent } from '~/utils/utils'

function formatSimpleHour(hour) {
  if (!hour) return null
  return `${hour.substring(0, 2)}:${hour.substring(2)}`
}

function changeHorario(diasInicializacao, nameField, horario) {
  const horaEntrada = formatSimpleHour(horario?.horarioTrabalho?.horaEntrada)
  const horaSaida = formatSimpleHour(horario?.horarioTrabalho?.horaSaida)

  const firstIntervalo = horario?.horarioTrabalho?.horarioTrabalhoIntervalo[0] || null

  const inicioIntervalo = formatSimpleHour(firstIntervalo?.inicioIntervalo)
  const fimIntervalo = formatSimpleHour(firstIntervalo?.fimIntervalo)

  diasInicializacao[nameField].horario = horaEntrada
    ? `DE ${horaEntrada} ÀS ${horaSaida}`
    : 'DESCANSO'
  diasInicializacao[nameField].intervalo = inicioIntervalo
    ? `DE ${inicioIntervalo} ÀS ${fimIntervalo}`
    : 'DESCANSO'
}

const initialStateDias = {
  domingo: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
  segunda: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
  terca: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
  quarta: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
  quinta: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
  sexta: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
  sabado: { horario: 'DESCANSO', intervalo: 'DESCANSO' },
}

export default function StepContainer(props) {
  const { onAfterSubmit, data: vinculosIds } = props

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function obterHorarioTrabalho() {
      if (!(vinculosIds?.length > 0)) return
      setLoading(true)
      try {
        const vinculoId = vinculosIds[0]
        const response = await api.get(
          '/Relatorio/CompensacaoHorasTrabalhadas/ObterHorarioTrabalhoFuncionario',
          {
            params: {
              vinculoId,
            },
          },
        )

        const horarios = response?.data?.data?.vinculoHorarioItens || []

        let diasInicializacao = initialStateDias

        horarios.forEach((horario) => {
          switch (horario.diaHorario) {
            case diaHorarioConsts.domingo_7:
              changeHorario(diasInicializacao, 'domingo', horario)
              break
            case diaHorarioConsts.segundaFeira_1:
              changeHorario(diasInicializacao, 'segunda', horario)
              break
            case diaHorarioConsts.terça_Feira_2:
              changeHorario(diasInicializacao, 'terca', horario)
              break
            case diaHorarioConsts.quarta_Feira_3:
              changeHorario(diasInicializacao, 'quarta', horario)
              break
            case diaHorarioConsts.quinta_Feira_4:
              changeHorario(diasInicializacao, 'quinta', horario)
              break
            case diaHorarioConsts.sexta_Feira_5:
              changeHorario(diasInicializacao, 'sexta', horario)
              break
            case diaHorarioConsts.sábado_6:
              changeHorario(diasInicializacao, 'sabado', horario)
              break
            default:
              break
          }
        })

        setData({
          vinculos: vinculosIds,
          jornadaNormal: {
            ...diasInicializacao,
            isUse: false,
            dtEfetiva: getDateCurrent(),
            textoCompensacaoHorasExtraordinarias:
              'As horas trabalhadas após a oitava hora diária de trabalho, destinam-se à compensação dos sábados não trabalhados, não sendo consideradas como horas extras extraordinárias.',
          },
          jornadaSabadoFeriado: {
            ...diasInicializacao,
            isUse: false,
          },
          jornadaSemanaFeriado: { ...diasInicializacao, isUse: false },
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    obterHorarioTrabalho()
    // eslint-disable-next-line
  }, [vinculosIds])

  function handleAfterSubmitJornadaNormal(dt) {
    setData((prevState) => ({
      ...prevState,
      jornadaNormal: dt,
    }))
  }
  function handleAfterSubmitJornadaSabadoFeriado(dt) {
    setData((prevState) => ({
      ...prevState,
      jornadaSabadoFeriado: dt,
    }))
  }
  function handleAfterSubmitJornadaSemanaFeriado(dt) {
    onAfterSubmit({
      ...data,
      jornadaSemanaFeriado: dt,
    })
  }

  return (
    <StepContent
      isLoading={isLoading}
      steps={[
        {
          title: 'Jornada de trabalho normal',
          content: (
            <JornadaNormal onSubmit={handleAfterSubmitJornadaNormal} data={data.jornadaNormal} />
          ),
        },
        {
          title: 'Compensação sábado',
          subtitle: 'Feriado que ocorrer no sábado',
          content: (
            <JornadaSabadoFeriado
              onSubmit={handleAfterSubmitJornadaSabadoFeriado}
              data={data.jornadaSabadoFeriado}
            />
          ),
        },
        {
          title: 'Compensação sábado',
          subtitle: 'Feriado que ocorrer nos dias de trabalhado',
          content: (
            <JornadaSemanaFeriado
              onSubmit={handleAfterSubmitJornadaSemanaFeriado}
              data={data.jornadaSemanaFeriado}
            />
          ),
        },
      ]}
      stepsOptionals={[1, 2]}
    />
  )
}
