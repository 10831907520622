import { convertToArrayDescription } from '~/utils/utils'

export enum CartaOposicaoSindicatoAssuntoEnum {
  ContribuicaoConfederativa,
  ContribuicaoSindical,
  ContribuicaoAssistencial,
}

export const CartaOposicaoSindicatoAssuntoLabels = {
  [CartaOposicaoSindicatoAssuntoEnum.ContribuicaoConfederativa]: 'Contribuição Confederativa',
  [CartaOposicaoSindicatoAssuntoEnum.ContribuicaoSindical]: 'Contribuição Sindical',
  [CartaOposicaoSindicatoAssuntoEnum.ContribuicaoAssistencial]: 'Contribuição Assistêncial',
}

export const CartaOposicaoSindicatoAssuntoValues =
  convertToArrayDescription<CartaOposicaoSindicatoAssuntoEnum>(
    CartaOposicaoSindicatoAssuntoEnum,
    CartaOposicaoSindicatoAssuntoLabels,
  )
