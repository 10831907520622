import { Box, Typography } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { PageHeader, Button } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Form from './components/Form'
import Table, { AcoesTabela } from './components/Table'
import DialogInterrupcao from './components/DialogInterrupcao'
import DialogSaldoFerias from './components/DialogSaldoFerias'
import DialogInformarFim from './components/DialogInformarFim'
import DialogMotivoAfastamento from './components/DialogMotivoAfastamento'

import useDialog from '~/hooks/useDialog'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useAfastamentoDelete } from '~/hooks/queries/VinculoAfastamento/useAfastamentoDelete'
import { useAfastamentoCreate } from '~/hooks/queries/VinculoAfastamento/useAfastamentoCreate'
import { useAfastamentoUpdate } from '~/hooks/queries/VinculoAfastamento/useAfastamentoUpdate'

import { CessaoInformacaoOnusEnum } from '~/@types/enums/CessaoInformacaoOnusEnum'
import { TipoAcidenteTransitoEnum } from '~/@types/enums/TipoAcidenteTransitoEnum'
import { InfoOnusRemuneracaoEnum } from '~/@types/enums/InfoOnusRemuneracaoEnum'
import { OrigemRetificacaoAfastamentoEnum } from '~/@types/enums/OrigemRetificacaoAfastamentoEnum'
import { ProcessoTipoAfastamentoEnum } from '~/@types/enums/ProcessoTipoAfastamentoEnum'
import { IndMovimentoAfastamentoEnum } from '~/@types/enums/IndMovimentoAfastamentoEnum'

import { MenuVinculo } from '~/hooks/queries/Menu/MenuVinculo'
import {
  VinculoAfastamento as VinculoAfastamentoEntity,
  VinculoAfastamentoForm,
} from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'
import { VinculoAfastamentoSaldoFerias } from '~/hooks/queries/VinculoAfastamentoSaldoFerias/VinculoAfastamentoSaldoFerias'

export const dataInicialForm = {
  isAfastamentoMesmoMotivo: false,
  cessaoInformacaoOnus: CessaoInformacaoOnusEnum.NaoAplicavel_9,
  tipoAcidenteTransito: TipoAcidenteTransitoEnum.NaoAplicavel_9,
  sindicalInformacaoOnus: InfoOnusRemuneracaoEnum.NaoAplicavel_9,
  origemRetificacao: OrigemRetificacaoAfastamentoEnum.NaoAplicavel_9,
  tipoProcesso: ProcessoTipoAfastamentoEnum.NaoInformado_9,
}

const HEADER_HEIGHT = '50px'

interface VinculoAfastamentoProps {
  vinculo: MenuVinculo | null
  noTitle?: boolean
}

export default function VinculoAfastamento(props: VinculoAfastamentoProps) {
  const { vinculo, noTitle = false } = props

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<{ data: VinculoAfastamentoForm; disableAllFields: boolean } | null>(null)
  const {
    close: closeDialogInterrupcao,
    data: dataDialogInterrupcao,
    isOpen: isOpenDialogInterrupcao,
    open: openDialogInterrupcao,
  } = useDialog<string | null>(null)
  const {
    close: closeDialogSaldoFerias,
    data: dataDialogSaldoFerias,
    isOpen: isOpenDialogSaldoFerias,
    open: openDialogSaldoFerias,
  } = useDialog<VinculoAfastamentoSaldoFerias[] | null>(null)
  const {
    close: closeDialogInformarFim,
    data: dataDialogInformarFim,
    isOpen: isOpenDialogInformarFim,
    open: openDialogInformarFim,
  } = useDialog<VinculoAfastamentoForm | null>(null)
  const {
    close: closeDialogMotivoAfastamento,
    data: dataDialogMotivoAfastamento,
    isOpen: isOpenDialogMotivoAfastamento,
    open: openDialogMotivoAfastamento,
  } = useDialog<VinculoAfastamentoForm | null>(null)

  const dialogNotification = useDialogNotification()

  const { mutateAsync: afastamentoDelete, isLoading: isLoadingDelete } = useAfastamentoDelete()
  const { mutateAsync: afastamentoCreate, isLoading: isLoadingCreate } = useAfastamentoCreate()
  const { mutateAsync: afastamentoUpdate, isLoading: isLoadingUpdate } = useAfastamentoUpdate()
  const isSubmitting = isLoadingCreate || isLoadingUpdate

  const handleClickItem = (event: AcoesTabela, vinculoAfastamento: VinculoAfastamentoEntity) => {
    const handleClickDeleteItem = () => {
      openConfirmDelete(vinculoAfastamento.id)
    }

    const handleClickEditItem = () => {
      openForm({
        data: vinculoAfastamento,
        disableAllFields: vinculoAfastamento?.reciboEsocial ? true : false,
      })
    }

    function handleClickSaldoFerias() {
      openDialogSaldoFerias(vinculoAfastamento.afastamentosSaldoFerias || [])
    }

    function handleClickInterromper() {
      openDialogInterrupcao(vinculoAfastamento.id)
    }

    function handleClickRetificar() {
      const data = {
        ...vinculoAfastamento,
        indMovimento: IndMovimentoAfastamentoEnum.Afastamento_Retificacao_2,
      }

      openForm({
        data,
        disableAllFields: false,
      })
    }

    function handleClickInformarFim() {
      openDialogInformarFim({
        ...vinculoAfastamento,
        indMovimento: IndMovimentoAfastamentoEnum.Afastamento_Retorno_3,
      })
    }

    function handleClickMotivoAfastamento() {
      openDialogMotivoAfastamento({
        ...vinculoAfastamento,
        indMovimento: IndMovimentoAfastamentoEnum.Afastamento_Retificacao_MotivoAfastametno_4,
      })
    }

    switch (event) {
      case AcoesTabela.DELETE:
        handleClickDeleteItem()
        break
      case AcoesTabela.EDIT:
        handleClickEditItem()
        break
      case AcoesTabela['SALDO-FERIAS']:
        handleClickSaldoFerias()
        break
      case AcoesTabela.INTERROMPER:
        handleClickInterromper()
        break
      case AcoesTabela.RETIFICAR:
        handleClickRetificar()
        break
      case AcoesTabela['DT-FIM']:
        handleClickInformarFim()
        break
      case AcoesTabela['MOTIVO-AFASTAMENTO']:
        handleClickMotivoAfastamento()
        break
      default:
        console.error('Ação não encontrada')
        break
    }
  }

  const handleAfterSubmitForm = async (afastamento: VinculoAfastamentoEntity) => {
    let alertas: string[] = []
    if (afastamento?.id) {
      alertas = await afastamentoUpdate(afastamento)
    } else {
      alertas = await afastamentoCreate(afastamento)
    }
    if (alertas?.length > 0) {
      dialogNotification.info({ descriptions: alertas })
    }
  }

  async function onConfirmDelete(id: string) {
    await afastamentoDelete(id)
    closeConfirmDelete()
  }

  const titleHeader = !noTitle && {
    title: 'Afastamentos',
    subtitle: vinculo?.vinculoNome || '-',
  }

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader {...titleHeader}>
          {vinculo?.vinculoId && (
            <ButtonBox top={1}>
              <Button
                size="small"
                color="primary"
                onClick={() =>
                  openForm({
                    data: {
                      ...dataInicialForm,
                      vinculoId: vinculo.vinculoId,
                    },
                    disableAllFields: false,
                  })
                }
                variant="contained"
              >
                Adicionar
              </Button>
            </ButtonBox>
          )}
        </PageHeader>
      </Box>
      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        {vinculo?.vinculoId ? (
          <Table onItemClick={handleClickItem} vinculoId={vinculo.vinculoId} />
        ) : (
          <Typography>Selecione um Funcionário</Typography>
        )}
      </Box>

      {isOpenForm && dataForm ? (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          data={dataForm.data}
          onAfterSubmitForm={handleAfterSubmitForm}
          isLoading={isSubmitting}
          disabledAllFields={dataForm.disableAllFields}
        />
      ) : (
        <></>
      )}

      {isOpenDialogInterrupcao && dataDialogInterrupcao ? (
        <DialogInterrupcao
          isOpen={isOpenDialogInterrupcao}
          afastamentoId={dataDialogInterrupcao}
          onClose={closeDialogInterrupcao}
        />
      ) : (
        <></>
      )}

      {isOpenDialogSaldoFerias && vinculo && dataDialogSaldoFerias ? (
        <DialogSaldoFerias
          isOpen={isOpenDialogSaldoFerias}
          data={dataDialogSaldoFerias}
          onClose={closeDialogSaldoFerias}
          vinculoId={vinculo.vinculoId}
        />
      ) : (
        <></>
      )}

      {isOpenDialogInformarFim && dataDialogInformarFim ? (
        <DialogInformarFim
          isOpen={isOpenDialogInformarFim}
          data={dataDialogInformarFim}
          onClose={closeDialogInformarFim}
          onAfterSubmitForm={handleAfterSubmitForm}
          isLoading={isSubmitting}
        />
      ) : (
        <></>
      )}

      {isOpenDialogMotivoAfastamento && dataDialogMotivoAfastamento ? (
        <DialogMotivoAfastamento
          isOpen={isOpenDialogMotivoAfastamento}
          data={dataDialogMotivoAfastamento}
          onClose={closeDialogMotivoAfastamento}
          onAfterSubmitForm={handleAfterSubmitForm}
          isLoading={isSubmitting}
        />
      ) : (
        <></>
      )}

      {isOpenConfirmDelete && dataConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isLoadingDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => onConfirmDelete(dataConfirmDelete)}
        />
      ) : (
        <></>
      )}
    </>
  )
}
