import React, { useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { ActionDialog, DatePickerNew } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import { vinculoRescisaoTSV } from '~/hooks/queries/Vinculo/vinculoRescisaoTSV'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtRescisao: yup
    .date()
    .required('Informe a Data de Desligamento')
    .typeError('Informe uma data válida')
    .nullable(),
})

interface FormDataProps {
  dtRescisao: string | null
}

const initialState: FormDataProps = {
  dtRescisao: getDateCurrent(),
}

interface DialogConfirmProps {
  isOpen: boolean
  onClose: () => void
  vinculosSelecionados: string[]
  onAfterSubmit: () => void
}

export default function DialogConfirm(props: DialogConfirmProps) {
  const { isOpen, onClose, vinculosSelecionados, onAfterSubmit } = props

  const [data, setData] = useState<FormDataProps>(initialState)
  const [isLoading, setLoading] = useState(false)
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const dialogNotification = useDialogNotification()

  async function handleSubmit() {
    setLoading(true)
    try {
      await vinculoRescisaoTSV({
        vinculosIds: vinculosSelecionados,
        dtRescisao: data.dtRescisao as string,
      })
      onAfterSubmit()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ActionDialog
      title="Informe os dados para a Rescisão TSV"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      isOkProcessing={isLoading}
      okLabel="Gerar Rescisão"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerNew
            label="Data de Desligamento"
            value={data.dtRescisao}
            required
            name="dtRescisao"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtRescisao = date
              setData((prevState) => ({ ...prevState, dtRescisao }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
