import React, { useEffect, useState } from 'react'

import { ButtonBox } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { Button, PageHeader, TextField } from '~/components'
import ConfirmDeleteDialog from '~/components/ConfirmDeleteDialog'

import Table from './components/Table'
import FormItem from './components/FormItem'

import { useStepperContext } from '~/components/StepperForm'
import useNotification from '~/hooks/useNotification'
import useDialog from '~/hooks/useDialog'

import { MainDataForm } from '..'
import { VinculoHorarioItemPartial } from '~/hooks/queries/VinculoHorarioItem/VinculoHorarioItem'
import useDialogNotification from '~/hooks/useDialogNotification'

const HEADER_HEIGHT = 50

export default function FormHorarioTrabalho() {
  const { mainData, onBack, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState(mainData.dataHorarioTrabalho)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  const { close: closeFormItem, isOpen: isOpenFormItem, open: openFormItem } = useDialog(null)

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<number | null>(null)

  useEffect(() => {
    const vinculoHorarioItens = mainData?.dataHorarioTrabalho?.vinculoHorarioItens || []
    if (vinculoHorarioItens.length > 0) return
    openFormItem(null)
    // eslint-disable-next-line
  }, [mainData])

  const handleClickItem = (event: 'delete', rowIndex: number) => {
    const handleClickDeleteItem = () => {
      openConfirmDelete(rowIndex)
    }

    const functions = {
      delete: handleClickDeleteItem,
    }
    functions[event]()
  }

  const handleDeleteItem = () => {
    setData((prev) => {
      const itens = prev.vinculoHorarioItens || []
      return {
        ...prev,
        vinculoHorarioItens: itens.filter((i, index) => index !== dataConfirmDelete),
      }
    })
    closeConfirmDelete()
    notification.remove()
  }

  const handleAfterSubmitFormItem = (dataItens: VinculoHorarioItemPartial[]) => {
    const vinculoHorarioItens = [...dataItens]

    const itens = data.vinculoHorarioItens || []

    itens.forEach((d) => {
      if (!vinculoHorarioItens.some((x) => x.diaHorario === d.diaHorario)) {
        vinculoHorarioItens.push(d)
      }
    })

    vinculoHorarioItens.sort((a, b) => (a?.diaHorario || 0) - (b?.diaHorario || 0))

    setData((prev) => ({
      ...prev,
      vinculoHorarioItens,
    }))
    closeFormItem()
  }

  function handleBack() {
    onBack()
  }

  function handleSubmit() {
    const vinculosHorariosItens = data?.vinculoHorarioItens || []
    if (!(vinculosHorariosItens.length > 0))
      return dialogNotification.warning({ descriptions: ['Informe pelo menos um Horário'] })
    onNext({
      ...mainData,
      dataHorarioTrabalho: data,
    })
  }

  return (
    <Box height="calc(100vh - 25rem)">
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Horário de Trabalho">
          <ButtonBox top={1}>
            <Button onClick={() => openFormItem(null)} variant="contained">
              Adicionar Horários
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={HEADER_HEIGHT}>
        <TextField
          label="Observação"
          fullWidth
          value={data?.observacao || ''}
          size="small"
          inputProps={{
            maxLength: 200,
          }}
          multiline
          onChange={(e) => {
            const observacao = e.target.value || ''
            setData((prev) => ({ ...prev, observacao }))
          }}
        />
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT * 2}px)`}>
        <Table data={data.vinculoHorarioItens || []} onItemClick={handleClickItem} />
      </Box>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleSubmit} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>

      {isOpenFormItem && (
        <FormItem
          isOpen={isOpenFormItem}
          onClose={closeFormItem}
          onAfterSubmitForm={handleAfterSubmitFormItem}
        />
      )}

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={handleDeleteItem}
        />
      )}
    </Box>
  )
}
