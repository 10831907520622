import { Grid, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, DatePicker } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getDateCurrent } from '~/utils/utils'

const schema = yup.object().shape({
  dtAssinatura: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da assinatura')
    .nullable(),
  textoAcordo: yup.string().required('Informe um texto para o acordo de hora extra'),
})

const initialState = {
  dtAssinatura: getDateCurrent(),
  textoAcordo:
    'Concordo em trabalhar até 2 horas extras por dia, de SEGUNDA-FEIRA à SEXTA-FEIRA e se necessário aos SÁBADOS',
}

export default function DialogConfirm(props) {
  const { isOpen, onClose, onAfterSubmit } = props

  const [data, setData] = useState(initialState)

  function handleSubmit() {
    onAfterSubmit(data)
    onClose()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  useEffect(() => {
    if (isOpen) return
    setData(initialState)
  }, [isOpen])

  return (
    <ActionDialog
      title="Informe dados para o acordo de hora extra"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      okLabel="Imprimir"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePicker
            label="Data da assinatura"
            value={data?.dtAssinatura || null}
            name="dtAssinatura"
            validationErrors={validationErrors}
            onChange={(date) => {
              const dtAssinatura = date ? date.format('yyyy-MM-DD') : null
              setData((prevState) => ({ ...prevState, dtAssinatura }))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label="Texto do acordo hora extra"
            value={data?.textoAcordo || ''}
            onChange={(e) => {
              const textoAcordo = e?.target?.value || ''
              setData((prevState) => ({
                ...prevState,
                textoAcordo,
              }))
            }}
            rows={5}
            multiline
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
