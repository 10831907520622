import api from '~/services/api-pessoal'

interface VinculoRescisaoTSVProps {
  vinculosIds: string[]
  dtRescisao: string
}

export async function vinculoRescisaoTSV(props: VinculoRescisaoTSVProps) {
  const { vinculosIds, dtRescisao } = props
  await api.post('/Vinculo/RescisaoTSV', vinculosIds, {
    params: {
      dtRescisao,
    },
  })
}
