import React, { useState } from 'react'

import { DataTable, ButtonBox } from 'mio-library-ui'

import { AnoMes } from '~/components/Displays'

import { formatToBRL } from 'brazilian-values'

const Table = (props) => {
  const { data, isLoading, pagination, query } = props
  const [searchText, setSearchText] = useState('')

  const columns = [
    {
      name: 'planoSaude',
      label: 'Plano de Saúde',
      options: {
        customBodyRender: (value) => value?.nome,
      },
    },
    {
      name: 'vinculo',
      label: 'Nome',
      options: {
        customBodyRender: (value) => value?.pessoaFisica.nome,
      },
    },
    {
      name: 'anoMesInicio',
      label: 'Início',
      options: {
        customBodyRender: (value) => <AnoMes value={value} />,
      },
    },
    {
      name: 'anoMesFim',
      label: 'Fim',
      options: {
        customBodyRender: (value) => <AnoMes value={value} />,
      },
    },
    {
      name: 'vrMensalidadeTitular',
      label: 'Vr. Mensalidade Titular',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrMensalidadeDependente',
      label: 'Vr. Mensalidade Dependente',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrCustoTitular',
      label: 'Vr. Custo Titular',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrCustoDependente',
      label: 'Vr. Custo Dependente',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
    {
      name: 'vrTotal',
      label: 'Vr. Total',
      options: {
        customBodyRender: (value) => <ButtonBox>{value ? formatToBRL(value) : null}</ButtonBox>,
      },
    },
  ]

  const options = {
    viewColumns: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    searchText,
    print: false,
    download: false,
    elevation: 0,
    onSearchChange: (search) => {
      setSearchText(search)
    },
    responsive: 'standard',
  }

  return (
    <DataTable
      data={data}
      columns={columns}
      options={options}
      isLoading={isLoading}
      pagination={pagination}
      sherlock={{
        query,
        columns: ['planoSaude.nome', 'vinculo.pessoaFisica.nome'],
      }}
    />
  )
}

Table.defaultProps = {
  pagination: true,
}

export default Table
