import React, { useState, useEffect } from 'react'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import { PageHeader, PDFViewer, PrintButton } from '~/components'

import Form from './components/Form'
import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import api from '~/services/api-pessoal'

import { getDateMinAndDateMaxOfYearMonth } from '~/hooks/useUtils'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { getDateCurrent } from '~/utils/utils'

const initialCollection = {
  funcionarios: [],
}

export default function AutorizacaoDeposito() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({})
  const [collection, setCollection] = useState(initialCollection)
  const [rowsSelecteds, setRowsSelected] = useState([])

  const { anoMes, estabelecimento } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {
    close: closeDialogConfirm,
    data: dataDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()

  useEffect(() => {
    const { dtMax, dtMin } = getDateMinAndDateMaxOfYearMonth(anoMes)
    setDataForm({
      dtInicio: dtMin,
      dtFim: dtMax,
      dtRelAutorizacao: getDateCurrent(),
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setCollection(initialCollection)
    setRowsSelected([])
  }, [estabelecimento])

  async function handleSubmit(data) {
    setLoading(true)
    try {
      const response = await api.get(
        '/Relatorio/AutorizacaoDeposito/ObterFuncionariosAutorizacaoDeposito',
        {
          params: {
            estabelecimentoId: estabelecimento.id,
            dtInicio: data.dtInicio,
            dtFim: data.dtFim,
            dtRelAutorizacao: data.dtRelAutorizacao,
          },
        },
      )
      setCollection(response?.data?.data || initialCollection)
      setRowsSelected([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handleHowsSelecteds(indexs) {
    if (rowsSelecteds.length === 0 && indexs.length === 0) {
      const arrayIndexs = []
      collection.funcionarios.forEach((d, index) =>
        d?.contaCorrente ? arrayIndexs.push(index) : null,
      )
      setRowsSelected(arrayIndexs)
      return
    }
    setRowsSelected(indexs)
  }

  function handlePrint() {
    if (!(rowsSelecteds.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário'],
      })
    const funcionarios = rowsSelecteds.map((index) => collection.funcionarios[index])
    openDialogConfirm({
      ...collection,
      funcionarios,
    })
  }

  function onAfterSubmitDialogConfirm(data) {
    openPDFViewer(data)
    setRowsSelected([])
  }

  return (
    <Box height="100%" width="100%" p={2} display="flex" flexDirection="column">
      <PageHeader title="Autorização de Depósito">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Form isLoading={isLoading} onSubmit={handleSubmit} data={dataForm} />

      <Box flex={1}>
        <Table
          isLoading={isLoading}
          data={collection.funcionarios}
          query={query}
          onRowSelected={handleHowsSelecteds}
          rowsSelected={rowsSelecteds}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" pt={1}>
        <PrintButton onClick={handlePrint} />
      </Box>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="AutorizaoDeposito"
        title="Autorização de Depósito"
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          data: dataPDFViewer,
          url: '/Relatorio/AutorizacaoDeposito/ObterRelatorio',
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        data={dataDialogConfirm}
        onAfterSubmit={onAfterSubmitDialogConfirm}
      />
    </Box>
  )
}
