import Home from '~/pages/Home'
import Painel from '~/pages/Painel'
import Lotacao from '~/pages/Lotacao'
import Departamento from '~/pages/Departamento'
import Cargo from '~/pages/Cargo'
import Funcao from '~/pages/Funcao'
import FuncaoCadastro from '~/pages/Funcao/components/Cadastro'
import Setor from '~/pages/Setor'
import HorarioTrabalho from '~/pages/HorarioTrabalho'
import HorarioTrabalhoCadastro from '~/pages/HorarioTrabalho/components/Cadastro'
import TabelaSF from '~/pages/TabelasIncidencias/TabelaSF'
import TabelaIR from '~/pages/TabelasIncidencias/TabelaIR'
import TabelaINSS from '~/pages/TabelasIncidencias/TabelaINSS'
import Medico from '~/pages/Medico'
import MedicoCadastro from '~/pages/Medico/components/Cadastro'
import Titulacao from '~/pages/Titulacao'
import TitulacaoCadastro from '~/pages/Titulacao/components/Cadastro'
import Sindicato from '~/pages/Sindicato'
import SindicatoCadastro from '~/pages/Sindicato/components/Container/components/Cadastro'
import BancoAgencia from '~/pages/BancoAgencia'
import BancoAgenciaCadastro from '~/pages/BancoAgencia/components/Cadastro'
import Estabilidade from '~/pages/Estabilidade'
import Feriado from '~/pages/Feriado'
import InstituicaoEnsino from '~/pages/InstituicaoEnsino'
import ValeTransporte from '~/pages/ValeTransporte'
import ValeTransporteCadastro from '~/pages/ValeTransporte/components/Cadastro'
import AcordoDissidioConvencao from '~/pages/AcordoDissidioConvencao'
import CTBModelo from '~/pages/CTBModelo'
import CTBModeloCadastro from '~/pages/CTBModelo/components/Cadastro'
import CTBClassificacao from '~/pages/CTBClassificacao'
import CTBClassificacaoCadastro from '~/pages/CTBClassificacao/components/Cadastro'
import Empregador from '~/pages/Empregador'
import Estabelecimento from '~/pages/Estabelecimento'
import EstabelecimentoContainerCadastro from '~/pages/Estabelecimento/components/Container'
import Evento from '~/pages/Evento'
import Processo from '~/pages/Processo'
import PlanoSaude from '~/pages/PlanoSaude'
import PlanoSaudeCadastro from '~/pages/PlanoSaude/components/Cadastro'
import PlanoSaudeLancamento from '~/pages/PlanoSaudeLancamento'
import PlanoSaudeConsulta from '~/pages/PlanoSaudeConsulta'
import VinculoContainer from '~/pages/Funcionario/Container'
import Funcionario from '~/pages/Funcionario'
import ReciboPagamentoOld from '~/pages/ReciboPagamentoOld'
import PessoaFisica from '~/pages/PessoaFisica'
import PessoaFisicaContainer from '~/pages/PessoaFisica/components/Container'
import AdmissaoPreliminar from '~/pages/AdmissaoPreliminar'
import TabelaMes from '~/pages/TabelaMes'
import TabelaMesCadastro from '~/pages/TabelaMes/components/Cadastro'
import TabelaIndice from '~/pages/TabelaIndice'
import TabelaIndiceCadastro from '~/pages/TabelaIndice/components/Cadastro'
import AgenteIntegracao from '~/pages/AgenteIntegracao'
import LancamentoColetivo from '~/pages/LancamentoColetivo'
import Adquirente from '~/pages/Adquirente'
import AdquirenteCadastro from '~/pages/Adquirente/components/Form'
import VinculoEmprestimo from '~/pages/VinculoEmprestimo'
import VinculoEmprestimoCadastro from '~/pages/VinculoEmprestimo/components/Form'
import TabelaSalarioMinimo from '~/pages/TabelasIncidencias/TabelaSalarioMinimo'
import SociosDiretores from '~/pages/SociosDiretores'
import SociosDiretoresContainer from '~/pages/SociosDiretores/Container'
import Autonomo from '~/pages/Autonomo'
import AutonomoContainer from '~/pages/Autonomo/Container'
import Cooperado from '~/pages/Cooperado'
import CooperadoContainer from '~/pages/Cooperado/Container'
import EstagiarioContainerCadastro from '~/pages/Estagiario/Container'
import Estagiario from '~/pages/Estagiario'
import Transferencia from '~/pages/Transferencia'
import ProgramacaoFeriasAviso from '~/pages/ProgramacaoFeriasAviso'
import Comercializacao from '~/pages/Comercializacao'
import VinculoAvisoPrevio from '~/pages/VinculoAvisoPrevio'
import AlterarCategoria from '~/pages/AlterarCategoria'
import AlterarInscricao from '~/pages/AlterarInscricao'
import ConsultaRP from '~/pages/ConsultaRP'
import PontoEletronico from '~/pages/PontoEletronico'
import AutonomosLancamento from '~/pages/AutonomosLancamento'
import TrabalhoIntermitente from '~/pages/TrabalhoIntermitente'
import SeguroDesemprego from '~/pages/SeguroDesemprego'
import CNAB from '~/pages/CNAB'
import GerarGRRF from '~/pages/GerarGRRF'
import ProcessarCompetencia from '~/pages/ProcessarCompetencia'
import Competencia from '~/pages/Competencia'
import ReciboPagamento from '~/pages/ReciboPagamento'
import Grupo from '~/pages/Grupo'
import Contador from '~/pages/Contador'
import DARFIR from '~/pages/DARFIR'
import DARFPIS from '~/pages/DARFPIS'
import SEFIP from '~/pages/SEFIP'
import Provisao13Salario from '~/pages/Provisao13Salario'
import ProvisaoFerias from '~/pages/ProvisaoFerias'
import EventoEmpregador from '~/pages/EventoEmpregador'
import ComunicadoAcidenteTrabalho from '~/pages/ComunicadoAcidenteTrabalho'
import AtestadoSaudeOcupacional from '~/pages/AtestadoSaudeOcupacional'
import AtestadoSaudeOcupacionalNew from '~/pages/S2220Aso/AtestadoSaudeOcupacionalNew'
import ASOImportar from '~/pages/S2220Aso/ASOImportar'
import EquipamentoProtecaoIndividual from '~/pages/EquipamentoProtecaoIndividual'
import CondicaoAmbienteTrabalho from '~/pages/CondicaoAmbienteTrabalho'
import CondicaoAmbienteTrabalhoNew from '~/pages/S2240AmbienteTrabalho/CondicaoAmbienteTrabalhoNew'
import AmbienteTrabalhoImportacao from '~/pages/S2240AmbienteTrabalho/AmbienteTrabalhoImportacao'
import ConsultaRPEstabelecimento from '~/pages/ConsultaRPEstabelecimento'
import PageInDevelopement from '~/pages/VinculoReintegracao'
import AlteracaoContratual from '~/pages/AlteracaoContratual'
import AlteracaoContratualNew from '~/pages/AlteracaoContratualNew'
import AlteracaoCadastral from '~/pages/AlteracaoCadastral'
import AlteracaoCadastralNew from '~/pages/AlteracaoCadastralNew'
import CertificadoDigital from '~/pages/CertificadoDigital'
import RESefip from '~/pages/RESefip'
import PrevidenciaBeneficiario from '~/pages/PrevidenciaBeneficiario'
import Servico from '~/pages/Servico'
import CargoAgenteNocivo from '~/pages/CargoAgenteNocivo'
import SetorAgenteNocivo from '~/pages/SetorAgenteNocivo'
import ResponsavelRegistroAmbiental from '~/pages/ResponsavelRegistroAmbiental'
import CondicaoAmbienteTrabalhoAutomatico from '~/pages/CondicaoAmbienteTrabalhoAutomatico'
import TransmissaoESocial from '~/pages/TransmissaoESocial'
import TransmissaoESocialNew from '~/pages/TransmissaoESocialNew'
import ExclusaoEventosESocial from '~/pages/ExclusaoEventosESocial'
import VinculoFaltas from '~/pages/VinculoFaltas'
import ReaberturaMovimentoESocial from '~/pages/ReaberturaMovimentoESocial'
import VinculoAfastamento from '~/pages/VinculoAfastamento'
import VinculoFerias from '~/pages/VinculoFerias'
import AdiantamentoSalario from '~/pages/AdiantamentoSalario'
import ConfigurarAdiantamentoSalario from '~/pages/ConfigurarAdiantamentoSalario'
import InformacaoComplementarEmpregador from '~/pages/InformacaoComplementarEmpregador'
import ContratacaoTrabalhadorAvulso from '~/pages/ContratacaoTrabalhadorAvulso'
import VinculoExameToxicologico from '~/pages/VinculoExameToxicologico'
import ProcessarAlteracaoSalario from '~/pages/ProcessarAlteracaoSalario'
import VinculoReintegracao from '~/pages/VinculoReintegracao'
import ValoresPorEvento from '~/pages/ValoresPorEvento'
import LGPD from '~/pages/LGPD'
import RelacaoBancaria from '~/pages/RelacaoBancaria'
import ContratoExperienciaProrrogacao from '~/pages/ContratoExperienciaProrrogacao'
import DemonstrativoRPEmail from '~/pages/DemonstrativoRPEmail'
import RelatorioFeriasVencidas from '~/pages/RelatorioFeriasVencidas'
import CondicaoAmbienteTrabalhoResumo from '~/pages/CondicaoAmbienteTrabalhoResumo'
import AutorizacaoDeposito from '~/pages/AutorizacaoDeposito'
import AcordoHoraExtra from '~/pages/AcordoHoraExtra'
import CompensacaoHorasTrabalhadas from '~/pages/CompensacaoHorasTrabalhadas'
import AcordoBancoHoras from '~/pages/AcordoBancoHoras'
import QuadroHorarioTrabalho from '~/pages/QuadroHorarioTrabalho'
import OrdemImpressaoEvento from '~/pages/OrdemImpressaoEvento'
import TermoCompromissoEstagio from '~/pages/TermoCompromissoEstagio'
import FichaRegistroFuncionario from '~/pages/FichaRegistroFuncionario'
import RelatorioFolhaColetiva from '~/pages/RelatorioFolhaColetiva'
import EnvioEventosAutomaticos from '~/pages/EnvioEventosAutomaticos'
import FinalizarRecibos from '~/pages/FinalizarRecibos'
import RelatorioResumoFolha from '~/pages/RelatorioResumoFolha'
import RelatorioContraCheque from '~/pages/RelatorioContraCheque'
import ResumoAdmissaoDemissao from '~/pages/ResumoAdmissaoDemissao'
import CancelarTransferencia from '~/pages/CancelarTransferencia'
import RelatorioFeriasConcedidas from '~/pages/RelatorioFeriasConcedidas'
import ResultRelatorioFeriasConcedidas from '~/pages/RelatorioFeriasConcedidas/components/ResultRelatorioFeriasVencidas'
import RelatorioConfiguracao from '~/pages/RelatorioConfiguracao'
import AuditoriaPorEmpregador from '~/pages/AuditoriaPorEmpregador'
import LimparGerarAmbiente from '~/pages/LimparGerarAmbiente'
import LotesPorEmpregador from '~/pages/LotesPorEmpregador'
import ParametrosSistema from '~/pages/ParametrosSistema'
import RelatorioFichaSalarioFamilia from '~/pages/RelatorioFichaSalarioFamilia'
import TermoResponsabilidadeDependentes from '~/pages/TermoResponsabilidadeDependentes'
import ComunicadoPerdaDireitoFerias from '~/pages/ComunicadoPerdaDireitoFerias'
import DocumentoArrecadacao from '~/pages/DCTFWeb/DocumentoArrecadacao'
import RequerimentoAbonoPecuniario from '~/pages/RequerimentoAbonoPecuniario'
import CartaAbandonoEmprego from '~/pages/CartaAbandonoEmprego'
import DeclaracaoUsoPlanoSaude from '~/pages/DeclaracaoUsoPlanoSaude'
import ComunicadoTerminoExperiencia from '~/pages/ComunicadoTerminoExperiencia'
import ValeTransnporteDeclaracaoUso from '~/pages/ValeTransnporteDeclaracaoUso'
import CartaApresentacao from '~/pages/CartaApresentacao'
import DeclaracaoQuitacaoAnual from '~/pages/DeclaracaoQuitacaoAnual'
import DeclaracaoSalarioContribuicao from '~/pages/DeclaracaoSalarioContribuicao'
import RescisaoContratoExperiencia from '~/pages/RescisaoContratoExperiencia'
import ListaPonto from '~/pages/ListaPonto'
import FolhaPonto from '~/pages/FolhaPonto'
import DadosCadastraisFuncionario from '~/pages/DadosCadastraisFuncionario'
import ApuracaoEncargos from '~/pages/ApuracaoEncargos'
import ManutencaoAtividadeConcomitante from '~/pages/ManutencaoAtividadeConcomitante'
import VinculoEstabilidade from '~/pages/VinculoEstabilidade'
import VinculoRescisaoSimulada from '~/pages/VinculoRescisaoSimulada'
import VinculoLotacao from '~/pages/VinculoLotacao'
import VinculoEventoAutomatico from '~/pages/VinculoEventoAutomatico'
import ContratoTrabalhoIndeterminado from '~/pages/ContratoTrabalhoIndeterminado'
import TermoMonitoramentoCameras from '~/pages/TermoMonitoramentoCameras'
import TermoAutorizacaoImagemVoz from '~/pages/TermoAutorizacaoImagemVoz'
import AutorizacaoDescontos from '~/pages/AutorizacaoDescontos'
import RequerimentoBeneficioIncapacidade from '~/pages/RequerimentoBeneficioIncapacidade'
import RescisaoTSV from '~/pages/RescisaoTSV'
import RelSalarioContratual from '~/pages/RelSalarioContratual'
import FeriasColetiva from '~/pages/FeriasColetiva'
import VencimentoContratoExperiencia from '~/pages/Relatorios/VencimentoContratoExperiencia'
import RescisaoComplementar from '~/pages/RescisaoComplementar'
import DIRF from '~/pages/DIRF'
import GerenciadorDocumentos from '~/pages/GerenciadorDocumentos'
import EtiquetaCTPS from '~/pages/Etiqueta/EtiquetaCTPS'
import EtiquetaContratoExperiencia from '~/pages/Etiqueta/EtiquetaContratoExperiencia'
import ConsultaVencimentos from '~/pages/ConsultaVencimentos'
import FichaFornecimentoEPI from '~/pages/Relatorios/FichaFornecimentoEPI'
import RPAutonomo from '~/pages/RPAutonomo'
import RetificarFuncionario from '~/pages/RetificarFuncionario'
import FlowRetificar from '~/pages/Funcionario/FlowRetificar'
import AntecipacaoSalarial from '~/pages/AntecipacaoSalarial'
import Terceiros from '~/pages/Terceiros'
import AuxiliaresFuncionario from '~/pages/AuxiliaresFuncionario'
import AuxiliaresCadastro from '~/pages/AuxiliaresCadastro'
import FGTSBeneficiario from '~/pages/FGTSBeneficiario'
import ApurarConvencaoColetiva from '~/pages/ApurarConvencaoColetiva'
import ProcessarConvencaoColetiva from '~/pages/ProcessarConvencaoColetiva'
import FechamentoESocial from '~/pages/FechamentoESocial'
import RelSalarialCargo from '~/pages/Relatorios/RelSalarialCargo'
import ManutencaoEmpregador from '~/pages/ManutencaoEmpregador'
import ManutencaoAutonomo from '~/pages/ManutencaoAutonomo'
import EtiquetaAvisoPrevio from '~/pages/Etiqueta/EtiquetaAvisoPrevio'
import ResumoContabilFolha from '~/pages/ResumoContabilFolha'
import ValeTransporteFuncionario from '~/pages/ValeTransporteFuncionario'
import ValeTransporteApuracao from '~/pages/ValeTransporteApuracao'
import IRRFBeneficiario from '~/pages/IRRFBeneficiario'
import ConfiguracoesNotificacoes from '~/pages/ConfiguracoesNotificacoes'
import ImportacaoPlanilhasEventos from '~/pages/ImportacaoPlanilhasEventos'
import ImportacaoPlanilhaFaltas from '~/pages/ImportacaoPlanilhaFaltas'
import DeclContriSindical from '~/pages/DeclContriSindical'
import EtiquetaCartaoPonto from '~/pages/EtiquetaCartaoPonto'
import DeclaracaoEncargosFamilia from '~/pages/DeclaracaoEncargosFamilia'
import ReciboTermoConduta from '~/pages/ReciboTermoConduta'
import RelatorioAniversariantes from '~/pages/RelatorioAniversariantes'
import VinculoAdvertencia from '~/pages/VinculoAdvertencia'
import VinculoSuspensao from '~/pages/VinculoSuspensao'
import MotivosAdvertenciaSuspensao from '~/pages/MotivosAdvertenciaSuspensao'
import Contribuintes from '~/pages/ContribuinteFacultativo/Contribuintes'
import Contribuicoes from '~/pages/ContribuinteFacultativo/Contribuicoes'
import Salario from '~/pages/Salario'
import ProcessoTrabalhista from '~/pages/ProcessoTrabalhista/ProcessoTrabalhista'
import ProcessoTrabalhistaFuncionario from '~/pages/ProcessoTrabalhista/ProcessoTrabalhistaFuncionario'
import ProvisaoSaldoInicial from '~/pages/ProvisaoSaldoInicial'
import ProcessoTrabalhistaTributos from '~/pages/ProcessoTrabalhista/ProcessoTrabalhistaTributos'
import ProcessoTrabalhistaManutencaoVinculo from '~/pages/ProcessoTrabalhista/ProcessoTrabalhistaManutencaoVinculo'
import ProcessoTrabalhistaRescisaoZerada from '~/pages/ProcessoTrabalhista/ProcessoTrabalhistaRescisaoZerada'
import ContratoIntermitente from '~/pages/ContratoIntermitente'
import CartaOposicaoSindicato from '~/pages/CartaOposicaoSindicato'
import AtualizarCTPS from '~/pages/AtualizarCTPS'
import EtiquetaFerias from '~/pages/Etiqueta/EtiquetaFerias'
import EtiquetaAlteracaoSalarial from '~/pages/Etiqueta/EtiquetaAlteracaoSalarial'
import VinculoTrabalhoFeriado from '~/pages/VinculoTrabalhoFeriado'

const routes = [
  { component: Home, title: 'Home', path: '/home' },
  { component: Painel, title: 'Painel', path: '/painel' },
  { component: Lotacao, title: 'Lotação', path: '/lotacao' },
  { component: Departamento, title: 'Departamento', path: '/departamento' },
  { component: Cargo, title: 'Cargo', path: '/cargo' },
  { component: FuncaoCadastro, title: 'Cadastro de Função', path: '/funcao/:id' },
  { component: Funcao, title: 'Função', path: '/funcao' },
  { component: Setor, title: 'Setor', path: '/setor' },
  {
    component: HorarioTrabalhoCadastro,
    title: 'Cadastro de Horário de Trabalho',
    path: '/horario-trabalho/:id',
  },
  { component: HorarioTrabalho, title: 'Horário de Trabalho', path: '/horario-trabalho' },
  { component: TabelaSF, title: 'Tabela Salário Família', path: '/tabelaSF' },
  { component: TabelaIR, title: 'Tabela IR', path: '/tabelaIR' },
  { component: TabelaINSS, title: 'Tabela INSS', path: '/tabelaINSS' },
  { component: MedicoCadastro, title: 'Médico/Responsável', path: '/medico-responsavel/:id' },
  { component: Medico, title: 'Médico/Responsável', path: '/medico-responsavel' },
  { component: TitulacaoCadastro, title: 'Titulação', path: '/titulacao/:id' },
  { component: Titulacao, title: 'Titulação', path: '/titulacao' },
  { component: SindicatoCadastro, title: 'Sindicato', path: '/sindicato/:id' },
  { component: Sindicato, title: 'Sindicato', path: '/sindicato' },
  { component: Grupo, title: 'Grupo', path: '/grupo' },
  { component: Contador, title: 'Contador', path: '/contador' },
  { component: BancoAgenciaCadastro, title: 'Agência Bancária', path: '/agencia-bancaria/:id' },
  { component: BancoAgencia, title: 'Agência Bancária', path: '/agencia-bancaria' },
  { component: Estabilidade, title: 'Estabilidade', path: '/Estabilidade' },
  { component: Feriado, title: 'Feriado', path: '/feriado' },
  { component: InstituicaoEnsino, title: 'Instituição de Ensino', path: '/instituicao-ensino' },
  {
    component: ValeTransporteCadastro,
    title: 'Transportadora',
    path: '/vale-transporte-transportadora/:id',
  },
  { component: ValeTransporte, title: 'Transportadora', path: '/vale-transporte-transportadora' },
  {
    component: AcordoDissidioConvencao,
    title: 'Acordo ou Dissídio Convenção',
    path: '/acordo-dissidio-convencao',
  },
  { component: CTBModeloCadastro, title: 'CTB Modelo', path: '/ctb-modelo/:id' },
  { component: CTBModelo, title: 'CTB Modelo', path: '/ctb-modelo' },
  {
    component: CTBClassificacaoCadastro,
    title: 'CTB Classificação',
    path: '/ctb-classificacao/:id',
  },
  { component: CTBClassificacao, title: 'CTB Classificação', path: '/ctb-classificacao' },
  { component: Empregador, title: 'Empregador', path: '/empregador' },
  {
    component: EstabelecimentoContainerCadastro,
    title: 'Estabelecimento',
    path: '/estabelecimento/:id',
  },
  { component: Estabelecimento, title: 'Estabelecimento', path: '/estabelecimento' },
  { component: Evento, title: 'Evento', path: '/evento' },
  { component: Processo, title: 'Processo', path: '/processo' },
  { component: PlanoSaudeCadastro, title: 'Plano Saúde', path: '/plano-saude/:id' },
  { component: PlanoSaude, title: 'Plano Saúde', path: '/plano-saude' },
  {
    component: PlanoSaudeLancamento,
    title: 'Plano Saúde - Lançamento',
    path: '/plano-saude-lancamento/',
  },
  {
    component: PlanoSaudeConsulta,
    title: 'Plano Saúde - Consulta',
    path: '/plano-saude-consulta/',
  },
  {
    component: VinculoContainer,
    title: 'Cadastro de Funcionário',
    path: '/funcionario/:id',
  },
  {
    component: RetificarFuncionario,
    title: 'Retificar Funcionário',
    path: '/funcionario-retificar',
  },
  {
    component: FlowRetificar,
    title: 'Retificar Funcionário',
    path: '/funcionario-retificar/:id',
  },
  { component: Funcionario, title: 'Funcionário', path: '/funcionario' },
  { component: ReciboPagamento, title: 'Recibo Pagamento', path: '/recibo-pagamento/mensal' },
  { component: ReciboPagamento, title: 'Recibo Pagamento', path: '/recibo-pagamento/ferias' },
  { component: ReciboPagamento, title: 'Recibo Pagamento', path: '/recibo-pagamento/rescisao' },
  {
    component: ReciboPagamento,
    title: 'Recibo Pagamento',
    path: '/recibo-pagamento/rescisao-complementar',
  },
  {
    component: ReciboPagamento,
    title: 'Recibo Pagamento',
    path: '/recibo-pagamento/adiantamento-salario',
  },
  {
    component: ReciboPagamento,
    title: 'Recibo Pagamento',
    path: '/recibo-pagamento/autonomo',
  },
  { component: ReciboPagamentoOld, title: 'Recibo Pagamento', path: '/recibo-pagamento-old' },
  { component: PessoaFisicaContainer, title: 'Pessoa Física', path: '/pessoa-fisica/:id' },
  { component: PessoaFisica, title: 'Pessoa Física', path: '/pessoa-fisica' },
  { component: AdmissaoPreliminar, title: 'Admissão Preliminar', path: '/admissao-preliminar' },
  {
    component: ProcessarCompetencia,
    title: 'Processar Competência',
    path: '/processar-competencia-old',
  },
  {
    component: Competencia,
    title: 'Processar Competência',
    path: '/processar-competencia',
  },
  { component: TabelaMesCadastro, title: 'Tabela Mês', path: '/tabela-mes/:id' },
  { component: TabelaMes, title: 'Tabela Mês', path: '/tabela-mes' },
  { component: TabelaIndiceCadastro, title: 'Tabela Índice', path: '/indice/:id' },
  { component: TabelaIndice, title: 'Tabela Índice', path: '/indice' },
  { component: AgenteIntegracao, title: 'Agente Integração', path: '/agente-integracao' },
  { component: LancamentoColetivo, title: 'Lançamento Coletivo', path: '/lancamento-coletivo' },
  { component: AdquirenteCadastro, title: 'Cadastro de Adquirente', path: '/adquirente/:id' },
  { component: Adquirente, title: 'Adquirente', path: '/adquirente' },
  {
    component: VinculoEmprestimoCadastro,
    title: 'Cadastro de Empréstimo',
    path: '/vinculo-emprestimo/:id',
  },
  { component: VinculoEmprestimo, title: 'Empréstimo', path: '/vinculo-emprestimo' },
  { component: TabelaSalarioMinimo, title: 'Tabela Salário', path: '/salario-minimo' },
  { component: SociosDiretores, title: 'Sócios/Diretores', path: '/socios-diretores' },
  { component: SociosDiretoresContainer, title: 'Sócios/Diretores', path: '/socios-diretores/:id' },
  { component: Autonomo, title: 'Autônomo', path: '/autonomo' },
  { component: AutonomoContainer, title: 'Autônomo', path: '/autonomo/:id' },
  { component: Cooperado, title: 'Cooperado', path: '/cooperado' },
  { component: CooperadoContainer, title: 'Cadastro de Cooperado', path: '/cooperado/:id' },
  {
    component: EstagiarioContainerCadastro,
    title: 'Cadastro de Estágiario',
    path: '/estagiario/:id',
  },
  { component: Estagiario, title: 'Estágiario', path: '/estagiario' },
  { component: Transferencia, title: 'Transferência', path: '/transferencia-funcionario' },
  {
    component: ProgramacaoFeriasAviso,
    title: 'Programação de Férias',
    path: '/programacao-ferias',
  },
  { component: Comercializacao, title: 'Comercialização', path: '/comercializacao' },
  { component: VinculoAvisoPrevio, title: 'Aviso Prévio', path: '/aviso-previo' },
  { component: AlterarCategoria, title: 'Alterar Categoria', path: '/alterar-categoria' },
  { component: AlterarInscricao, title: 'Alterar Número de Inscrição', path: '/alterar-inscricao' },
  { component: VinculoExameToxicologico, title: 'Exame Toxicológico', path: '/exame-toxicologico' },
  { component: ConsultaRP, title: 'Consultar Recibos', path: '/recibo-consulta' },
  { component: PontoEletronico, title: 'Ponto Eletrônico', path: '/ponto-eletronico' },
  { component: AutonomosLancamento, title: 'Autônomos Lançamento', path: '/autonomos-lancamento' },
  {
    component: TrabalhoIntermitente,
    title: 'Trabalho Intermitente',
    path: '/trabalho-intermitente',
  },
  { component: SeguroDesemprego, title: 'Seguro Desemprego', path: '/seguro-desemprego' },
  { component: CNAB, title: 'CNAB', path: '/gerar-cnab' },
  { component: GerarGRRF, title: 'Gerar GRRF', path: '/gerar-grrf' },
  { component: DARFIR, title: 'DARF IR', path: '/darf-ir' },
  { component: DARFPIS, title: 'DARF PIS', path: '/darf-pis' },
  { component: ValoresPorEvento, title: 'Valores Por Evento', path: '/valores-por-evento' },
  { component: SEFIP, title: 'SEFIP', path: '/gerar-sefip' },
  { component: RESefip, title: 'RE Sefip', path: '/re-sefip' },
  { component: Provisao13Salario, title: 'Provisão de 13° Salário', path: '/provisao-13-salario' },
  { component: ProvisaoFerias, title: 'Provisão de Férias', path: '/provisao-ferias' },
  { component: EventoEmpregador, title: 'Evento Empregador', path: '/evento-empregador' },
  {
    component: ComunicadoAcidenteTrabalho,
    title: 'Comunicado Acidente de Trabalho (CAT)',
    path: '/comunicado-acidente-trabalho',
  },
  {
    component: AtestadoSaudeOcupacional,
    title: 'Atestado Saúde Ocupacional (ASO)',
    path: '/atestado-saude-ocupacional-old',
  },
  {
    component: AtestadoSaudeOcupacionalNew,
    title: 'Atestado Saúde Ocupacional (ASO)',
    path: '/atestado-saude-ocupacional',
  },
  {
    component: ASOImportar,
    title: 'ASO Importação',
    path: '/aso-importacao',
  },
  {
    component: EquipamentoProtecaoIndividual,
    title: 'Equipamento de Proteção Individual',
    path: '/equipamento-protecao-individual',
  },
  {
    component: CondicaoAmbienteTrabalho,
    title: 'Condição Ambiente de Trabalho',
    path: '/condicao-ambiente-trabalho-old',
  },
  {
    component: CondicaoAmbienteTrabalhoNew,
    title: 'Condição Ambiente de Trabalho',
    path: '/condicao-ambiente-trabalho',
  },
  {
    component: AmbienteTrabalhoImportacao,
    title: 'Condição Ambiente de Trabalho Importação',
    path: '/condicao-ambiente-trabalho-importacao',
  },
  {
    component: ConsultaRPEstabelecimento,
    title: 'Consultar Recibos Estabelecimento',
    path: '/recibo-consulta-estabelecimento',
  },
  { component: VinculoReintegracao, title: 'Reintegração', path: '/reintegracao' },
  {
    component: PageInDevelopement,
    title: 'Alteração Salarial Coletiva',
    path: '/alteracao-salarial-coletiva',
  },
  {
    component: PageInDevelopement,
    title: 'Tabela de Incidência',
    path: '/tabela-incidencia-facultativo',
  },
  { component: PageInDevelopement, title: 'Cálculo e Impressao GPS', path: '/calculo-facultativo' },
  { component: PageInDevelopement, title: 'Importar TSV', path: '/importar-tsv' },
  { component: PageInDevelopement, title: 'Importar Cooperados', path: '/importar-cooperados' },
  { component: PageInDevelopement, title: 'Instituição de Ensino', path: '/importar-siga' },
  { component: PageInDevelopement, title: 'Exportar Folha de Pagamento', path: '/exportar-folha' },
  {
    component: PageInDevelopement,
    title: 'Ajuste Convenção Coletiva / Dissídio',
    path: '/ajuste-convencao-dissidio',
  },
  {
    component: PageInDevelopement,
    title: 'Comunicado de Perda de Férias',
    path: '/comunicado-perda-ferias',
  },
  { component: PageInDevelopement, title: 'Férias Coletivas', path: '/ferias-coletivas' },
  { component: PageInDevelopement, title: 'Simular Rescisão', path: '/simular-rescisao' },
  { component: PageInDevelopement, title: 'Resilisão', path: '/recibo-resilisao' },
  { component: PageInDevelopement, title: 'Homolognet Gerar Arquivo', path: '/gerar-homolognet' },
  { component: PageInDevelopement, title: 'Excluir Homolognet', path: '/homolognet-excluir' },
  { component: PageInDevelopement, title: 'Proventos', path: '/homolognet-proventos' },
  { component: PageInDevelopement, title: 'Descontos', path: '/homolognet-descontos' },
  { component: PageInDevelopement, title: 'Carta de Preposto', path: '/relatorio-carta-preposto' },
  {
    component: PageInDevelopement,
    title: 'Autorizaçao para Depósito',
    path: '/relatorio-autorizacao-deposito',
  },
  { component: PageInDevelopement, title: 'Resumo Folha', path: '/extrato' },
  { component: PageInDevelopement, title: 'Folha Coletiva', path: '/balancete' },
  {
    component: AlteracaoContratual,
    title: 'Alteração Contrato de Trabalho',
    path: '/alteracao-contratual-old',
  },
  {
    component: AlteracaoContratualNew,
    title: 'Alteração Contrato de Trabalho',
    path: '/alteracao-contratual',
  },
  {
    component: AlteracaoCadastral,
    title: 'Alterações Cadastrais',
    path: '/alteracao-cadastral-old',
  },
  {
    component: AlteracaoCadastralNew,
    title: 'Alterações Cadastrais',
    path: '/alteracao-cadastral',
  },
  { component: CertificadoDigital, title: 'CertificadoDigital', path: '/certificado-digital' },
  {
    component: PrevidenciaBeneficiario,
    title: 'Previdência por Beneficiário',
    path: '/previdencia-beneficiario',
  },
  { component: Servico, title: 'Serviço', path: '/servico' },
  { component: CargoAgenteNocivo, title: 'Cargo Agente Nocivo', path: '/cargo-agente-nocivo' },
  { component: SetorAgenteNocivo, title: 'Setor Agente Nocivo', path: '/setor-agente-nocivo' },
  {
    component: ResponsavelRegistroAmbiental,
    title: 'Responsável Registro Ambiental',
    path: '/responsavel-registro-ambiental',
  },
  {
    component: CondicaoAmbienteTrabalhoAutomatico,
    title: 'Condição Ambiente de Trabalho Automático',
    path: '/condicao-ambiente-trabalho-automatico',
  },
  { component: TransmissaoESocial, title: 'Transmissão eSocial', path: '/transmissao-esocial-old' },
  {
    component: TransmissaoESocialNew,
    title: 'Transmissão eSocial',
    path: '/transmissao-esocial/:step',
  },
  {
    component: ExclusaoEventosESocial,
    title: 'Exclusão de Eventos',
    path: '/exclusao-eventos-esocial',
  },
  { component: VinculoFaltas, title: 'Faltas', path: '/vinculo-faltas' },
  {
    component: ReaberturaMovimentoESocial,
    title: 'Reabertura Movimento eSocial',
    path: '/reabertura-movimento-esocial',
  },
  {
    component: InformacaoComplementarEmpregador,
    title: 'Informação compelementar Empregador',
    path: '/informacao-complementar-empregador',
  },
  {
    component: ContratacaoTrabalhadorAvulso,
    title: 'Contratação Trabalhador Avulso',
    path: '/contratacao-trabalhador-avulso',
  },
  { component: VinculoAfastamento, title: 'Afastamentos', path: '/vinculo-afastamento' },
  { component: VinculoFerias, title: 'Férias', path: '/vinculo-ferias' },
  {
    component: AdiantamentoSalario,
    title: 'Adiantamento de Salário',
    path: '/adiantamento-salario',
  },
  {
    component: ConfigurarAdiantamentoSalario,
    title: 'Configurar Adiantamento de Salário',
    path: '/configurar-adiantamento-salario',
  },
  {
    component: ProcessarAlteracaoSalario,
    title: 'Processar Alteração de Salário(2206)',
    path: '/processar-alteracao-salario',
  },
  { component: LGPD, title: 'LGPD', path: '/LGPD' },
  { component: RelacaoBancaria, title: 'Relação Bancária', path: '/relacao-bancaria' },
  {
    component: ContratoExperienciaProrrogacao,
    title: 'Contrato Experiência/Prorrogação',
    path: '/contrato-experiencia-prorrogacao',
  },
  {
    component: DemonstrativoRPEmail,
    title: 'Demonstrativo por Email',
    path: '/demonstrativo-rp-email',
  },
  {
    component: RelatorioFeriasVencidas.RelatorioFeriasVencidasMain,
    title: 'Relatório Férias Vencidas',
    path: '/relatorio-ferias-vencidas-main',
  },
  {
    component: RelatorioFeriasVencidas.RelatorioFeriasVencidasResult,
    title: 'Relatório Férias Vencidas',
    path: '/relatorio-ferias-vencidas-result',
  },
  {
    component: CondicaoAmbienteTrabalhoResumo,
    title: 'Condição ambiente de trabalho resumo',
    path: '/condicao-ambiente-trabalho-resumo',
  },
  {
    component: AutorizacaoDeposito,
    title: 'Autorização de Depósito',
    path: '/autorizacao-deposito',
  },
  { component: AcordoHoraExtra, title: 'Acordo de hora extra', path: '/acordo-hora-extra' },
  {
    component: CompensacaoHorasTrabalhadas,
    title: 'Acordo Compensação de Horas Trabalhadas',
    path: '/compensacao-horas-trabalhadas',
  },
  { component: AcordoBancoHoras, title: 'Acordo Banco de Horas', path: '/acordo-banco-horas' },
  {
    component: QuadroHorarioTrabalho,
    title: 'Quadro Horário de Trabalho',
    path: '/quadro-horario-trabalho',
  },
  {
    component: OrdemImpressaoEvento,
    title: 'Ordem de Impressão Evento',
    path: '/ordem-impressao-evento',
  },
  {
    component: TermoCompromissoEstagio,
    title: 'Termo de Compromisso de Estágio',
    path: '/termo-compromisso-estagio',
  },
  {
    component: FichaRegistroFuncionario,
    title: 'Ficha de Registro de Funcionário',
    path: '/ficha-registro-funcionario',
  },
  { component: RelatorioFolhaColetiva, title: 'Folha Coletiva', path: '/folha-coletiva' },
  {
    component: EnvioEventosAutomaticos,
    title: 'Envio de Eventos Automáticos',
    path: '/envio-eventos-automaticos',
  },
  { component: FinalizarRecibos, title: 'Finalizar Recibos', path: '/finalizar-recibos' },
  { component: RelatorioResumoFolha, title: 'Resumo Folha', path: '/resumo-folha' },
  { component: RelatorioContraCheque, title: 'Contracheque', path: '/relatorio-contracheque' },
  {
    component: ResumoAdmissaoDemissao,
    title: 'Resumo Admissão e Demissão',
    path: '/resumo-admissao-demissao',
  },
  {
    component: CancelarTransferencia,
    title: 'Cancelar Transferência',
    path: '/cancelar-transferencia-funcionario',
  },
  {
    component: RelatorioFeriasConcedidas,
    title: 'Relatório Férias Concedidas',
    path: '/form-relatorio-ferias-concedidas',
  },
  {
    component: ResultRelatorioFeriasConcedidas,
    title: 'Relatório Férias Concedidas',
    path: '/result-relatorio-ferias-concedidas',
  },
  { component: ParametrosSistema, title: 'Parâmetros do Sistema', path: '/parametros-sistema' },
  { component: RelatorioConfiguracao, title: 'Relatório', path: '/configuracao-relatorio' },
  { component: LotesPorEmpregador, title: 'Lotes por Empregador', path: '/lotes-empregador' },
  {
    component: AuditoriaPorEmpregador,
    title: 'Auditoria por Empregador',
    path: '/auditoria-empregador',
  },
  {
    component: LimparGerarAmbiente,
    title: 'Limpar ou Gerar Ambiente eSocial',
    path: '/limpar-gerar-ambiente',
  },
  {
    component: RelatorioFichaSalarioFamilia,
    title: 'Ficha de Salário Família',
    path: '/ficha-salario-familia',
  },
  {
    component: TermoResponsabilidadeDependentes,
    title: 'Termo de Responsabilidade',
    path: '/termo-responsabilidade-dependentes',
  },
  {
    component: ComunicadoPerdaDireitoFerias,
    title: 'Comunicado de Perda do Direito a Férias',
    path: '/comunicado-perda-direito-ferias',
  },
  {
    component: DocumentoArrecadacao,
    title: 'Documento de Arrecadação',
    path: '/documento-arrecadacao',
  },
  {
    component: RequerimentoAbonoPecuniario,
    title: 'Requerimento de Abono Pecuniário',
    path: '/requerimento-abono-pecuniario',
  },
  {
    component: CartaAbandonoEmprego,
    title: 'Carta de Abandono de Emprego',
    path: '/carta-abandono-emprego',
  },
  {
    component: DeclaracaoUsoPlanoSaude,
    title: 'Declaração uso de Plano de Saúde',
    path: '/declaracao-uso-plano-saude',
  },
  {
    component: ComunicadoTerminoExperiencia,
    title: 'Término Contrato Experiência',
    path: '/comunicado-termino-experiencia',
  },
  {
    component: ValeTransnporteDeclaracaoUso,
    title: 'Vale Transporte Declaração de Uso',
    path: '/vale-transporte-declaracao-uso',
  },
  {
    component: CartaApresentacao,
    title: 'Carta de Apresentação',
    path: '/carta-apresentacao',
  },
  {
    component: DeclaracaoQuitacaoAnual,
    title: 'Declaração de Quitação Anual',
    path: '/declaracao-quitacao-anual',
  },
  {
    component: DeclaracaoSalarioContribuicao,
    title: 'Declaração para Salário Contribuição',
    path: '/declaracao-salario-contribuicao',
  },
  {
    component: RescisaoContratoExperiencia,
    title: 'Rescisão Antecipada Contrato Experiência',
    path: '/rescisao-contrato-experiencia',
  },
  {
    component: ListaPonto,
    title: 'Lista de Ponto',
    path: '/lista-ponto',
  },
  {
    component: FolhaPonto,
    title: 'Folha de Ponto',
    path: '/folha-ponto',
  },
  {
    component: DadosCadastraisFuncionario,
    title: 'Relatório de Dados Cadastrais Funcionário',
    path: '/dados-cadastrais-funcionario',
  },
  {
    component: ApuracaoEncargos,
    title: 'Apuração de Encargos',
    path: '/apuracao-encargos',
  },
  {
    component: ManutencaoAtividadeConcomitante,
    title: 'Manutenção de Atividade Concomitante',
    path: '/manutencao-atividade-concomitante',
  },
  {
    component: VinculoEstabilidade,
    title: 'Estabilidade por Funcionário',
    path: '/vinculo-estabilidade',
  },
  {
    component: VinculoRescisaoSimulada,
    title: 'Rescisão Simulada',
    path: '/rescisao-simulada',
  },
  {
    component: VinculoLotacao,
    title: 'Lotação Funcionário',
    path: '/vinculo-lotacao',
  },
  {
    component: VinculoLotacao,
    title: 'Lotação TSV',
    path: '/vinculo-lotacao/tsv',
  },
  {
    component: VinculoEventoAutomatico,
    title: 'Eventos Automáticos Funcionário',
    path: '/vinculo-evento-automatico',
  },
  {
    component: VinculoEventoAutomatico,
    title: 'Eventos Automáticos TSV',
    path: '/vinculo-evento-automatico/tsv',
  },
  {
    component: ContratoTrabalhoIndeterminado,
    title: 'Contrato Trabalho Indeterminado',
    path: '/contrato-trabalho-indeterminado',
  },
  {
    component: TermoMonitoramentoCameras,
    title: 'Termo de Monitoramento por Câmeras',
    path: '/termo-monitoramento-cameras',
  },
  {
    component: TermoAutorizacaoImagemVoz,
    title: 'Termo de Autorização de Uso de Imagem e Voz',
    path: '/termo-autorizacao-imagem-voz',
  },
  {
    component: AutorizacaoDescontos,
    title: 'Autorização de Descontos',
    path: '/autorizacao-descontos',
  },
  {
    component: RequerimentoBeneficioIncapacidade,
    title: 'Requerimento de Benefício por Incapacidade',
    path: '/requerimento-beneficio-incapacidade',
  },
  {
    component: RescisaoTSV,
    title: 'Rescisão TSV',
    path: '/rescisao-tsv',
  },
  {
    component: RelSalarioContratual,
    title: 'Relatório - Salário Contratual',
    path: '/relatorio-salario-contratual',
  },
  {
    component: FeriasColetiva,
    title: 'Férias Coletiva',
    path: '/ferias-coletiva',
  },
  {
    component: VencimentoContratoExperiencia,
    title: 'Relatório - Vencimento Contrato Experiência',
    path: '/vencimento-contrato-experiencia',
  },
  {
    component: RescisaoComplementar,
    title: 'Rescisão Complementar',
    path: '/rescisao-complementar',
  },
  {
    component: DIRF,
    title: 'DIRF',
    path: '/dirf',
  },
  {
    component: GerenciadorDocumentos,
    title: 'Gerenciador Documentos',
    path: '/gerenciador-documentos',
  },
  {
    component: EtiquetaCTPS,
    title: 'Etiqueta CTPS',
    path: '/etiqueta-ctps',
  },
  {
    component: EtiquetaContratoExperiencia,
    title: 'Etiqueta Contrato Experiência',
    path: '/etiqueta-contrato-experiencia',
  },
  {
    component: ConsultaVencimentos,
    title: 'Consulta Vencimentos',
    path: '/consulta-vencimentos',
  },
  {
    component: FichaFornecimentoEPI,
    title: 'Ficha de Fornecimento e Controle de EPI',
    path: '/ficha-fornecimento-control-epi',
  },
  {
    component: RPAutonomo,
    title: 'Recibo Pagamento Autônomo',
    path: '/recibo-pagamento-autonomo',
  },
  {
    component: AntecipacaoSalarial,
    title: 'Antecipação Salarial',
    path: '/antecipacao-salarial',
  },
  {
    component: Terceiros,
    title: 'Terceiros',
    path: '/terceiros',
  },
  {
    component: AuxiliaresFuncionario,
    title: 'Auxiliares Funcionário',
    path: '/auxiliares-funcionario',
  },
  {
    component: AuxiliaresCadastro,
    title: 'Auxiliares Cadastro',
    path: '/auxiliares-cadastro',
  },
  {
    component: FGTSBeneficiario,
    title: 'FGTS por Beneficiário',
    path: '/fgts-beneficiario',
  },
  {
    component: ApurarConvencaoColetiva,
    title: 'Apurar Convenção Coletiva',
    path: '/apurar-convencao-coletiva',
  },
  {
    component: ProcessarConvencaoColetiva,
    title: 'Processar Convenção Coletiva',
    path: '/processar-convencao-coletiva',
  },
  {
    component: FechamentoESocial,
    title: 'Fechamento eSocial',
    path: '/fechamento-esocial',
  },
  {
    component: RelSalarialCargo,
    title: 'Relatório Salarial Por Cargo',
    path: '/relatorio-salarial-cargo',
  },
  {
    component: ManutencaoEmpregador,
    title: 'Manutenção Empregador',
    path: '/manutencao-empregador',
  },
  {
    component: ManutencaoAutonomo,
    title: 'Manutenção Autônomo',
    path: '/manutencao-autonomo',
  },
  {
    component: EtiquetaAvisoPrevio,
    title: 'Etiqueta Aviso Prévio',
    path: '/etiqueta-aviso-previo',
  },
  {
    component: ResumoContabilFolha,
    title: 'Resumo Contábil da Folha',
    path: '/resumo-contabil-folha',
  },
  {
    component: ValeTransporteFuncionario,
    title: 'Configuração por Funcionário',
    path: '/vale-transporte-funcionario',
  },
  {
    component: ValeTransporteApuracao,
    title: 'Apuração do Vale',
    path: '/vale-transporte-apuracao',
  },
  {
    component: IRRFBeneficiario,
    title: 'IRRF por Beneficiário',
    path: '/irrf-beneficiario',
  },
  {
    component: ProgramacaoFeriasAviso,
    title: 'Aviso de Férias',
    path: '/aviso-ferias',
  },
  {
    component: ConfiguracoesNotificacoes,
    title: 'Configurações das Notificações',
    path: '/configuracoes-notificacoes',
  },
  {
    component: ImportacaoPlanilhasEventos,
    title: 'Importação de Eventos',
    path: '/importacao-eventos',
  },
  {
    component: ImportacaoPlanilhaFaltas,
    title: 'Importação de Faltas',
    path: '/importacao-faltas',
  },
  {
    component: DeclContriSindical,
    title: 'Declaração de Contribuição Sindical',
    path: '/declaracao-contribuicao-sindical',
  },
  {
    component: EtiquetaCartaoPonto,
    title: 'Etiqueta Cartão de Ponto',
    path: '/etiqueta-cartao-ponto',
  },
  {
    component: DeclaracaoEncargosFamilia,
    title: 'Declaração de Encargos de Família',
    path: '/declaracao-encargos-familia',
  },
  {
    component: ReciboTermoConduta,
    title: 'Recibo Termo de Conduta',
    path: '/recibo-termo-conduta',
  },
  {
    component: RelatorioAniversariantes,
    title: 'Relatório Aniversariantes',
    path: '/relatorio-aniversariantes',
  },
  {
    component: VinculoAdvertencia,
    title: 'Advertências',
    path: '/funcionario-advertencia',
  },
  {
    component: VinculoSuspensao,
    title: 'Suspensões',
    path: '/funcionario-suspensao',
  },
  {
    component: MotivosAdvertenciaSuspensao,
    title: 'Motivos - Advertência / Suspensão',
    path: '/motivos-advertencia-suspensao',
  },
  {
    component: Contribuintes,
    title: 'Contribuintes Facultativo',
    path: '/contribuinte-facultativo',
  },
  {
    component: Contribuicoes,
    title: 'Contribuições Facultativo',
    path: '/contribuicao-facultativo',
  },
  {
    component: Salario,
    title: 'Salários',
    path: '/salario',
  },
  {
    component: ProcessoTrabalhista,
    title: 'Processo Trabalhista',
    path: '/processo-trabalhista',
  },
  {
    component: ProcessoTrabalhistaFuncionario,
    title: 'Processo Trabalhista Funcionário S-2500',
    path: '/processo-trabalhista-funcionario',
  },
  {
    component: ProvisaoSaldoInicial,
    title: 'Provisão Saldo Inicial',
    path: '/provisao-saldo-inicial',
  },
  {
    component: ProcessoTrabalhistaTributos,
    title: 'Processo Trabalhista Tributos S-2501',
    path: '/processo-trabalhista-tributos',
  },
  {
    component: ProcessoTrabalhistaManutencaoVinculo,
    title: 'Processo Trabalhista Manutenção Cadastro',
    path: '/processo-trabalhista-manutencao-cadastro',
  },
  {
    component: ProcessoTrabalhistaRescisaoZerada,
    title: 'Processo Trabalhista Rescisão Zerada',
    path: '/processo-trabalhista-rescisao-zerada',
  },
  {
    component: ContratoIntermitente,
    title: 'Contrato Intermitente',
    path: '/contrato-intermitente',
  },
  {
    component: CartaOposicaoSindicato,
    title: 'Carta de Oposição Sindicato',
    path: '/carta-oposicao-sindicato',
  },
  {
    component: AtualizarCTPS,
    title: 'Atualizar CTPS',
    path: '/atualizar-ctps',
  },
  {
    component: EtiquetaFerias,
    title: 'Etiqueta Férias Concedidas',
    path: '/etiqueta-ferias',
  },
  {
    component: EtiquetaAlteracaoSalarial,
    title: 'Etiqueta de Alteração Salarial',
    path: '/etiqueta-alteracao-salarial',
  },
  {
    component: VinculoTrabalhoFeriado,
    title: 'Controle de Feriado',
    path: '/controle-feriado',
  },
]

export default routes
