import React, { useEffect, useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'

import { Box, Divider, Grid, makeStyles } from '@material-ui/core'

import { Button, TextField } from '~/components'
import DatePicker from '~/components/DatePickerNew'

import ContainerFerias from '../components/ContainerFerias'
import Notification from '../components/Notification'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { useValidarFeriasAtual } from '~/hooks/queries/VinculoFerias/useValidarFeriasAtual'

import { FeriasConcedida, FeriasFormData, initialStateFormFerias } from '..'
import { DayOfWeekEnum, DayOfWeekValues } from '~/@types/enums/DayOfWeekEnum'
import { IndFeriasEnum } from '~/@types/enums/IndFeriasEnum'
import { JornadaTipoEnum } from '~/@types/enums/JornadaTipoEnum'

const useStyles = makeStyles((theme) => ({
  colorDanger: {
    fontSize: '11pt',
    color: theme.palette.error.main,
  },
}))

const schema = yup.object().shape({
  nrDiasFerias: yup.number().required('Informe a quantidade de dias de Concessão de Férias'),
  dtInicioConcessao: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .required('Informe o Início das Férias')
    .when(['$anoMes', '$isFeriasProgramacao'], (anoMes, isFeriasProgramacao, schema) => {
      if (isFeriasProgramacao) return schema
      return schema.test(
        'test-dt-inicio-in-competencia',
        'Informe a Data de Início das Férias dentro da competência atual ' +
          anoMes.substring(0, 4) +
          '/' +
          anoMes.substring(4),
        (value: FixLater) =>
          moment(anoMes).isSameOrBefore(value) && moment(anoMes).add(1, 'month').isAfter(value),
      )
    }),
  dtRetorno: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Retorno'),
  dtEmissaoFerias: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Pagamento'),
  dtAvisoFerias: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .required('Informe o Aviso das Férias'),
  dtInicioAbono: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(
      ['$hasDiasAbono', 'dtInicioConcessao', 'dtFimConcessao'],
      (hasDiasAbono, dtInicioConcessao, dtFimConcessao, schema) => {
        if (hasDiasAbono) {
          const inicioConcessao = moment(dtInicioConcessao).subtract(1, 'd').format('yyyy-MM-DD')
          const fimConcessao = moment(dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')
          return schema.required('Informe o Início do Abono').test({
            name: 'test',
            exclusive: true,
            params: { inicioConcessao, fimConcessao },
            message: 'Informe o Início do Abono fora do Período das Férias',
            test: (dtInicioAbono: string) =>
              !moment(dtInicioAbono).isBetween(inicioConcessao, fimConcessao),
          })
        }
      },
    ),
  dtFimAbono: yup
    .date()
    .nullable()
    .typeError('Informe uma data válida')
    .when(
      ['$hasDiasAbono', 'dtInicioConcessao', 'dtFimConcessao'],
      (hasDiasAbono, dtInicioConcessao, dtFimConcessao, schema) => {
        if (hasDiasAbono) {
          const inicioConcessao = moment(dtInicioConcessao).subtract(1, 'd').format('yyyy-MM-DD')
          const fimConcessao = moment(dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')
          return schema.required('Informe o Fim do Abono').test({
            name: 'test',
            exclusive: true,
            params: { inicioConcessao, fimConcessao },
            message: 'Informe o Fim do Abono fora do Período das Férias',
            test: (dtFimAbono: string) =>
              !moment(dtFimAbono).isBetween(inicioConcessao, fimConcessao),
          })
        }
      },
    ),
})

interface FeriasAtualForm {
  nrDiasFeriasDireito: number
  nrDiasFeriasJaConcedido: number
  descansoSemanalFuncionario: DayOfWeekEnum
  tipoJornadaFuncionario: JornadaTipoEnum
  feriados: string[]
  periodoFeriasConcedidas: FeriasConcedida[]
}

interface FormFeriasProps {
  data: FeriasFormData
  feriasAtual: FeriasAtualForm
  onRemovePeriodoFerias: () => void
  onSubmit: (data: FeriasFormData) => void
  onCancel: () => void
  isFeriasProgramacao: boolean
}

export default function FormFerias(props: FormFeriasProps) {
  const {
    data: _data,
    feriasAtual,
    onRemovePeriodoFerias,
    onSubmit,
    onCancel,
    isFeriasProgramacao,
  } = props
  const {
    descansoSemanalFuncionario,
    tipoJornadaFuncionario,
    feriados,
    nrDiasFeriasDireito,
    nrDiasFeriasJaConcedido,
    periodoFeriasConcedidas,
  } = feriasAtual

  const [data, setData] = useState<FeriasFormData>(initialStateFormFerias)
  const [maxDtsValidate, setMaxDtsValidate] = useState<{
    dtEmissaoFerias: string | null
    dtAvisoFerias: string | null
  }>({
    dtEmissaoFerias: null,
    dtAvisoFerias: null,
  })
  const [notificationsWarning, setNotificationsWarning] = useState<string[]>([])
  const [notificationsError, setNotificationsError] = useState<string[]>([])

  const hasDiasFerias = data.nrDiasFerias > 0
  const hasDiasAbono = data.nrDiasAbono > 0

  const isProgramacaoFerias = data.vinculoFeriasProgramacaoId ? true : false
  const totalNrDiasFeriasAtual = nrDiasFeriasJaConcedido + data.nrDiasFerias + data.nrDiasAbono

  const isDiasFeriasMaiorQueDiasDireito = totalNrDiasFeriasAtual > nrDiasFeriasDireito

  const maxNrDiasValidate = {
    nrDiasFerias: nrDiasFeriasDireito - nrDiasFeriasJaConcedido,
    nrDiasAbono: Math.trunc(nrDiasFeriasDireito / 3),
  }

  const classes = useStyles()
  const { anoMes } = useAmbiente()
  const { validationErrors, handleValidate } = useValidationErrors({
    data,
    schema,
    handleSubmit: handleValidateFerias,
    schemaOptions: {
      abortEarly: false,
      context: {
        hasDiasAbono,
        anoMes,
        isFeriasProgramacao,
      },
    },
  })
  const dialogNotification = useDialogNotification()

  const { mutateAsync: mutateAsyncValidarFeriasAtual, isLoading: isLoadingValidarFeriasAtual } =
    useValidarFeriasAtual()

  useEffect(() => {
    setData(_data)
  }, [_data])

  function handleGenerateNotificationWarning(notification: string) {
    setNotificationsWarning((prev) => [...prev, notification])
  }

  function calcularDtInicioConcessao(dtInicioConcessao: moment.Moment): moment.Moment {
    const initialMessage = 'Data de Início das Férias: '
    const descansoSemanalString =
      DayOfWeekValues.find((d) => d.value === descansoSemanalFuncionario)?.name || '-'
    if (dtInicioConcessao.day() === DayOfWeekEnum.Sabado_6) {
      dtInicioConcessao = calcularDtInicioConcessao(dtInicioConcessao.subtract(1, 'day'))
      handleGenerateNotificationWarning(
        initialMessage +
          'Foi ajustada para ' +
          dtInicioConcessao.format('DD/MM/yyyy') +
          ' não pode iniciar no Sábado.',
      )
    }

    if (dtInicioConcessao.day() === DayOfWeekEnum.Domingo_0) {
      dtInicioConcessao = calcularDtInicioConcessao(dtInicioConcessao.subtract(2, 'day'))
      handleGenerateNotificationWarning(
        initialMessage +
          'Foi ajustada para ' +
          dtInicioConcessao.format('DD/MM/yyyy') +
          ' não pode iniciar no Domingo.',
      )
    }

    //Exemplo: Descanso semanal domingo, se cair no domingo deve ajustar para quinta
    if (dtInicioConcessao.day() === descansoSemanalFuncionario) {
      dtInicioConcessao = calcularDtInicioConcessao(dtInicioConcessao.subtract(3, 'day'))
      handleGenerateNotificationWarning(
        initialMessage +
          'Foi ajustada para ' +
          dtInicioConcessao.format('DD/MM/yyyy') +
          ' não pode iniciar próximo ao Descanso Semanal.' +
          '(' +
          descansoSemanalString +
          ')',
      )
    }

    //Exemplo: Descanso semanal domingo, se cair no sabádo deve ajustar para quinta
    if (
      dtInicioConcessao.day() ===
      moment()
        .day(descansoSemanalFuncionario - 1)
        .day()
    ) {
      dtInicioConcessao = calcularDtInicioConcessao(dtInicioConcessao.subtract(2, 'day'))
      handleGenerateNotificationWarning(
        initialMessage +
          'Foi ajustada para ' +
          dtInicioConcessao.format('DD/MM/yyyy') +
          ' não pode iniciar próximo ao Descanso Semanal.' +
          '(' +
          descansoSemanalString +
          ')',
      )
    }

    //Exemplo: Descanso semanal domingo, se cair na sexta deve ajustar para quinta
    if (
      dtInicioConcessao.day() ===
      moment()
        .day(descansoSemanalFuncionario - 2)
        .day()
    ) {
      dtInicioConcessao = calcularDtInicioConcessao(dtInicioConcessao.subtract(1, 'day'))
      handleGenerateNotificationWarning(
        initialMessage +
          'Foi ajustada para ' +
          dtInicioConcessao.format('DD/MM/yyyy') +
          ' não pode iniciar próximo ao Descanso Semanal.' +
          '(' +
          descansoSemanalString +
          ')',
      )
    }

    const feriadoFinded = feriados.find(
      (dtFeriado) => dtFeriado === dtInicioConcessao.format('yyyy-MM-DD'),
    )
    if (feriadoFinded) {
      dtInicioConcessao = calcularDtInicioConcessao(dtInicioConcessao.subtract(1, 'day'))
      handleGenerateNotificationWarning(
        initialMessage +
          'Foi ajustada para ' +
          dtInicioConcessao.format('DD/MM/yyyy') +
          ' não pode iniciar em um Feriado.' +
          '(' +
          feriadoFinded +
          ')',
      )
    }

    return dtInicioConcessao
  }

  function calcularDtAvisoDtEmissao(
    initialMessageNotification: string,
    dtAvisoDtEmissao: moment.Moment,
  ): moment.Moment {
    if (dtAvisoDtEmissao.day() === DayOfWeekEnum.Sabado_6) {
      dtAvisoDtEmissao = calcularDtAvisoDtEmissao(
        initialMessageNotification,
        dtAvisoDtEmissao.subtract(1, 'day'),
      )
      handleGenerateNotificationWarning(
        initialMessageNotification +
          'Foi ajustada para ' +
          dtAvisoDtEmissao.format('DD/MM/yyyy') +
          ' não pode ser no Sábado.',
      )
    }

    if (dtAvisoDtEmissao.day() === DayOfWeekEnum.Domingo_0) {
      dtAvisoDtEmissao = calcularDtAvisoDtEmissao(
        initialMessageNotification,
        dtAvisoDtEmissao.subtract(2, 'day'),
      )
      handleGenerateNotificationWarning(
        initialMessageNotification +
          'Foi ajustada para ' +
          dtAvisoDtEmissao.format('DD/MM/yyyy') +
          ' não pode ser no Domingo.',
      )
    }

    if (dtAvisoDtEmissao.day() === descansoSemanalFuncionario) {
      dtAvisoDtEmissao = calcularDtAvisoDtEmissao(
        initialMessageNotification,
        dtAvisoDtEmissao.subtract(1, 'day'),
      )
      handleGenerateNotificationWarning(
        initialMessageNotification +
          'Foi ajustada para ' +
          dtAvisoDtEmissao.format('DD/MM/yyyy') +
          ' não pode ser no Descanso Semanal.',
      )
    }

    const feriadoFinded = feriados.find(
      (dtFeriado) => dtFeriado === dtAvisoDtEmissao.format('yyyy-MM-DD'),
    )
    if (feriadoFinded) {
      dtAvisoDtEmissao = calcularDtInicioConcessao(dtAvisoDtEmissao.subtract(1, 'day'))
      handleGenerateNotificationWarning(
        initialMessageNotification +
          'Foi ajustada para ' +
          dtAvisoDtEmissao.format('DD/MM/yyyy') +
          ' não pode ser em um Feriado.' +
          '(' +
          feriadoFinded +
          ')',
      )
    }

    return dtAvisoDtEmissao
  }

  function handleChangeIndFerias(nrDiasFerias: number, nrDiasAbono: number) {
    const totalNrDiasFeriasAtualInd = nrDiasFeriasJaConcedido + nrDiasFerias + nrDiasAbono
    const indFerias =
      totalNrDiasFeriasAtualInd === nrDiasFeriasDireito
        ? IndFeriasEnum.Mensal_0
        : IndFeriasEnum.Fracionada_3
    setData((prev) => ({
      ...prev,
      indFerias,
    }))
  }

  function handleChangeDiasConcessao(e: React.ChangeEvent<HTMLInputElement>) {
    const nrDiasFerias = parseInt(e.target.value) || 0
    handleChangeIndFerias(nrDiasFerias, data.nrDiasAbono)
    setData((oldState) => ({
      ...oldState,
      nrDiasFerias,
      dtInicioConcessao: null,
      dtFimConcessao: null,
      dtEmissaoFerias: null,
      dtAvisoFerias: null,
      nrDiasAbono: 0,
      dtInicioAbono: null,
      dtFimAbono: null,
      dtRetorno: null,
    }))
  }

  function handleChangeInicioConcessao(dtInicioConcessao: string | null) {
    let dtAvisoFerias: null | string = null
    let dtEmissaoFerias: null | string = null
    let dtFimConcessao: null | string = null
    let dtRetorno: null | string = null

    if (dtInicioConcessao) {
      setNotificationsWarning([])

      dtInicioConcessao =
        tipoJornadaFuncionario === JornadaTipoEnum.Jornada_12x36_2
          ? dtInicioConcessao
          : calcularDtInicioConcessao(moment(dtInicioConcessao)).format('yyyy-MM-DD')
      dtAvisoFerias = calcularDtAvisoDtEmissao(
        'Data de Aviso das Férias: ',
        moment(dtInicioConcessao).subtract(1, 'months'),
      ).format('yyyy-MM-DD')
      dtEmissaoFerias = calcularDtAvisoDtEmissao(
        'Data de Pagamento das Férias: ',
        moment(dtInicioConcessao).subtract(2, 'days'),
      ).format('yyyy-MM-DD')
      dtFimConcessao = moment(dtInicioConcessao)
        .add(data.nrDiasFerias - 1, 'd')
        .format('yyyy-MM-DD')
      dtRetorno = moment(dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')

      setMaxDtsValidate({
        dtAvisoFerias,
        dtEmissaoFerias,
      })
    }

    setData((oldState) => ({
      ...oldState,
      dtInicioConcessao,
      dtAvisoFerias,
      dtEmissaoFerias,
      dtFimConcessao,
      dtRetorno,
    }))
  }

  function handleChangeDiasAbono(e: React.ChangeEvent<HTMLInputElement>) {
    const nrDiasAbono = parseInt(e.target.value) || 0
    let dtInicioAbono: null | string = null
    let dtFimAbono: null | string = null

    handleChangeIndFerias(data.nrDiasFerias, nrDiasAbono)

    if (data.dtFimConcessao && nrDiasAbono > 0) {
      dtInicioAbono = moment(data.dtFimConcessao).add(1, 'd').format('yyyy-MM-DD')
      dtFimAbono = moment(data.dtFimConcessao).add(nrDiasAbono, 'd').format('yyyy-MM-DD')
    }
    setData((oldState) => ({
      ...oldState,
      nrDiasAbono,
      dtInicioAbono,
      dtFimAbono,
    }))
  }

  function handleChangeInicioAbono(dtInicioAbono: string | null) {
    let dtFimAbono: string | null = null

    if (dtInicioAbono) {
      dtFimAbono = moment(dtInicioAbono)
        .add(data.nrDiasAbono - 1, 'd')
        .format('yyyy-MM-DD')
    }

    setData((oldState) => ({
      ...oldState,
      dtInicioAbono,
      dtFimAbono,
    }))
  }

  function handleValidateFerias() {
    const erroMessages = []

    if (data.nrDiasFerias > maxNrDiasValidate.nrDiasFerias) {
      erroMessages.push(`Informe no máximo ${maxNrDiasValidate.nrDiasFerias} Dias de Concessão`)
    }

    if (data.nrDiasFerias < 5) {
      erroMessages.push(`Não é possível criar uma Férias com menos de 5 Dias de Concessão`)
    }

    if (
      data.dtEmissaoFerias &&
      !moment(data.dtEmissaoFerias).isSameOrBefore(maxDtsValidate.dtEmissaoFerias)
    ) {
      erroMessages.push(
        'A Data de Pagamento de Férias deve ser inferior a ' +
          moment(maxDtsValidate.dtEmissaoFerias).add(1, 'day').format('DD/MM/yyyy'),
      )
    }

    if (
      data.dtAvisoFerias &&
      !moment(data.dtAvisoFerias).isSameOrBefore(maxDtsValidate.dtAvisoFerias)
    ) {
      erroMessages.push(
        'A Data de Aviso das Férias deve ser inferior a ' +
          moment(maxDtsValidate.dtAvisoFerias).add(1, 'day').format('DD/MM/yyyy'),
      )
    }

    if (data.nrDiasAbono > maxNrDiasValidate.nrDiasAbono) {
      erroMessages.push(
        `Informe no máximo 1/3 dos Dias de Direito de Férias (${maxNrDiasValidate.nrDiasAbono} dias)`,
      )
    }

    if (isDiasFeriasMaiorQueDiasDireito) {
      erroMessages.push(
        'A soma dos dias para o Período atual não pode ultrapassar ' + nrDiasFeriasDireito + '.',
      )
    }

    if (periodoFeriasConcedidas.length === 3) {
      erroMessages.push('Número máximo de Períodos de Férias atingido.')
    }

    if (periodoFeriasConcedidas.length === 2) {
      const hasPeriodoAtLeast14Days = periodoFeriasConcedidas.some((d) => d.nrDiasFerias >= 14)
      if (!hasPeriodoAtLeast14Days && !(data.nrDiasFerias >= 14)) {
        erroMessages.push('Pelo menos um dos períodos deve ser de 14 dias.')
      }

      if (nrDiasFeriasDireito !== totalNrDiasFeriasAtual) {
        erroMessages.push(
          '3° Recibo de Férias sendo criado, necessário utilizar todos os dias do Saldo.',
        )
      }
    }

    setNotificationsError(erroMessages)

    if (erroMessages.length > 0) {
      dialogNotification.error({
        title: 'Temos algumas notificações para você',
        descriptions: erroMessages,
        labelOnConfirm: 'Criar as Férias mesmo assim',
        onConfirm: () => handleValidationFinal(data),
      } as FixLater)
    } else {
      handleValidationFinal(data)
    }
  }

  async function handleValidationFinal(data: FeriasFormData) {
    const problemas = await mutateAsyncValidarFeriasAtual({
      vinculoId: data.vinculoId || '',
      dtInicioFerias: data.dtInicioConcessao || '',
    })

    if (problemas.length > 0) {
      dialogNotification.error({
        descriptions: problemas,
        labelOnCancel: 'Cancelar',
        labelOnConfirm: 'Confirmar',
        onConfirm: () => onSubmit(data),
        onCancel: () => undefined,
      })
    } else {
      onSubmit(data)
    }
  }

  return (
    <ContainerFerias
      style={{
        height: 'auto',
        paddingBottom: '16px',
      }}
      title={
        'Férias' +
        (data.indFerias !== null && !isDiasFeriasMaiorQueDiasDireito
          ? data.indFerias === IndFeriasEnum.Mensal_0
            ? ' - Mensal'
            : ' - Fracionada'
          : '')
      }
      disabled={isProgramacaoFerias}
      titleHover={isProgramacaoFerias ? 'Período Programado Selecionado' : ''}
      renderRight={() => (
        <Box display="flex" gridGap={8}>
          <Box display="flex" alignItems="center" gridGap={4}>
            <Box>Total dias</Box>
            <Box>
              <strong className={isDiasFeriasMaiorQueDiasDireito ? classes.colorDanger : ''}>
                {totalNrDiasFeriasAtual}
              </strong>
              /{nrDiasFeriasDireito}
            </Box>
          </Box>

          <Notification
            notifications={notificationsError}
            onChange={setNotificationsError}
            severity="error"
          />
          <Notification
            notifications={notificationsWarning}
            onChange={setNotificationsWarning}
            severity="warning"
          />

          {isProgramacaoFerias && (
            <Button
              style={{
                marginBottom: 8,
              }}
              onClick={onRemovePeriodoFerias}
            >
              Desconsiderar Programação
            </Button>
          )}
        </Box>
      )}
      renderActions={() => (
        <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%" gridGap={8}>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button variant="contained" onClick={handleValidate}>
            Confirmar
          </Button>
        </Box>
      )}
    >
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 4,
        }}
      >
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <TextField
            required
            label="Dias de Concessão"
            validationErrors={validationErrors}
            name="nrDiasFerias"
            value={data.nrDiasFerias}
            onChange={handleChangeDiasConcessao}
            onlyNumber
            inputProps={{
              maxLength: 5,
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DatePicker
            name="dtInicioConcessao"
            label="Início das Férias"
            autoFocus
            // disabled={!hasDiasFerias}
            required
            validationErrors={validationErrors}
            value={data.dtInicioConcessao}
            onChange={(date) => handleChangeInicioConcessao(date)}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DatePicker
            name="dtFimConcessao"
            required
            label="Fim das Férias"
            disabled
            value={data.dtFimConcessao}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DatePicker
            name="dtRetorno"
            required
            label="Data de Retorno"
            validationErrors={validationErrors}
            value={data.dtRetorno}
            onChange={(date) => setData((prev) => ({ ...prev, dtRetorno: date }))}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DatePicker
            label="Data de Pagamento"
            name="dtEmissaoFerias"
            disabled={!hasDiasFerias}
            required
            validationErrors={validationErrors}
            value={data.dtEmissaoFerias}
            onChange={(date) => setData((prev) => ({ ...prev, dtEmissaoFerias: date }))}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DatePicker
            label="Aviso das Férias"
            name="dtAvisoFerias"
            disabled={!hasDiasFerias}
            required
            validationErrors={validationErrors}
            value={data.dtAvisoFerias}
            onChange={(date) => setData((prev) => ({ ...prev, dtAvisoFerias: date }))}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <TextField
            label="Dias de Abono"
            name="nrDiasAbono"
            onlyNumber
            validationErrors={validationErrors}
            value={data?.nrDiasAbono || 0}
            inputProps={{
              maxLength: 5,
            }}
            onChange={handleChangeDiasAbono}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DatePicker
            label="Início do Abono"
            required={hasDiasAbono}
            disabled={!hasDiasAbono}
            validationErrors={validationErrors}
            name="dtInicioAbono"
            value={data.dtInicioAbono}
            onChange={(date) => handleChangeInicioAbono(date)}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <DatePicker
            label="Fim do Abono"
            required={hasDiasAbono}
            disabled
            validationErrors={validationErrors}
            name="dtFimAbono"
            value={data.dtFimAbono}
          />
        </Grid>
      </Grid>
    </ContainerFerias>
  )
}
