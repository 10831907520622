import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { TextField } from 'mio-library-ui'
import { Grid } from '@material-ui/core'

import { DatePicker } from '~/components'
import { useStepContext, StepButtons } from '~/components/StepContent'

import FormHorariosBase, { schemaFields } from '../FormHorariosBase'

import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  dtEfetiva: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data efetiva')
    .nullable(),
  textoCompensacaoHorasExtraordinarias: yup
    .string()
    .required('Informe a Horas trabalhadas após a oitava hora diária de trabalho'),
  ...schemaFields,
})

export default function JornadaNormal(props) {
  const { onSubmit, data: _data } = props

  const [data, setData] = useState({})

  const { handleNext } = useStepContext()

  useEffect(() => {
    setData({ ..._data, isUse: false })
  }, [_data])

  function handleSubmit() {
    onSubmit({ ...data, isUse: true })
    handleNext()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  return (
    <Grid container spacing={2}>
      <Grid item xl={12}>
        <DatePicker
          label="Data Efetiva"
          size="small"
          value={data?.dtEfetiva || null}
          onChange={(date) => {
            const dtEfetiva = date ? date.format('yyyy-MM-DD') : null
            setData((prevState) => ({ ...prevState, dtEfetiva }))
          }}
          validationErrors={validationErrors}
          name="dtEfetiva"
          required
        />
      </Grid>

      <FormHorariosBase data={data} setData={setData} validationErrors={validationErrors} />

      <Grid item xs={12}>
        <TextField
          label="Horas trabalhadas após a oitava hora diária de trabalho"
          fullWidth
          value={data?.textoCompensacaoHorasExtraordinarias || ''}
          variant="outlined"
          size="small"
          inputProps={{
            maxLength: 250,
          }}
          onChange={(e) => {
            const textoCompensacaoHorasExtraordinarias = e?.target?.value || ''
            setData((prevState) => ({ ...prevState, textoCompensacaoHorasExtraordinarias }))
          }}
          multiline
          rows={4}
          validationErrors={validationErrors}
          name="textoCompensacaoHorasExtraordinarias"
          required
        />
      </Grid>

      <Grid item xs={12}>
        <StepButtons handleNext={handleValidate} />
      </Grid>
    </Grid>
  )
}
