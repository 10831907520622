import React, { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { Finder } from 'mio-library-ui'
import { Box } from '@material-ui/core'

import Table from './components/Table'
import DialogConfirm from './components/DialogConfirm'

import { PageHeader, PDFViewer, PrintButton } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

export default function AcordoHoraExtra() {
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)

  const [collection, setCollection] = useState([])
  const [rowsSelected, setRowsSelected] = useState([])

  const dialogNotification = useDialogNotification()
  const { estabelecimento } = useAmbiente()
  const {
    close: closeDialogConfirm,
    isOpen: isOpenDialogConfirm,
    open: openDialogConfirm,
  } = useDialog()

  const {
    close: closePDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
    data: dataPDFViewer,
  } = useDialog()

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/Vinculo/ObterVinculosPorEstabelecimento', {
          params: { estabelecimentoId: estabelecimento.id },
        })
        setCollection(response?.data?.data || [])
        setRowsSelected([])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    // eslint-disable-next-line
  }, [estabelecimento])

  function handlePrint() {
    if (!(rowsSelected.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um funcionário'],
      })
    openDialogConfirm()
  }

  function handleAfterSubmit(dt) {
    const arraysIds = rowsSelected.map((index) => collection[index].id)
    openPDFViewer({
      vinculosIds: arraysIds,
      dtAssinatura: dt.dtAssinatura,
      textoAcordo: dt.textoAcordo,
    })
    setRowsSelected([])
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Acordo de hora extra">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table
        data={collection}
        isLoading={isLoading}
        query={query}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelected}
      />

      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} />
      </Box>

      <DialogConfirm
        isOpen={isOpenDialogConfirm}
        onClose={closeDialogConfirm}
        onAfterSubmit={handleAfterSubmit}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="AcordoHoraExtra"
        title="Acordo de hora extra"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/AcordoHoraExtra/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
