import { useState } from 'react'

import { Box, Grid, Tooltip, useTheme } from '@material-ui/core'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { Button, Checkbox, ContentDivider } from '~/components'

import {
  indModeloContratoExperienciaConsts,
  indModeloContratoExperienciaValues,
} from '~/values/indModeloContratoExperienciaValues'
import {
  IndModeloValeTransporterUsoEnum,
  IndModeloValeTransporterUsoValues,
} from '~/@types/enums/IndModeloValeTransporterUsoEnum'
import {
  IndModeloCompensacaoHorasTrabalhadasEnum,
  IndModeloCompensacaoHorasTrabalhadasValues,
} from '~/@types/enums/IndModeloCompensacaoHorasTrabalhadasEnum'

import { ParametrosSistema } from '~/hooks/queries/ParametrosSistema/ParametrosSistema'
import {
  IndModeloTermoAutUsoImagemEnum,
  IndModeloTermoAutUsoImagemValues,
} from '~/@types/enums/IndModeloTermoAutUsoImagem'

import {
  IndModeloEtiquetaCTPSValues,
  IndModeloEtiquetaCTPSEnum,
} from '~/@types/enums/IndModeloEtiquetaCTPSEnum'

interface StepRelatoriosProps {
  data: ParametrosSistema
  onSubmit: (data: ParametrosSistema) => void
}

export default function StepRelatorios({ data: _data, onSubmit }: StepRelatoriosProps) {
  const [data, setData] = useState(_data)

  const theme = useTheme()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MUIAutoComplete
          label="Modelo de Contrato de Experiência"
          options={indModeloContratoExperienciaValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data.indModeloContratoExperiencia}
          onChange={(_, obj) => {
            setData((prev) => ({
              ...prev,
              indModeloContratoExperiencia:
                obj?.value || indModeloContratoExperienciaConsts.modelo_01,
            }))
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MUIAutoComplete
          label="Modelo de Vale Transporte Declaração de Uso"
          options={IndModeloValeTransporterUsoValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data.indModeloValeTransporte}
          onChange={(_, obj) => {
            setData((prev) => ({
              ...prev,
              indModeloValeTransporte: obj?.value || IndModeloValeTransporterUsoEnum.Modelo_01,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MUIAutoComplete
          label="Modelo de Compensação de Horas Trabalhadas"
          options={IndModeloCompensacaoHorasTrabalhadasValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data.indModeloCompensacaoHorasTrabalhadas}
          onChange={(_, obj) => {
            setData((prev) => ({
              ...prev,
              indModeloCompensacaoHorasTrabalhadas:
                obj?.value || IndModeloCompensacaoHorasTrabalhadasEnum.Modelo_01,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MUIAutoComplete
          label="Termo de autorização de uso de imagem e voz"
          options={IndModeloTermoAutUsoImagemValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data.indModeloTermoAutUsoImagem}
          onChange={(_, obj) => {
            setData((prev) => ({
              ...prev,
              indModeloTermoAutUsoImagem: obj?.value || IndModeloTermoAutUsoImagemEnum.Modelo_01,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MUIAutoComplete
          label="Modelo Etiqueta CTPS"
          options={IndModeloEtiquetaCTPSValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data.indModeloEtiquetaCTPS}
          onChange={(_, obj) => {
            setData((prev) => ({
              ...prev,
              indModeloEtiquetaCTPS: obj?.value || IndModeloEtiquetaCTPSEnum.Modelo_01,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Considerar o Início do Aviso Prévio como Data de Emissão para o 05 - Pedido de Demissão"
          value={data.isConsiderarInicioAvisoComoEmissao}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isConsiderarInicioAvisoComoEmissao: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Alertar no Recibo quando a Contribuição menor que o Salário Mínimo"
          value={data.isAlertaRPContribuicaoMenorQueSalarioMinimo}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isAlertaRPContribuicaoMenorQueSalarioMinimo: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Termo de Rescisão, não preencher a Data de Homologação"
          value={data.isInserirDtHomologacaoRescisaoPunho}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isInserirDtHomologacaoRescisaoPunho: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ContentDivider title="Férias Vencidas" />
      </Grid>

      <Grid item xs={12}>
        <Tooltip
          title={
            data.isCompararCmDtLimiteRelFeriasVencidas
              ? 'Atualmente a Data de Vencimento será comparada com a Data Limite das Férias'
              : 'Atualmente a Data de Vencimento será comparada com a Data de Período Fim das Férias'
          }
        >
          <Checkbox
            label="Relatório de Férias Vencidas, comparar a Data de Vencimento com a Data Limite das Férias"
            value={data.isCompararCmDtLimiteRelFeriasVencidas}
            onChange={(_, checked) => {
              setData((prev) => ({
                ...prev,
                isCompararCmDtLimiteRelFeriasVencidas: checked,
              }))
            }}
          />
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Avisar Férias Vencidas no Contracheque"
          value={data.isAvisarFeriasVencidasContraCheque}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isAvisarFeriasVencidasContraCheque: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ContentDivider title="Recibo do Autônomo" />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Imprimir estabelecimentos diferentes na mesma Folha"
          value={data.isJuntarEmpresasImpressaoRPA}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isJuntarEmpresasImpressaoRPA: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Imprimir RPA em duas vias"
          value={data.isDobrarMesmaFolhaRPA}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isDobrarMesmaFolhaRPA: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
          <Button onClick={() => setData(_data)} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={() => onSubmit(data)} variant="contained">
            Salvar
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
