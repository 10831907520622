import React, { useState, useEffect, useCallback } from 'react'

import moment from 'moment'

import { Box } from '@material-ui/core'
import { Button, PageHeader } from 'mio-library-ui'

import { ConfirmDeleteDialog, TreeView, PDFViewer } from '~/components'

import Table from './components/Table'
import Form from './components/Form'

import { useEstabelecimentoVinculosMenu } from '~/hooks/queries/useEstabelecimento'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import api from '~/services/api-pessoal'
import useDialog from '~/hooks/useDialog'

import { IndCumprimentoEnum } from '~/@types/enums/IndCumprimentoEnum'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

const HEADER_HEIGHT = '70px'

const VinculoAvisoPrevio = () => {
  const [vinculo, setVinculo] = useState({})

  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })

  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    isOpen: false,
    isDeleting: false,
    id: null,
  })

  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { data: dataMenu, isLoading: isLoadingMenu } = useEstabelecimentoVinculosMenu([
    VinculoTipoEnum.Funcionario_1,
  ])

  useEffect(() => {
    setVinculo({})
    setCollection({
      isLoading: false,
      itens: [],
    })
  }, [anoMes, estabelecimento])

  const handleClickItem = useCallback(
    (event, value) => {
      function handleClickPrint(id) {
        openPDFViewer(id)
      }

      const handleClickEditItem = (id) => {
        const { itens } = collection
        const item = itens.find((i) => i.id === id)
        handleOpenForm(item)
      }

      const handleClickDeleteItem = (id) => {
        setConfirmDeleteDialog((oldState) => ({
          ...oldState,
          isOpen: true,
          id,
        }))
      }

      const functions = {
        print: handleClickPrint,
        edit: handleClickEditItem,
        delete: handleClickDeleteItem,
      }
      functions[event](value)
    },
    //eslint-disable-next-line
    [collection],
  )

  const handleCloseConfirmDeleteItem = useCallback(() => {
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isOpen: false,
      isDeleting: false,
    }))
  }, [])

  const handleDeleteItem = useCallback(async () => {
    const itens = collection.itens
    setConfirmDeleteDialog((oldState) => ({
      ...oldState,
      isDeleting: true,
    }))
    try {
      await api.delete(`/vinculoAvisoPrevio/${confirmDeleteDialog.id}`)
      const newItens = itens.filter((i) => i.id !== confirmDeleteDialog.id)
      handleCloseConfirmDeleteItem()
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    //eslint-disable-next-line
  }, [collection.itens, confirmDeleteDialog.id])

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
    //eslint-disable-next-line
  }, [vinculo?.id])

  const handleOpenForm = (
    data = {
      vinculoId: vinculo.id,
      indCumprimento: IndCumprimentoEnum.Cumprimento_Total,
      isIndenizacaoArtigoIX: false,
      isAvisoMisto: false,
    },
  ) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = useCallback(
    (event, value) => {
      const handleAfterInsert = (data) => {
        const newItens = [data]
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const handleAfterUpdate = (data) => {
        const { itens } = collection
        const newItens = itens.map((i) => (i.id === data.id ? data : i))
        setCollection((oldState) => ({
          ...oldState,
          itens: newItens,
        }))
        handleCloseForm()
      }

      const functions = {
        insert: handleAfterInsert,
        update: handleAfterUpdate,
      }

      functions[event](value)
    },
    [collection],
  )

  const getCollectionByVinculo = async (vinculoId) => {
    if (!vinculoId) return
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))
    try {
      const response = await api.get(`/vinculoAvisoPrevio/GetByVinculo/${vinculoId}`)
      let newData = response?.data?.data ? [response.data.data] : []
      setCollection((oldState) => ({
        ...oldState,
        itens: newData,
      }))
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
  }

  const subtitlePage = `Nome: ${vinculo?.nome || '-'} | Admissão: ${
    vinculo?.dtAdmissao ? moment(vinculo.dtAdmissao).format('DD/MM/yyyy') : '-'
  }`

  return (
    <TreeView
      data={dataMenu}
      onSelectItem={(e, vinculo) => {
        getCollectionByVinculo(vinculo.vinculoId)
        setVinculo({
          id: vinculo?.vinculoId,
          nome: vinculo?.vinculoNome,
          dtAdmissao: vinculo?.dtAdmissao,
        })
      }}
      searchBy="vinculoNome"
      renderOption={(option) => `${option.vinculoNome}`}
      isLoading={isLoadingMenu}
      isLoadingOnClick={collection.isLoading}
    >
      <Box height={HEADER_HEIGHT}>
        <PageHeader title="Aviso Prévio" subtitle={subtitlePage}>
          {vinculo?.id && (
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          )}
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table
          data={collection.itens}
          isLoading={collection.isLoading}
          onItemClick={handleClickItem}
        />
      </Box>

      {form.isOpen && (
        <Form
          subtitlePage={subtitlePage}
          isOpen={form.isOpen}
          data={form.data}
          onClose={handleCloseForm}
          onAfterSubmitForm={handleAfterSubmitForm}
        />
      )}

      <ConfirmDeleteDialog
        isOpen={confirmDeleteDialog.isOpen}
        isDeleting={confirmDeleteDialog.isDeleting}
        onCancel={handleCloseConfirmDeleteItem}
        onConfirm={handleDeleteItem}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="AvisoPrevio"
        title="Relatório / Aviso Prévio"
        isSalvarDocumento
        axiosConfig={{
          url: `/vinculoAvisoPrevio/ObterRelatorio/${dataPDFViewer}`,
          method: 'get',
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />
    </TreeView>
  )
}

export default VinculoAvisoPrevio
