import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { useStepContext, StepButtons } from '~/components/StepContent'

import FormHorariosBase, { schemaFields } from '../FormHorariosBase'

import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape(schemaFields)

export default function JornadaSabadoFeriado(props) {
  const { onSubmit, data: _data } = props

  const [data, setData] = useState({})

  const { handleNext, handleBack, handleSkip } = useStepContext()

  useEffect(() => {
    setData({ ..._data, isUse: false })
  }, [_data])

  function handleSubmit() {
    onSubmit({ ...data, isUse: true })
    handleNext()
  }

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  return (
    <Grid container spacing={2}>
      <FormHorariosBase data={data} setData={setData} validationErrors={validationErrors} />
      <Grid item xs={12}>
        <StepButtons handleNext={handleValidate} handleBack={handleBack} handleSkip={handleSkip} />
      </Grid>
    </Grid>
  )
}
