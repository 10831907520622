import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoConsulta } from './VinculoConsulta'

export function useVinculoConsultaGetVinculosRescisaoTSV(
  estabelecimentoId: string,
  anoMes: string,
) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoConsulta[] }>(
      '/VinculoConsulta/GetVinculosRescisaoTSV',
      {
        params: {
          estabelecimentoId,
          anoMes,
        },
      },
    )
    return response?.data?.data || []
  }

  const query = useQuery(
    ['VinculoConsultaGetVinculosRescisaoTSV', estabelecimentoId, anoMes],
    handleRequest,
    {
      onError: (err) => dialogNotification.extractErrors(err),
    },
  )

  return { ...query, data: query.data || [] }
}
