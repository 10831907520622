import { useState, memo, useEffect, useMemo } from 'react'

import { Grid, Typography, Box, Paper, useTheme } from '@material-ui/core'
import { FaFileExcel } from 'react-icons/fa'

import {
  ButtonBox,
  StackContainer,
  ContainerTable,
  PageHeader,
  Button,
  Finder,
  Stack,
  DatePickerNew,
} from '~/components'
import ButtonIcon from '~/components/ButtonIcon'

import { AutoCompleteCNAB, MUIAutoComplete } from '~/components/AutoComplete'

import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import useGetByRecibos from '~/hooks/queries/CNAB/useGetByRecibos'
import useGerarArquivo from '~/hooks/queries/CNAB/useGerarArquivo'
import useGerarPlanilha from '~/hooks/queries/CNAB/useGerarPlanilha'

import * as yup from 'yup'
import moment from 'moment'
import { formatCurrency } from '~/utils/utils'

import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'
import TableRemessas from './components/TableRemessas'

const schemaSearch = yup.object().shape({
  cnabId: yup.string().required('Informe o Banco').nullable(),
  reciboIDF: yup.string().required('Informe a Identificação do Recibo'),
})

const schemaProcess = yup.object().shape({
  dtPagamento: yup
    .date()
    .required('Informe a Data de Pamento')
    .min(
      moment().subtract(1, 'd').format('yyyy-MM-DD'),
      'Informar Data de Pagamento maior que a Data Atual',
    )
    .typeError('Data Inválida')
    .nullable(),
})

const MemoTable = memo(Table)

const CNAB = () => {
  const [query, setQuery] = useState('')
  const [data, setData] = useState({
    cnabId: '',
    reciboIDF: ReciboIDFEnum.Mensal_1,
    dtPagamento: '',
  })
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const { estabelecimento, anoMes } = useAmbiente()
  const theme = useTheme()
  const {
    close: closeTableRemessas,
    isOpen: isOpenTableRemessas,
    open: openTableRemessas,
  } = useDialog(null)

  const {
    data: _data,
    isLoading,
    isFetching,
    refetch,
  } = useGetByRecibos({
    params: {
      cnabId: data.cnabId,
      estabelecimentoId: estabelecimento.id,
      anoMes,
      reciboIDF: data.reciboIDF,
    },
  })
  const collection = useMemo(() => _data || [], [_data])

  const nomeArquivo = `CNAB/${estabelecimento.nrInscricao}${
    data?.dtPagamento ? '/' + data?.dtPagamento : ''
  }`

  const { mutateAsync: mutateAsyncProcess, isLoading: isLoadingProcess } = useGerarArquivo(
    nomeArquivo + '.txt',
  )
  const { mutateAsync: mutateAsyncPlanilha, isLoading: isLoadingPlanilha } = useGerarPlanilha(
    nomeArquivo + '.xlsx',
  )

  useEffect(() => {
    setRowsSelected(collection.map((_, index) => index))
  }, [collection])

  const { validationErrors: validationErrorsSearch, handleValidate: handleValidateSearch } =
    useValidationErrors({
      schema: schemaSearch,
      handleSubmit: async () => await refetch(),
      data,
    })

  const { validationErrors: validationErrorsProcess, handleValidate: handleValidateProcess } =
    useValidationErrors({
      schema: schemaProcess,
      handleSubmit: handleProcess,
      data,
    })

  async function handleProcess() {
    const recibosIds = rowsSelected.map((index) => collection[index].reciboId)
    await mutateAsyncProcess({
      data: recibosIds,
      params: {
        cnabId: data.cnabId,
        dtPagamento: data.dtPagamento,
        estabelecimentoId: estabelecimento.id,
      },
    })
  }

  async function handleDownloadExcel() {
    const recibosIds = rowsSelected.map((index) => collection[index].reciboId)
    await mutateAsyncPlanilha({
      data: recibosIds,
      params: {
        cnabId: data.cnabId,
        estabelecimentoId: estabelecimento.id,
        anoMes,
        reciboIDF: data.reciboIDF,
      },
    })
  }

  const calcTotal = useMemo(() => {
    return rowsSelected.reduce((prev, indexSelected) => {
      const dataCurrent = collection[indexSelected]
      if (dataCurrent) {
        return (prev += dataCurrent.valorLiquido)
      }
      {
        return prev
      }
    }, 0)
  }, [collection, rowsSelected])

  return (
    <StackContainer>
      <PageHeader title="CNAB">
        <ButtonBox>
          <Button variant="contained" onClick={() => openTableRemessas(null)}>
            Abrir Remessas
          </Button>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </ButtonBox>
      </PageHeader>

      <Stack orientation="horizontal" alignItems="center" component={Paper} p={1}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={4}>
            <AutoCompleteCNAB
              name="cnabId"
              required
              validationErrors={validationErrorsSearch}
              value={data?.cnabId}
              onChange={(cnab) => {
                const cnabId = cnab?.id || ''
                setData((oldState) => ({ ...oldState, cnabId }))
              }}
            />
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <MUIAutoComplete
              label="Identificação do Recibo"
              name="reciboIDF"
              required
              validationErrors={validationErrorsSearch}
              options={ReciboIDFValues}
              optionId="value"
              renderOption={(option) => option.name}
              value={data.reciboIDF}
              onChange={(e, obj) => {
                const reciboIDF = obj ? obj.value : ''
                setData((oldState) => ({ ...oldState, reciboIDF }))
              }}
            />
          </Grid>
        </Grid>

        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleValidateSearch}
          isLoading={isLoading}
        >
          Buscar
        </Button>
      </Stack>

      <ContainerTable>
        <MemoTable
          data={collection}
          isLoading={isLoading}
          isFetching={isFetching}
          onRowSelected={setRowsSelected}
          rowsSelecteds={rowsSelected}
          query={query}
        />
      </ContainerTable>

      <Stack orientation="horizontal" alignItems="center" component={Paper} p={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Box display="flex" flexDirection="column">
              <Box>
                Foram selecionados{' '}
                <strong>
                  {rowsSelected.length}/{collection.length}
                </strong>{' '}
                Funcionários para Gerar CNAB
              </Box>
              <Typography style={{ color: '#808080' }}>
                Total valor Líquido: {formatCurrency(calcTotal)}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box height="100%" display="flex" alignItems="center">
              <DatePickerNew
                label="Data de Pagamento"
                size="small"
                name="dtPagamento"
                required
                validationErrors={validationErrorsProcess}
                value={data?.dtPagamento || null}
                onChange={(date) => {
                  const dtPagamento = date || ''
                  setData((oldState) => ({ ...oldState, dtPagamento }))
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          width={300}
          height="100%"
          display="flex"
          gridGap={theme.spacing(1)}
          alignItems="center"
          justifyContent="center"
        >
          <ButtonIcon
            icon={<FaFileExcel />}
            variant="outlined"
            fullWidth
            onClick={handleDownloadExcel}
            isLoading={isLoadingPlanilha}
            disabled={rowsSelected.length > 0 ? false : true}
          >
            Planilha
          </ButtonIcon>

          <Button
            size="small"
            color="primary"
            fullWidth
            variant="contained"
            onClick={handleValidateProcess}
            isLoading={isLoadingProcess}
            disabled={rowsSelected.length > 0 ? false : true}
          >
            Gerar Arquivo
          </Button>
        </Box>
      </Stack>

      {isOpenTableRemessas && (
        <TableRemessas isOpen={isOpenTableRemessas} onClose={closeTableRemessas} />
      )}
    </StackContainer>
  )
}

export default CNAB
