import { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'
import { Creators as AppActions } from '~/store/ducks/app'

import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Paper,
  useTheme,
} from '@material-ui/core'

import { PageHeader } from '~/components'
import StepperForm from '~/components/StepperForm'

import StepConfiguracoes from './Steps/Configuracoes'
import StepRelatorios from './Steps/Relatorios'
import StepDCTFWeb from './Steps/DCTFWeb'

import { useDispatch, useSelector } from 'react-redux'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { ParametrosSistema } from '~/hooks/queries/ParametrosSistema/ParametrosSistema'

export default function ParametrosSistemaPage() {
  const [data, setData] = useState<ParametrosSistema | null>(null)

  const [isSubmiting, setSubmiting] = useState(false)

  const theme = useTheme()
  const dispatch = useDispatch()
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const parametrosSistema = useSelector(
    (state: {
      app: {
        parametrosSistema: ParametrosSistema
      }
    }) => state.app.parametrosSistema,
  )

  useEffect(() => {
    setData(parametrosSistema)
  }, [parametrosSistema])

  async function handleSave(d: ParametrosSistema) {
    setSubmiting(true)
    try {
      const response = await api.put('/ParametrosSistema', d)
      dispatch(AppActions.setParametrosSistema(response?.data?.data))
      notification.put()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setSubmiting(false)
    }
  }

  if (!data) return <CircularProgress />

  return (
    <StepperForm.Provider
      steps={[
        {
          label: 'Configurações',
          icon: 'settings',
          children: <StepConfiguracoes data={data} onSubmit={handleSave} />,
        },
        {
          label: 'Relatório',
          icon: 'library_books',
          children: <StepRelatorios data={data} onSubmit={handleSave} />,
        },
        {
          label: 'DCTFWeb',
          icon: 'account_balance',
          children: <StepDCTFWeb data={data} onSubmit={handleSave} />,
        },
      ]}
    >
      <Box padding={2}>
        <PageHeader title="Parâmetros do Sistema" />
        <Container maxWidth="sm">
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
            <StepperForm.Steps orientation="horizontal" />
            <Box height={4}>{isSubmiting && <LinearProgress />}</Box>
            <Box
              component={Paper}
              padding={3}
              display="flex"
              gridGap={theme.spacing(1)}
              flexDirection="column"
            >
              <StepperForm.Content />
            </Box>
          </Box>
        </Container>
      </Box>
    </StepperForm.Provider>
  )
}
