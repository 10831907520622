import { useEffect, useState } from 'react'

import { Box, Paper } from '@material-ui/core'

import {
  PageHeader,
  PDFViewer,
  Button,
  Finder,
  DialogConfirmDtAssinatura,
  Stack,
  ContainerTable,
  MultipleSelectCheck,
} from '~/components'
import { DataFormProps } from '~/components/DialogConfirmDtAssinatura'

import { Table } from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { TipoAssinaturaEnum } from '~/@types/enums/TipoAssinaturaEnum'
import {
  CartaOposicaoSindicatoAssuntoEnum,
  CartaOposicaoSindicatoAssuntoValues,
} from '~/@types/enums/CartaOposicaoSindicatoAssuntoEnum'

export interface CartaOposicaoSindicatoRequestDTO {
  vinculoIds: string[]
  estabelecimentoId: string
  tipoAssinatura: TipoAssinaturaEnum
  dtAssinatura: Date | string | null
  assuntos: CartaOposicaoSindicatoAssuntoEnum[]
}

const TITLE_PAGE = 'Carta de Oposição ao Desconto das Contribuições ao Sindicato'

export default function CartaOposicaoSindicato() {
  const [dataForm, setDataForm] = useState({
    assuntos: CartaOposicaoSindicatoAssuntoValues,
  })
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const [query, setQuery] = useState('')

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<CartaOposicaoSindicatoRequestDTO>({
    vinculoIds: [],
    dtAssinatura: null,
    tipoAssinatura: TipoAssinaturaEnum.Admissao,
    estabelecimentoId: '',
    assuntos: [],
  })

  const {
    close: closeConfirmDtAssinatura,
    isOpen: isOpenConfirmDtAssinatura,
    open: openConfirmDtAssinatura,
  } = useDialog(null)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    estabelecimentoId: estabelecimento.id,
    anoMes,
  })
  const data = _d || []

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  function handlePrint(d: DataFormProps) {
    const ids = rowsSelected.map((index) => data[index].id)
    const assuntos = dataForm.assuntos.map((d) => d.value)
    openPDFViewer({
      vinculoIds: ids,
      dtAssinatura: d.dtAssinatura,
      tipoAssinatura: d.tipoAssinatura,
      estabelecimentoId: estabelecimento.id,
      assuntos,
    })
  }

  function handleClosePDFViewer() {
    setRowsSelected([])
    closePDFViewer()
  }

  function handleConfirmDtAssinatura() {
    openConfirmDtAssinatura(null)
  }

  return (
    <Stack height="100%" width="100%" p={2}>
      <PageHeader title={TITLE_PAGE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Stack component={Paper} p={1}>
        <MultipleSelectCheck
          label="Assuntos"
          value={dataForm.assuntos}
          options={CartaOposicaoSindicatoAssuntoValues}
          getOptionLabel={(d) => d.name}
          fieldCompare="value"
          onChange={(value) => setDataForm((prev) => ({ ...prev, assuntos: value }))}
        />
      </Stack>

      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          onRowSelected={(indexs) => setRowsSelected(indexs)}
          rowsSelected={rowsSelected}
          query={query}
        />
      </ContainerTable>

      <Box component={Paper} p={1} width="100%" display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleConfirmDtAssinatura}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Imprimir
        </Button>
      </Box>

      {isOpenPDFViewer && (
        <PDFViewer
          title={TITLE_PAGE}
          reportKey="CartaOposicaoSindicato"
          isOpen={isOpenPDFViewer}
          onClose={handleClosePDFViewer}
          isSalvarDocumento
          axiosConfig={{
            url: 'CartaOposicaoSindicato/ObterRelatorio',
            method: 'post',
            data: dataPDFViewer,
          }}
        />
      )}

      {isOpenConfirmDtAssinatura && (
        <DialogConfirmDtAssinatura
          isOpen={isOpenConfirmDtAssinatura}
          onAfterSubmit={handlePrint}
          onClose={closeConfirmDtAssinatura}
        />
      )}
    </Stack>
  )
}
