import { useState, useEffect, useCallback } from 'react'

import { Box, Paper, useTheme } from '@material-ui/core'

import { PageHeader, Finder, ButtonBox, Button } from '~/components'

import Table from './components/Table'
import apiPessoal from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useProcessarPlanoSaude from '~/hooks/queries/VinculoPlanoSaude/useProcessarPlanoSaude'

const PlanoSaudeConsulta = () => {
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')

  const pageTitle = 'Plano de Saúde - Consulta'
  const apiRoute = '/vinculoplanoSaude'

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  const { mutateAsync, isLoading: isProcessing } = useProcessarPlanoSaude()

  const updateItems = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await apiPessoal.get(
        `${apiRoute}/getConsulta?estabelecimentoId=${estabelecimento.id}&anoMes=${anoMes}`,
      )
      if (response.data.data) {
        setItems(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [estabelecimento.id, anoMes])

  useEffect(() => {
    updateItems()
  }, [updateItems])

  async function handleProcess() {
    await mutateAsync({
      data: items,
      params: {
        anoMes,
      },
    })
  }

  return (
    <Box
      height="100%"
      width="100%"
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <PageHeader title={pageTitle}>
        <ButtonBox>
          <Finder
            onSearch={(value) => {
              setQuery(value)
            }}
            searching={isLoading}
            onClose={() => {
              setQuery('')
            }}
          />
        </ButtonBox>
      </PageHeader>

      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%" minHeight={300}>
          <Table data={items} isLoading={isLoading} query={query} />
        </Box>
      </Box>

      <Box component={Paper} padding={1} display="flex" justifyContent="flex-end">
        <Button
          disabled={items.length > 0 ? false : true}
          variant="contained"
          onClick={() => handleProcess()}
          isLoading={isProcessing}
        >
          Processar para o Recibo
        </Button>
      </Box>
    </Box>
  )
}

export default PlanoSaudeConsulta
