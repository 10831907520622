import React from 'react'

import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

import moment from 'moment'

import { HeightDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

const Table = (props) => {
  const { data: _data, onItemClick, isLoading } = props

  const data = _data.map((value) => {
    return {
      ...value,
      dtInicio: value.dtInicio ? moment(value.dtInicio).format('DD/MM/YYYY') : null,
      dtFim: value.dtFim ? moment(value.dtFim).format('DD/MM/YYYY') : null,
      dtAceiteConvocacao: value.dtAceiteConvocacao
        ? moment(value.dtAceiteConvocacao).format('DD/MM/YYYY')
        : null,
    }
  })

  const columns = [
    {
      name: 'codigoConvocacao',
      label: 'Código da Convocação',
    },
    {
      name: 'dtInicio',
      label: 'Data de Início',
    },
    {
      name: 'dtFim',
      label: 'Data Final',
    },
    {
      name: 'dtAceiteConvocacao',
      label: 'Data da Confirmação',
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'rpId',
      label: 'Recibo',
      options: {
        customBodyRender: (value) => <Ativo ativo={value ? true : false} />,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" size="small" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" size="small" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return <HeightDataTable data={data} columns={columns} isLoading={isLoading} />
}

export default Table
