import React, { useEffect, useState } from 'react'

import { Finder, Button } from 'mio-library-ui'
import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { PageHeader, PDFViewer, PrintButton } from '~/components'

import TableHorariosTrabalho from './components/TableHorariosTrabalho'
import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

import {
  contratoExperienciaValues,
  contratoExperienciaConsts,
} from '~/values/contratoExperienciaValues'

const initialData = {
  vinculos: [],
}

const titlePage = 'Contrato de Experiência/Prorrogação'

export default function ContratoExperienciaProrrogacao() {
  const [tipoContrato, setTipoContrato] = useState(contratoExperienciaConsts.contratoExperiencia)
  const [query, setQuery] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(initialData)
  const [rowsSelected, setRowsSelected] = useState([])

  const dialogNotification = useDialogNotification()
  const { anoMes, estabelecimento } = useAmbiente()
  const {
    isOpen: isOpenTableHorariosTrabalho,
    close: closeTableHorariosTrabalho,
    data: dataTableHorariosTrabalho,
    open: openTableHorariosTrabalho,
  } = useDialog([])
  const theme = useTheme()

  const {
    isOpen: isOpenPDFViewer,
    close: closePDFViewer,
    open: openPDFViewer,
    data: dataPDFViewer,
  } = useDialog()

  useEffect(() => {
    function resetFields() {
      setData(initialData)
      setRowsSelected([])
    }
    resetFields()
  }, [tipoContrato, anoMes, estabelecimento])

  async function handleProcess() {
    setLoading(true)
    try {
      const response = await api.get('/Relatorio/ContratoExperiencia/ObterContratoExperiencia', {
        params: {
          estabelecimentoId: estabelecimento.id,
          anoMes,
          tipoContrato,
        },
      })
      setData(response?.data?.data || initialData)
      setRowsSelected([])
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
  }

  function handleClickItem(rowItem) {
    openTableHorariosTrabalho(rowItem.horarios)
  }

  function handlePrint() {
    if (!(rowsSelected.length > 0))
      return dialogNotification.warning({
        descriptions: ['Informe pelo menos um Funcionário.'],
      })

    const newVinculosSelecteds = rowsSelected.map((indexCurrent) => data.vinculos[indexCurrent])

    const newData = {
      ...data,
      vinculos: newVinculosSelecteds,
    }

    openPDFViewer(newData)
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={titlePage}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Box component={Paper} padding={2}>
        <Grid
          container
          spacing={2}
          style={{
            paddingBottom: theme.spacing(1),
          }}
        >
          <Grid item xs={12} sm={10} md={11}>
            <MUIAutoComplete
              optionId="value"
              options={contratoExperienciaValues}
              renderOption={(opt) => opt.name}
              value={tipoContrato}
              onChange={(_, obj) =>
                setTipoContrato(obj?.value || contratoExperienciaConsts.contratoExperiencia)
              }
              label="Tipo de Contrato"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleProcess}
              isLoading={isLoading}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Table
        isLoading={isLoading}
        data={data.vinculos}
        query={query}
        onClickItem={handleClickItem}
        rowsSelected={rowsSelected}
        onRowSelected={setRowsSelected}
        tipoContrato={tipoContrato}
      />
      <Box display="flex" justifyContent="flex-end" pt={1}>
        <PrintButton onClick={handlePrint} />
      </Box>

      <TableHorariosTrabalho
        isOpen={isOpenTableHorariosTrabalho}
        onClose={closeTableHorariosTrabalho}
        data={dataTableHorariosTrabalho}
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        reportKey="ContratoExperiencia"
        title={titlePage}
        onClose={closePDFViewer}
        isSalvarDocumento
        axiosConfig={{
          url: '/Relatorio/ContratoExperiencia/ObterRelatorio',
          method: 'post',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />
    </Box>
  )
}
