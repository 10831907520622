import React, { useState } from 'react'

import * as yup from 'yup'

import { ButtonBox } from 'mio-library-ui'
import { Box, Grid, MenuItem } from '@material-ui/core'

import {
  ContentDivider,
  DatePickerNew,
  NumeroInscricaoTextField,
  TextField,
  Button,
  PageHeader,
  CurrencyTextField,
  Checkbox,
} from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'

import { VinculoPartial } from '~/hooks/queries/Vinculo/Vinculo'
import { CategoriaValues } from '~/@types/enums/CategoriaEnum'
import { IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'
import { IndUnidadeSalarioValues } from '~/@types/enums/IndUnidadeSalarioEnum'
import { useStepperContext } from '~/components/StepperForm'
import { IndSimplesValues } from '~/@types/enums/IndSimplesEnum'
import { MainDataForm } from '..'
import { NaturezaAtividadeValues } from '~/@types/enums/NaturezaAtividadeEnum'
import { IndPensaoAlimenticiaValues } from '~/@types/enums/IndPensaoAlimenticiaEnum'
import { IndMultiploVinculoValues } from '~/@types/enums/IndMultiploVinculoEnum'

const schema = yup.object().shape({
  indSimples: yup.string().required('Informe o Indicador Simples Nacional'),
  indGrauExposicao: yup.string().required('Informe o Indicador Grau Exposição'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
  indPensaoAlimenticia: yup.string().required('Informe o Indicador Pensão Alimenícia'),
  naturezaAtividade: yup.string().required('Informe a Natureza Atividade'),
})

export default function FormAdmissao() {
  const { mainData, onCancel, onNext } = useStepperContext<MainDataForm>()

  const [data, setData] = useState<VinculoPartial>(mainData.dataInitialForm)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function handleSubmit() {
    onNext({
      ...mainData,
      dataInitialForm: data,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <PageHeader title="Admissão" />
      </Grid>

      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
        <TextField
          label="Pessoa Física"
          fullWidth
          value={data?.pessoaFisica?.nome || ''}
          size="small"
          disabled
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
        <NumeroInscricaoTextField
          label="CPF"
          value={data?.pessoaFisica?.nrInscricao || ''}
          disabled
        />
      </Grid>

      <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
        <DatePickerNew
          label="Data Admissão"
          size="small"
          value={data?.dtAdmissao || null}
          disabled
        />
      </Grid>
      <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
        <DatePickerNew label="Opção FGTS" size="small" value={data?.dtOpcaoFGTS || null} disabled />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <TextField
          label="Matrícula"
          fullWidth
          value={data?.matricula || ''}
          size="small"
          disabled
        />
      </Grid>

      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
        <MUIAutoComplete
          label="Categoria"
          disabled
          value={data.categoria}
          renderOption={(option) => option.name}
          options={CategoriaValues}
          optionId="value"
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <MUIAutoComplete
          label="Indicador Grau Exposição"
          options={IndGrauExposicaoValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="indGrauExposicao"
          value={data.indGrauExposicao}
          onChange={(e, obj) => {
            const indGrauExposicao = obj ? obj.value : ''
            setData({ ...data, indGrauExposicao })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <MUIAutoComplete
          label="Natureza Atividade"
          options={NaturezaAtividadeValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="naturezaAtividade"
          value={data.naturezaAtividade}
          onChange={(e, obj) => {
            const naturezaAtividade = obj ? obj.value : ''
            setData({ ...data, naturezaAtividade })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <Checkbox
          label="O Empregador possuí retirada Pró-Labore?"
          value={data?.isReciboEmpregador || false}
          onChange={(e, value) => {
            const isReciboEmpregador = value
            setData((oldState) => ({ ...oldState, isReciboEmpregador }))
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <DatePickerNew
          label="Data Rescisão"
          size="small"
          disabled
          value={data.dtRescisao || null}
          onChange={(date) => {
            const dtRescisao = date
            setData({ ...data, dtRescisao })
          }}
        />
      </Grid>

      <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
        <MUIAutoComplete
          label="Unidade Salário Fixo"
          options={IndUnidadeSalarioValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="unidadeSalarioFixo"
          value={data.unidadeSalarioFixo}
          onChange={(e, obj) => {
            const unidadeSalarioFixo = obj ? obj.value : ''
            setData({ ...data, unidadeSalarioFixo })
          }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={4} sm={9} xs={12}>
        <TextField
          label="Descrição Salário Variável"
          fullWidth
          value={data.descricaoSalarioVariavel || ''}
          size="small"
          inputProps={{
            maxLength: 100,
          }}
          multiline
          onChange={(e) => {
            const descricaoSalarioVariavel = e.target.value
            setData({ ...data, descricaoSalarioVariavel })
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <Checkbox
          label="Sócio Responsável pela Empresa"
          value={data?.isSocioResponsavel || false}
          onChange={(e, value) => {
            const isSocioResponsavel = value
            setData((oldState) => ({
              ...oldState,
              isSocioResponsavel,
            }))
          }}
        />
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
        <TextField
          label="Alíquota Participação no Capital Social"
          fullWidth
          value={data?.aliqParticipacaoCapitalSocial || ''}
          size="small"
          onlyNumber
          inputProps={{
            maxLength: 3,
          }}
          onChange={(e) => {
            const aliqParticipacaoCapitalSocial = parseInt(e?.target?.value || '0')
            setData((oldState) => ({
              ...oldState,
              aliqParticipacaoCapitalSocial,
            }))
          }}
        />
      </Grid>

      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <ContentDivider title="Outras informações" />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <MUIAutoComplete
          label="Indicador Pensão Alimenícia"
          options={IndPensaoAlimenticiaValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="indPensaoAlimenticia"
          value={data.indPensaoAlimenticia}
          onChange={(e, obj) => {
            const indPensaoAlimenticia = obj ? obj.value : ''
            setData({ ...data, indPensaoAlimenticia })
          }}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <CurrencyTextField
          label="Vr. Pensão Alimentícia"
          fullWidth
          value={data.vrPensaoAlimenticia || ''}
          variant="outlined"
          size="small"
          onChange={(e, obj) => {
            const vrPensaoAlimenticia = obj || null
            setData({ ...data, vrPensaoAlimenticia })
          }}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <MUIAutoComplete
          label="Indicador Múltiplos Vínculos"
          options={IndMultiploVinculoValues}
          renderOption={(option) => option.name}
          optionId="value"
          required
          validationErrors={validationErrors}
          name="indMultiplosVinculos"
          value={data.indMultiplosVinculos}
          onChange={(e, obj) => {
            const indMultiplosVinculos = obj ? obj.value : ''
            setData({ ...data, indMultiplosVinculos })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <MUIAutoComplete
          label="Indicador Simples Nacional"
          options={IndSimplesValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="indSimples"
          value={data.indSimples}
          onChange={(e, obj) => {
            const indSimples = obj ? obj.value : ''
            setData({ ...data, indSimples })
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <DatePickerNew
          label="Data Início da Inatividade"
          size="small"
          value={data?.dtInativo || null}
          onChange={(date) => {
            const dtInativo = date
            setData((oldState) => ({
              ...oldState,
              dtInativo,
            }))
          }}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <TextField
          label="Categoria FGTS"
          fullWidth
          value={data?.categoriaFGTS || ''}
          size="small"
          select
          onChange={(e) => {
            const categoriaFGTS = e?.target?.value || ''
            setData((oldState) => ({ ...oldState, categoriaFGTS }))
          }}
        >
          <MenuItem value="01">01 - Empregado</MenuItem>
          <MenuItem value="02">02 - Trabalhador Avulso</MenuItem>
          <MenuItem value="03">
            03 - Trabalhador não vinculado ao RGPS, mas com direito ao FGTS
          </MenuItem>
          <MenuItem value="04">
            04 - Empregado sob contrato de trabalho por prazo determinado (Lei 9.601,98)
          </MenuItem>
          <MenuItem value="05">
            05 - Contribuinte individual - Diretor não empregado com FGTS (Lei 8.036/90, art 16)
          </MenuItem>
          <MenuItem value="06">06 - Empregado Doméstico</MenuItem>
          <MenuItem value="07">07 - Menor Aprendiz (Lei 10.097/2000)</MenuItem>
          <MenuItem value="11">
            11 - Contribuinte Individual - Diretor não empregado e demais empresários sem FGTS
          </MenuItem>
          <MenuItem value="12">12 - Demais Agentes Públicos</MenuItem>
          <MenuItem value="13">
            13 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper. máquina com
            contribuição sobre remuneração
          </MenuItem>
          <MenuItem value="14">
            14 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper. máquina com
            contribuição sobre salário-base
          </MenuItem>
          <MenuItem value="15">
            15 - Contribuinte Individual - Transportador autônomo com contribuiçào sobre remuneração
          </MenuItem>
          <MenuItem value="16">
            16 - Contribuinte Individual - Transportador autônomo com contribuição sobre
            salário-base
          </MenuItem>
          <MenuItem value="17">
            17 - Contribuinte Individual - Cooperado que presta serviços a empresas contratentes de
            Cooperativa de Trabalho
          </MenuItem>
          <MenuItem value="18">
            18 - Contribuinte Individual - Transportador Cooperado que presta serviços a empresas
            contratantes de Cooperativa de Trabalho
          </MenuItem>
          <MenuItem value="19">19 - Agente Político</MenuItem>
          <MenuItem value="20">
            20 - Servidor Público Ocupante, exclusivamente, de cargo em comissão, Servidor Público
            Ocupante de cargo
          </MenuItem>
          <MenuItem value="21">
            21 - Servidor Público titular de cargo efetivo, magistrado, membro do Ministério Público
            e do Tribunal
          </MenuItem>
          <MenuItem value="22">
            22 - Contrib individ contratado por contrib individ, por prod rural PF, por missao
            diplomática/consulado
          </MenuItem>
          <MenuItem value="23">
            23 - Contribuinte individual transportador autônomo contratado por contrib individual,
            por produtor rural
          </MenuItem>
          <MenuItem value="24">
            24 - Contribuinte individual cooperado que presta serviços à entidade beneficente isenta
            da cota patronal
          </MenuItem>
          <MenuItem value="25">
            25 - Contrib individ transportador cooperado que presta serviços à entidade beneficente
            isenta cota patronal
          </MenuItem>
        </TextField>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Checkbox
          label="Utilizar cálculo simplificado do IR"
          value={data?.isCalcularIRSimplificado || false}
          onChange={(e, value) => {
            const isCalcularIRSimplificado = value
            setData({ ...data, isCalcularIRSimplificado })
          }}
        />
      </Grid>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        padding={1.5}
        zIndex={1}
        style={{ backgroundColor: 'white' }}
      >
        <ButtonBox>
          <Button onClick={onCancel}>Cancelar</Button>
          <Button onClick={handleValidate} variant="contained">
            Continuar
          </Button>
        </ButtonBox>
      </Box>
    </Grid>
  )
}
