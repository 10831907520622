import { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, useTheme } from '@material-ui/core'

import { Button, NumeroInscricaoTextField, TextField } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { ParametrosSistema } from '~/hooks/queries/ParametrosSistema/ParametrosSistema'
import { isCPFOrCNPJ } from 'brazilian-values'

const schema = yup.object().shape({
  icContratanteNrInscricao: yup.string().test({
    message: 'Informe um Número de Inscrição válido',
    test: (d: string) => (d ? isCPFOrCNPJ(d) : true),
  } as FixLater),
})

interface StepDCTFWebrops {
  data: ParametrosSistema
  onSubmit: (data: ParametrosSistema) => void
}

export default function StepDCTFWeb({ data: _data, onSubmit }: StepDCTFWebrops) {
  const [data, setData] = useState(_data)

  const theme = useTheme()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit: () => onSubmit(data),
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Nome do Contratante"
          value={data.icContratanteNome}
          validationErrors={validationErrors}
          name="icContratanteNome"
          onChange={(e) => {
            const icContratanteNome = e.target.value
            setData((prev) => ({ ...prev, icContratanteNome }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <NumeroInscricaoTextField
          label="Número de Inscrição do Contratante"
          value={data.icContratanteNrInscricao}
          validationErrors={validationErrors}
          name="icContratanteNrInscricao"
          onChange={(e, value) => {
            setData((prev) => ({ ...prev, icContratanteNrInscricao: value }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Consumer Key"
          value={data.icConsumerKey}
          validationErrors={validationErrors}
          name="icConsumerKey"
          onChange={(e) => {
            const icConsumerKey = e.target.value
            setData((prev) => ({ ...prev, icConsumerKey }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Consumer Secret"
          value={data.icConsumerSecret}
          validationErrors={validationErrors}
          name="icConsumerSecret"
          onChange={(e) => {
            const icConsumerSecret = e.target.value
            setData((prev) => ({ ...prev, icConsumerSecret }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
          <Button onClick={() => setData(_data)} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleValidate} variant="contained">
            Salvar
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
