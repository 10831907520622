import { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'

import { ViewHeadline } from '@material-ui/icons'
import { Grid, InputAdornment, IconButton, LinearProgress, Box } from '@material-ui/core'

import { ActionDialog, TextField, DatePickerNew, ContentDivider, TimePicker } from '~/components'
import { AutoCompleteCidade, MUIAutoComplete } from '~/components/AutoComplete'

import FormHorariosVinculo from './components/FormHorariosVinculo'
import DialogValidadeDtBase from './components/DialogValidadeDtBase'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import { calcDiasAvisoPrevio } from '~/hooks/useUtils'

import {
  IndMotivoDesligamentoEnum,
  IndMotivoDesligamentoValues,
} from '~/@types/enums/IndMotivoDesligamentoEnum'
import { IndAvisoPrevioEnum, IndAvisoPrevioValues } from '~/@types/enums/IndAvisoPrevioEnum'
import { RescisaoFGTSValues, RescisaoFGTSEnum } from '~/@types/enums/RescisaoFGTSEnum'
import { HomologNetValues, HomologNetEnum } from '~/@types/enums/HomologNetEnum'
import { IndHomologacaoEnum } from '~/@types/enums/IndHomologacaoEnum'
import { IndCumprimentoEnum, IndCumprimentoValues } from '~/@types/enums/IndCumprimentoEnum'

import {
  VinculoAvisoPrevio,
  VinculoAvisoPrevioForm,
} from '~/hooks/queries/VinculoAvisoPrevio/VinculoAvisoPrevio'
import { Vinculo } from '~/hooks/queries/Vinculo/Vinculo'

const itensToRemoveMotivoDesligamento = [
  IndMotivoDesligamentoEnum.NaoAplicavel_99,
  IndMotivoDesligamentoEnum.TransferênciaEmpregadoMesmoGrupo_11,
  IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorciada_12,
  IndMotivoDesligamentoEnum.TransferênciaEmpregadoConsorcio_13,
  IndMotivoDesligamentoEnum.MudancaCPF_36,
]
const motivoDesligamentoValues = IndMotivoDesligamentoValues.filter(
  (obj) => !itensToRemoveMotivoDesligamento.includes(obj.value),
)

const changeTipoRescisaoByTipoAviso = (tipoAvisoPrevio: IndAvisoPrevioEnum) => {
  const motivoDesligamentoMaps = {
    [IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoHoras_1]:
      IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
    [IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoDias_2]:
      IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
    [IndAvisoPrevioEnum.PedidoDemissaoNaoDispensaoDoCumprimento_3]:
      IndMotivoDesligamentoEnum.RescisãoIniciativaEmpregador_07,
    [IndAvisoPrevioEnum.PedidoDeDemissao_4]:
      IndMotivoDesligamentoEnum.RescisãoIniciativaEmpregador_07,
    [IndAvisoPrevioEnum.PedidoDeDemissaoIndenizado_5]:
      IndMotivoDesligamentoEnum.RescisãoIniciativaEmpregador_07,
    [IndAvisoPrevioEnum.TrabalhadoDadoEmpregadorRural_6]:
      IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
    [IndAvisoPrevioEnum.AvisoPrevioTrabalhoDecorrenteAcordo_7]:
      IndMotivoDesligamentoEnum.RescisaoAcordo_33,
    [IndAvisoPrevioEnum.AvisoPrevioMisto_8]:
      IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
    [IndAvisoPrevioEnum.Indenizado_9]:
      IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02,
  }
  return motivoDesligamentoMaps[tipoAvisoPrevio]
}

function getHomologacaoFields(vinculo: Vinculo): {
  homologacaoLocal: string
  homologacaoEndereco: string
  homologacaoCidadeId: string
} | null {
  const indHomologacao = vinculo?.estabelecimento?.empregador?.indHomologacao

  if (!indHomologacao) return null
  if (indHomologacao === IndHomologacaoEnum.Outros) return null

  if (indHomologacao === IndHomologacaoEnum.EstabelecimentoEmpregador) {
    const estabelecimento = vinculo.estabelecimento
    if (!estabelecimento) return null
    return {
      homologacaoLocal: estabelecimento.nome,
      homologacaoEndereco: `${estabelecimento.endereco} - ${estabelecimento.numero} - ${estabelecimento.bairro}`,
      homologacaoCidadeId: estabelecimento.cidadeId,
    }
  }
  if (indHomologacao === IndHomologacaoEnum.SedeSindicatoFuncionairo) {
    const sindicato = vinculo.sindicato
    if (!sindicato) return null
    return {
      homologacaoLocal: sindicato.nome,
      homologacaoEndereco: `${sindicato.endereco} - ${sindicato.numero} - ${sindicato.bairro}`,
      homologacaoCidadeId: sindicato.cidadeId,
    }
  }

  return null
}

const schema = yup.object().shape({
  tipoAvisoPrevio: yup
    .mixed()
    .oneOf(Object.values(IndAvisoPrevioEnum), 'Informe o Tipo Aviso Prévio'),
  motivoDesligamento: yup
    .mixed()
    .oneOf(Object.values(IndMotivoDesligamentoEnum), 'Informe o Motivo de Desligamento'),
  indCumprimento: yup
    .mixed()
    .oneOf(Object.values(IndCumprimentoEnum), 'Informe o Indicador de Cumprimento'),
  rescisaoFGTS: yup.mixed().oneOf(Object.values(RescisaoFGTSEnum), 'Informe a Rescisão FGTS'),
  rescisaoHomologNet: yup
    .mixed()
    .oneOf(Object.values(HomologNetEnum), 'Informe a Rescisão Homolognet'),
  dtEmissao: yup.date().nullable().required('Informe a Data Emissão'),
  dtInicio: yup.date().nullable().required('Informe a Data Início'),
  dtRescisao: yup
    .date()
    .nullable()
    .required('Informe a Data Rescisão')
    .when(['dtEmissao'], (dtEmissao: string | null, schema) =>
      schema.test(
        'dt-rescisao-no-less',
        'Informe a Data de Rescisão maior que a Data de Emissão',
        (dtRescisao: string | null) => {
          if (!dtRescisao) return true
          if (!dtEmissao) return true
          return moment(dtRescisao).isSameOrAfter(dtEmissao)
        },
      ),
    ),
  numeroDiasDeAvisoPrevio: yup
    .number()
    .required('Informe o Número de dias Aviso Prévio')
    .min(1, 'Informe o Número de dias Aviso Prévio')
    .nullable()
    .when(
      ['numeroDiasDeAvisoPrevioTrabalhado', 'numeroDiasDeAvisoPrevioIndenizado', 'tipoAvisoPrevio'],
      (
        numeroDiasDeAvisoPrevioTrabalhado,
        numeroDiasDeAvisoPrevioIndenizado,
        tipoAvisoPrevio: IndAvisoPrevioEnum | null,
        schema,
      ) => {
        return schema.test(
          'verify-sum-of-days',
          'A soma dos dias Trabalhados e Indenizados devem ser igual ao dias do Aviso',
          (numeroDiasDeAvisoPrevio: number) => {
            if (tipoAvisoPrevio === IndAvisoPrevioEnum.PedidoDeDemissao_4) {
              return true
            }

            numeroDiasDeAvisoPrevio = numeroDiasDeAvisoPrevio || 0
            numeroDiasDeAvisoPrevioTrabalhado = numeroDiasDeAvisoPrevioTrabalhado || 0
            numeroDiasDeAvisoPrevioIndenizado = numeroDiasDeAvisoPrevioIndenizado || 0

            const soma = numeroDiasDeAvisoPrevioTrabalhado + numeroDiasDeAvisoPrevioIndenizado

            return numeroDiasDeAvisoPrevio === soma
          },
        )
      },
    ),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoAvisoPrevio
  onAfterSubmitForm: (event: 'insert' | 'update', data: VinculoAvisoPrevio) => void
  subtitlePage: string
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, subtitlePage } = props

  const [data, setData] = useState<VinculoAvisoPrevioForm>(_data)
  const [vinculo, setVinculo] = useState<Vinculo | null>(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isLoadingStartData, setLoadingStartData] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { estabelecimento } = useAmbiente()

  const {
    close: closeDialogValidadeDtBase,
    open: openDialogValidadeDtBase,
    data: dataDialogValidadeDtBase,
    isOpen: isOpenDialogValidadeDtBase,
  } = useDialog()
  const {
    close: closeFormHorariosVinculo,
    open: openFormHorariosVinculo,
    data: dataFormHorariosVinculo,
    isOpen: isOpenFormHorariosVinculo,
  } = useDialog()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: verifyIsValidadeDataBase,
    data,
  })

  useEffect(() => {
    const startData = async () => {
      setLoadingStartData(true)
      try {
        const response = await api.get(`Vinculo/${_data.vinculoId}`)
        const currentVinculo = response?.data?.data || null
        if (!currentVinculo) return

        if (!_data?.id) {
          const responseHomologacao = getHomologacaoFields(currentVinculo)
          if (!responseHomologacao) return
          const { homologacaoLocal, homologacaoEndereco, homologacaoCidadeId } = responseHomologacao
          setData((oldState) => ({
            ...oldState,
            homologacaoLocal,
            homologacaoEndereco,
            homologacaoCidadeId,
          }))
        }

        setVinculo(currentVinculo)
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingStartData(false)
      }
    }
    startData()
  }, [_data])

  function handleSubmit(_data?: VinculoAvisoPrevioForm) {
    const dataCurrent = _data ? _data : data

    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/vinculoAvisoPrevio/${data.id}`, dataCurrent)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculoAvisoPrevio/criar', dataCurrent)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (dataCurrent?.id) {
      update()
      return
    }
    insert()
  }

  function verifyDtMesBase(mes: string, dtToCompare: string | Date) {
    const newMes = parseInt(mes) // 01 | 02 ...
    const previousMes = newMes - 1 <= 0 ? 12 : newMes - 1
    const monthDtToCompare = moment(dtToCompare, 'yyyy-MM-DD').month() + 1
    return monthDtToCompare === newMes || monthDtToCompare === previousMes
  }

  async function verifyIsValidadeDataBase() {
    let dtMesBase: string | null = null
    setSubmitting(true)
    try {
      const response = await api.get(`/Estabelecimento/${estabelecimento.id}`)
      dtMesBase = response?.data.data.mesDtBase || null
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
    }

    function validadeDataBaseByDtRescisao() {
      const { dtRescisao, isIndenizacaoDataBase } = data
      if (dtMesBase && dtRescisao && verifyDtMesBase(dtMesBase, dtRescisao)) {
        openDialogValidadeDtBase({
          dtMesBase,
          dtToDisplay: dtRescisao,
          isIndenizacaoDataBase,
        })
        return
      }
      handleSubmit()
    }

    function validadeDataBaseByDtRescisaoMoreDiasIndenizado() {
      const { dtRescisao, numeroDiasDeAvisoPrevioIndenizado, isIndenizacaoDataBase } = data
      const newDtRescisao = dtRescisao
        ? moment(dtRescisao, 'yyyy-MM-DD')
            .add(numeroDiasDeAvisoPrevioIndenizado || 0, 'd')
            .format('yyyy-MM-DD')
        : null
      if (dtMesBase && newDtRescisao && verifyDtMesBase(dtMesBase, newDtRescisao)) {
        openDialogValidadeDtBase({
          dtMesBase,
          dtToDisplay: newDtRescisao,
          isIndenizacaoDataBase,
        })
        return
      }
      handleSubmit()
    }

    function validadeDataBaseByDtProjecao() {
      const { dtProjecaoAvisoPrevio, isIndenizacaoDataBase } = data
      if (dtMesBase && dtProjecaoAvisoPrevio && verifyDtMesBase(dtMesBase, dtProjecaoAvisoPrevio)) {
        openDialogValidadeDtBase({
          dtMesBase,
          dtToDisplay: dtProjecaoAvisoPrevio,
          isIndenizacaoDataBase,
        })
        return
      }
      handleSubmit()
    }

    switch (data.tipoAvisoPrevio) {
      case IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoHoras_1:
        validadeDataBaseByDtRescisao()
        break
      case IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoDias_2:
        validadeDataBaseByDtRescisao()
        break
      case IndAvisoPrevioEnum.PedidoDemissaoNaoDispensaoDoCumprimento_3:
        handleSubmit()
        break
      case IndAvisoPrevioEnum.PedidoDeDemissao_4:
        handleSubmit()
        break
      case IndAvisoPrevioEnum.PedidoDeDemissaoIndenizado_5:
        handleSubmit()
        break
      case IndAvisoPrevioEnum.TrabalhadoDadoEmpregadorRural_6:
        validadeDataBaseByDtRescisao()
        break
      case IndAvisoPrevioEnum.AvisoPrevioTrabalhoDecorrenteAcordo_7:
        handleSubmit()
        break
      case IndAvisoPrevioEnum.AvisoPrevioMisto_8:
        validadeDataBaseByDtRescisaoMoreDiasIndenizado()
        break
      case IndAvisoPrevioEnum.Indenizado_9:
        validadeDataBaseByDtProjecao()
        break
      default:
        console.error('Aviso Prévio não selecionado.')
        break
    }
  }

  function onChangeDialogValidadeDtBase(isIndenizacaoDataBase: boolean) {
    handleSubmit({ ...data, isIndenizacaoDataBase })
  }

  const changeFieldsTrabalhadoDadoEmpregador = (dtInicio: string, numeroDiasCalculados: number) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(numeroDiasCalculados, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')
    const dtRescisao = dtProjecaoAvisoPrevio

    const numeroDiasDeAvisoPrevio = numeroDiasCalculados
    const numeroDiasDeAvisoPrevioTrabalhado = numeroDiasCalculados
    const numeroDiasDeAvisoPrevioIndenizado = 0

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const changeFieldsTrabalhadoDadoEmpregadorReducaoDias = (
    dtInicio: string,
    numeroDiasCalculados: number,
  ) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(numeroDiasCalculados, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')
    const dtRescisao = dtProjecaoAvisoPrevio

    const numeroDiasDeAvisoPrevio = numeroDiasCalculados
    const numeroDiasDeAvisoPrevioTrabalhado = numeroDiasCalculados
    const numeroDiasDeAvisoPrevioIndenizado = 0

    handleCalcReducaoDias(dtRescisao, 7)

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const changeFieldsPedidoDemissaoDispensado = (dtInicio: string) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(30, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')
    const dtRescisao = dtProjecaoAvisoPrevio

    const numeroDiasDeAvisoPrevio = 30
    const numeroDiasDeAvisoPrevioTrabalhado = 30
    const numeroDiasDeAvisoPrevioIndenizado = 0

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const changeFieldsPedidoDemissao = (dtInicio: string) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(30, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')
    const dtRescisao = moment(dtInicio).subtract(1, 'day').format('yyyy-MM-DD')

    const numeroDiasDeAvisoPrevio = 30
    const numeroDiasDeAvisoPrevioTrabalhado = 0
    const numeroDiasDeAvisoPrevioIndenizado = 0

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const changeFieldsPedidoDemissaoIndenizado = (dtInicio: string) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(30, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')
    const dtRescisao = moment(dtInicio).subtract(1, 'day').format('yyyy-MM-DD')

    const numeroDiasDeAvisoPrevio = 30
    const numeroDiasDeAvisoPrevioTrabalhado = 0
    const numeroDiasDeAvisoPrevioIndenizado = 30

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const changeFieldsMisto = (dtInicio: string, numeroDiasCalculados: number) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(numeroDiasCalculados, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')

    const restDays = numeroDiasCalculados - 30

    const numeroDiasDeAvisoPrevio = numeroDiasCalculados
    const numeroDiasDeAvisoPrevioTrabalhado = 30
    const numeroDiasDeAvisoPrevioIndenizado = restDays < 0 ? 0 : restDays

    const dtRescisao = moment(dtInicio)
      .add(numeroDiasDeAvisoPrevioTrabalhado, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const changeFieldsIndenizado = (dtInicio: string, numeroDiasCalculados: number) => {
    const dtProjecaoAvisoPrevio = moment(dtInicio)
      .add(numeroDiasCalculados, 'day')
      .subtract(1, 'days')
      .format('yyyy-MM-DD')
    const dtRescisao = moment(dtInicio).subtract(1, 'day').format('yyyy-MM-DD')

    const numeroDiasDeAvisoPrevio = numeroDiasCalculados
    const numeroDiasDeAvisoPrevioTrabalhado = 0
    const numeroDiasDeAvisoPrevioIndenizado = numeroDiasCalculados

    return {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }
  }

  const handleCalcFields = (dtEmissao: string | null, _dtInicio: string | null) => {
    if (!dtEmissao || !data?.tipoAvisoPrevio) {
      setData((oldState) => ({
        ...oldState,
        homologacaoData: null,
        dtEmissao: null,
        dtInicio: null,

        dtProjecaoAvisoPrevio: null,
        dtRescisao: null,

        numeroDiasDeAvisoPrevio: null,
        numeroDiasDeAvisoPrevioTrabalhado: null,
        numeroDiasDeAvisoPrevioIndenizado: null,
      }))
      return
    }

    const dtInicio = _dtInicio ? _dtInicio : moment(dtEmissao).add(1, 'day').format('yyyy-MM-DD')

    const numeroDiasCalculados = calcDiasAvisoPrevio(dtInicio, vinculo?.dtAdmissao)

    const changeMaps = {
      [IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoHoras_1]:
        changeFieldsTrabalhadoDadoEmpregador,
      [IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoDias_2]:
        changeFieldsTrabalhadoDadoEmpregadorReducaoDias,
      [IndAvisoPrevioEnum.PedidoDemissaoNaoDispensaoDoCumprimento_3]:
        changeFieldsPedidoDemissaoDispensado,
      [IndAvisoPrevioEnum.PedidoDeDemissao_4]: changeFieldsPedidoDemissao,
      [IndAvisoPrevioEnum.PedidoDeDemissaoIndenizado_5]: changeFieldsPedidoDemissaoIndenizado,
      [IndAvisoPrevioEnum.TrabalhadoDadoEmpregadorRural_6]: changeFieldsTrabalhadoDadoEmpregador,
      [IndAvisoPrevioEnum.AvisoPrevioTrabalhoDecorrenteAcordo_7]:
        changeFieldsTrabalhadoDadoEmpregador,
      [IndAvisoPrevioEnum.AvisoPrevioMisto_8]: changeFieldsMisto,
      [IndAvisoPrevioEnum.Indenizado_9]: changeFieldsIndenizado,
    }

    const {
      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    } = changeMaps[data.tipoAvisoPrevio](dtInicio, numeroDiasCalculados)

    const homologacaoData = dtRescisao ? moment(dtRescisao).add(9, 'd').format('yyyy-MM-DD') : null

    setData((oldState) => ({
      ...oldState,
      homologacaoData,
      dtEmissao,
      dtInicio,

      dtProjecaoAvisoPrevio,
      dtRescisao,

      numeroDiasDeAvisoPrevio,
      numeroDiasDeAvisoPrevioTrabalhado,
      numeroDiasDeAvisoPrevioIndenizado,
    }))
  }

  function handleChangeTipoAvisoPrevio(tipoAvisoPrevio: IndAvisoPrevioEnum | null) {
    const motivoDesligamento = tipoAvisoPrevio
      ? changeTipoRescisaoByTipoAviso(tipoAvisoPrevio)
      : null
    let deducaoHoras: string | null = null
    let reducaoDias: number | null = null

    if (motivoDesligamento) {
      changeRescisaoByMotivoDesligamento(motivoDesligamento)
    }

    if (tipoAvisoPrevio === IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoHoras_1) {
      deducaoHoras = '02:00'
    }

    if (tipoAvisoPrevio === IndAvisoPrevioEnum.TrabalhadoDadoPeloEmpregadorReducaoDias_2) {
      reducaoDias = 7
    }

    setData((oldState) => ({
      ...oldState,
      tipoAvisoPrevio,
      motivoDesligamento,

      dtEmissao: null,
      dtInicio: null,

      dtProjecaoAvisoPrevio: null,
      dtRescisao: null,
      numeroDiasDeAvisoPrevio: null,
      numeroDiasDeAvisoPrevioTrabalhado: null,
      numeroDiasDeAvisoPrevioIndenizado: null,

      reducaoDias,
      dtTerminoReducao: null,
      deducaoHoras,
      hEntrada: null,
      hSaida: null,
    }))
  }

  function handleCalcReducaoDias(dtRescisao: string | Date, reducaoDias: number) {
    const dtTerminoReducao = moment(dtRescisao).subtract(reducaoDias, 'days').format('yyyy-MM-DD')
    setData((oldState) => ({
      ...oldState,
      dtTerminoReducao,
    }))
  }

  function changeRescisaoByMotivoDesligamento(motivoDesligamento: IndMotivoDesligamentoEnum) {
    if (
      motivoDesligamento === IndMotivoDesligamentoEnum.RescisaoSemJustaCausaIniciativaEmpregador_02
    ) {
      setData((oldState) => ({
        ...oldState,
        rescisaoFGTS: RescisaoFGTSEnum.Rescisao_sem_justa_causa_empregador,
        rescisaoHomologNet: HomologNetEnum.DespedidaSemJustaCausaPeloEmpregador_SJ2,
      }))
    } else {
      setData((oldState) => ({
        ...oldState,
        rescisaoFGTS: null,
        rescisaoHomologNet: null,
      }))
    }
  }

  return (
    <ActionDialog
      title="Cadastro de Aviso Prévio"
      subtitle={subtitlePage}
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {isLoadingStartData ? <LinearProgress /> : <Box height={4} />}

          <MUIAutoComplete
            label="Tipo Aviso Prévio"
            options={IndAvisoPrevioValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.tipoAvisoPrevio || null}
            required
            validationErrors={validationErrors}
            name="tipoAvisoPrevio"
            onChange={(e, obj) => handleChangeTipoAvisoPrevio(obj?.value || null)}
          />
        </Grid>

        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <MUIAutoComplete
            label="Motivo de Desligamento"
            value={data.motivoDesligamento || null}
            options={motivoDesligamentoValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            name="motivoDesligamento"
            validationErrors={validationErrors}
            onChange={(e, obj) => {
              const motivoDesligamento = obj ? obj.value : null
              changeRescisaoByMotivoDesligamento(motivoDesligamento)
              setData((oldState) => ({
                ...oldState,
                motivoDesligamento,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Indicador de Cumprimento"
            value={data.indCumprimento || null}
            options={IndCumprimentoValues}
            renderOption={(option) => option.name}
            optionId="value"
            onChange={(e, obj) => {
              const indCumprimento = obj?.value || null
              setData({ ...data, indCumprimento })
            }}
            required
            validationErrors={validationErrors}
            name="indCumprimento"
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePickerNew
            label="Data Emissão"
            size="small"
            value={data.dtEmissao || null}
            required
            validationErrors={validationErrors}
            name="dtEmissao"
            onChange={(date) => {
              handleCalcFields(date, null)
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePickerNew
            label="Data Início do Aviso/Projeção"
            size="small"
            value={data.dtInicio || null}
            required
            validationErrors={validationErrors}
            name="dtInicio"
            onChange={(date) => {
              const dtInicio = date
              setData((prev) => ({ ...prev, dtInicio }))
            }}
            onBlur={() => handleCalcFields(data.dtEmissao || null, data.dtInicio || null)}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePickerNew
            label="Data Admissão Funcionário"
            size="small"
            value={vinculo?.dtAdmissao || null}
            disabled
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            label="Número de dias Aviso Prévio"
            fullWidth
            value={data.numeroDiasDeAvisoPrevio || 0}
            size="small"
            onlyNumber
            inputProps={{ maxLength: 9 }}
            required
            validationErrors={validationErrors}
            name="numeroDiasDeAvisoPrevio"
            onChange={(e) => {
              const numeroDiasDeAvisoPrevio = e?.target?.value ? parseInt(e.target.value) : null
              setData({ ...data, numeroDiasDeAvisoPrevio })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            label="Número de dias Trabalhado"
            fullWidth
            value={data.numeroDiasDeAvisoPrevioTrabalhado || 0}
            size="small"
            onlyNumber
            inputProps={{ maxLength: 9 }}
            onChange={(e) => {
              const numeroDiasDeAvisoPrevioTrabalhado = e?.target?.value
                ? parseInt(e.target.value)
                : null
              const dtRescisao =
                numeroDiasDeAvisoPrevioTrabalhado && data.dtInicio
                  ? moment(data.dtInicio)
                      .add(numeroDiasDeAvisoPrevioTrabalhado, 'day')
                      .format('yyyy-MM-DD')
                  : null
              setData((oldState) => ({
                ...oldState,
                numeroDiasDeAvisoPrevioTrabalhado,
                dtRescisao,
              }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            label="Número de dias Indenizado"
            fullWidth
            value={data.numeroDiasDeAvisoPrevioIndenizado || 0}
            size="small"
            onlyNumber
            inputProps={{ maxLength: 9 }}
            onChange={(e) => {
              const numeroDiasDeAvisoPrevioIndenizado = e?.target?.value
                ? parseInt(e.target.value)
                : null
              setData({ ...data, numeroDiasDeAvisoPrevioIndenizado })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePickerNew
            label="Data Final da Projeção"
            size="small"
            value={data.dtProjecaoAvisoPrevio || null}
            onChange={(date) => {
              const dtProjecaoAvisoPrevio = date
              setData({
                ...data,
                dtProjecaoAvisoPrevio,
              })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePickerNew
            label="Data Rescisão"
            size="small"
            value={data.dtRescisao || null}
            required
            validationErrors={validationErrors}
            name="dtRescisao"
            onChange={(date) => {
              const dtRescisao = date
              setData({
                ...data,
                dtRescisao,
              })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Rescisão FGTS"
            options={RescisaoFGTSValues}
            renderOption={(option) => `${option.value} - ${option.name}`}
            value={data.rescisaoFGTS || null}
            onChange={(e, obj) => {
              const rescisaoFGTS = obj?.value || null
              setData((oldState) => ({ ...oldState, rescisaoFGTS }))
            }}
            optionId="value"
            required
            validationErrors={validationErrors}
            name="rescisaoFGTS"
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <MUIAutoComplete
            label="Rescisão Homolognet"
            options={HomologNetValues}
            renderOption={(option) => option.name}
            value={data.rescisaoHomologNet || null}
            onChange={(e, obj) => {
              const rescisaoHomologNet = obj?.value || null
              setData((oldState) => ({ ...oldState, rescisaoHomologNet }))
            }}
            optionId="value"
            required
            validationErrors={validationErrors}
            name="rescisaoHomologNet"
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Redução Horas" />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TimePicker
            label="Horas"
            value={data.deducaoHoras || null}
            onChange={(value, date) => {
              const deducaoHoras = date?.format('HH:mm')
              setData({ ...data, deducaoHoras })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    title="Visualizar Horários do Vínculo"
                    aria-label="Horários"
                    disabled={vinculo?.id ? false : true}
                    onClick={() => openFormHorariosVinculo(vinculo)}
                  >
                    <ViewHeadline fontSize="small" color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TimePicker
            label="Entrada"
            value={data.hEntrada || null}
            onChange={(value, date) => {
              const hEntrada = date?.format('HH:mm')
              setData({ ...data, hEntrada })
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TimePicker
            label="Saída"
            value={data.hSaida || null}
            onChange={(value, date) => {
              const hSaida = date?.format('HH:mm')
              setData({ ...data, hSaida })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Redução Dias" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Número de Dias para redução"
            fullWidth
            value={data.reducaoDias || 0}
            size="small"
            onlyNumber
            inputProps={{ maxLength: 9 }}
            onChange={(e) => {
              const reducaoDias = e?.target?.value ? parseInt(e.target.value) : null
              setData({ ...data, reducaoDias })
            }}
            onBlur={(e) => {
              const reducaoDias = e?.target?.value ? parseInt(e.target.value) : null
              if (!data.dtRescisao || !reducaoDias) return
              handleCalcReducaoDias(data.dtRescisao, reducaoDias)
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DatePickerNew
            label="Término Redução"
            value={data.dtTerminoReducao || null}
            onChange={(date) => {
              const dtTerminoReducao = date
              setData({
                ...data,
                dtTerminoReducao,
              })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider title="Homologação" />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePickerNew
            label="Data de Homologação"
            size="small"
            value={data.homologacaoData || null}
            onChange={(date) => {
              const homologacaoData = date
              setData({
                ...data,
                homologacaoData,
              })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TimePicker
            label="Hora Início"
            value={data?.homologacaoHoraInicio || null}
            onChange={(value, date) => {
              const homologacaoHoraInicio = date?.format('HH:mm')
              setData((oldState) => ({
                ...oldState,
                homologacaoHoraInicio,
              }))
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TimePicker
            label="Hora Fim"
            value={data?.homologacaoHoraFim || null}
            onChange={(value, date) => {
              const homologacaoHoraFim = date?.format('HH:mm')
              setData((oldState) => ({
                ...oldState,
                homologacaoHoraFim,
              }))
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <TextField
            label="Local"
            fullWidth
            value={data.homologacaoLocal || ''}
            size="small"
            multiline
            inputProps={{ maxLength: 80 }}
            onChange={(e) => {
              const homologacaoLocal = e?.target?.value || null
              setData({ ...data, homologacaoLocal })
            }}
          />
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <TextField
            label="Endereço"
            fullWidth
            value={data.homologacaoEndereco || ''}
            size="small"
            multiline
            inputProps={{ maxLength: 80 }}
            onChange={(e) => {
              const homologacaoEndereco = e?.target?.value || null
              setData({ ...data, homologacaoEndereco })
            }}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
          <AutoCompleteCidade
            value={data.homologacaoCidadeId || null}
            onChange={(e, cidadeObj) => {
              const homologacaoCidadeId = cidadeObj?.id || null
              setData({
                ...data,
                homologacaoCidadeId,
              })
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            fullWidth
            value={data.observacao || ''}
            size="small"
            multiline
            inputProps={{ maxLength: 200 }}
            onChange={(e) => {
              const observacao = e?.target?.value || null
              setData({ ...data, observacao })
            }}
          />
        </Grid>
      </Grid>

      {isOpenFormHorariosVinculo && (
        <FormHorariosVinculo
          isOpen={isOpenFormHorariosVinculo}
          onClose={closeFormHorariosVinculo}
          vinculo={dataFormHorariosVinculo}
        />
      )}

      {isOpenDialogValidadeDtBase && (
        <DialogValidadeDtBase
          isOpen={isOpenDialogValidadeDtBase}
          onClose={closeDialogValidadeDtBase}
          data={dataDialogValidadeDtBase}
          onChange={onChangeDialogValidadeDtBase}
        />
      )}
    </ActionDialog>
  )
}
