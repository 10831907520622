import { useState, useEffect } from 'react'

import api from '~/services/api-pessoal'

import { Divider, Grid, LinearProgress } from '@material-ui/core'

import { ActionDialog, TextField } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import { getDataAtualPorExtenso } from '~/hooks/useUtils'

import {
  RelCompensacaoHorasTrabalhadasDTO,
  RelRelCompensacaoHorasTrabalhadasModelo2,
} from '~/hooks/queries/CompensacaoHorasTrabalhadas/RelCompensacaoHorasTrabalhadasDTO'

import { DiaHorarioEnum } from '~/@types/enums/DiaHorarioEnum'

function formatSimpleHour(hour: FixLater) {
  if (!hour) return null
  return `${hour.substring(0, 2)}:${hour.substring(2)}`
}

function changeHorario(diasInicializacao: FixLater, nameField: FixLater, horario: FixLater) {
  const horaEntrada = formatSimpleHour(horario?.horarioTrabalho?.horaEntrada)
  const horaSaida = formatSimpleHour(horario?.horarioTrabalho?.horaSaida)
  diasInicializacao[nameField] = horaEntrada ? `DE ${horaEntrada} ÀS ${horaSaida}` : 'DESCANSO'
}

interface FormModelo2Props {
  isOpen: boolean
  onClose: () => void
  vinculosIds: string[]
  onAfterSubmitForm: (dt: RelCompensacaoHorasTrabalhadasDTO) => void
}

export default function FormModelo2(props: FormModelo2Props) {
  const { isOpen, onClose, vinculosIds, onAfterSubmitForm } = props

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<RelRelCompensacaoHorasTrabalhadasModelo2>({
    domingo: '',
    segunda: '',
    terca: '',
    quarta: '',
    quinta: '',
    sexta: '',
    sabado: '',
    intervaloAlimentacao: '',
    intervaloLanche: '',
    motivoCompensacao: '',
    localData: '',
  })

  const dialogNotification = useDialogNotification()

  useEffect(() => {
    async function obterHorarioTrabalho() {
      setLoading(true)
      try {
        const vinculoId = vinculosIds[0]
        const response = await api.get(
          '/Relatorio/CompensacaoHorasTrabalhadas/ObterHorarioTrabalhoFuncionario',
          {
            params: {
              vinculoId,
            },
          },
        )

        const horarios = response?.data?.data?.vinculoHorarioItens || []

        const estabelecimento = response?.data?.data?.vinculo?.estabelecimento

        const diasInicializacao = {
          domingo: 'DESCANSO',
          segunda: 'DESCANSO',
          terca: 'DESCANSO',
          quarta: 'DESCANSO',
          quinta: 'DESCANSO',
          sexta: 'DESCANSO',
          sabado: 'DESCANSO',
        }

        horarios.forEach((horario: FixLater) => {
          switch (horario.diaHorario) {
            case DiaHorarioEnum.Domingo_7:
              changeHorario(diasInicializacao, 'domingo', horario)
              break
            case DiaHorarioEnum.SegundaFeira_1:
              changeHorario(diasInicializacao, 'segunda', horario)
              break
            case DiaHorarioEnum.Terça_Feira_2:
              changeHorario(diasInicializacao, 'terca', horario)
              break
            case DiaHorarioEnum.Quarta_Feira_3:
              changeHorario(diasInicializacao, 'quarta', horario)
              break
            case DiaHorarioEnum.Quinta_Feira_4:
              changeHorario(diasInicializacao, 'quinta', horario)
              break
            case DiaHorarioEnum.Sexta_Feira_5:
              changeHorario(diasInicializacao, 'sexta', horario)
              break
            case DiaHorarioEnum.Sabado_6:
              changeHorario(diasInicializacao, 'sabado', horario)
              break
            default:
              break
          }
        })

        const firstIntervalo = horarios[0]?.horarioTrabalho?.horarioTrabalhoIntervalo[0] || null
        const inicioIntervalo = formatSimpleHour(firstIntervalo?.inicioIntervalo)
        const fimIntervalo = formatSimpleHour(firstIntervalo?.fimIntervalo)

        setData({
          domingo: diasInicializacao.domingo,
          segunda: diasInicializacao.segunda,
          terca: diasInicializacao.terca,
          quarta: diasInicializacao.quarta,
          quinta: diasInicializacao.quinta,
          sexta: diasInicializacao.sexta,
          sabado: diasInicializacao.sabado,
          intervaloAlimentacao: inicioIntervalo ? `DE ${inicioIntervalo} ÀS ${fimIntervalo}` : '',
          intervaloLanche: '',
          localData: estabelecimento?.cidadeNome
            ? `${estabelecimento.cidadeNome}-${
                estabelecimento.cidadeUF
              }, ${getDataAtualPorExtenso()}`
            : '',
          motivoCompensacao: '',
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    obterHorarioTrabalho()
    // eslint-disable-next-line
  }, [vinculosIds])

  async function handleSubmit() {
    onAfterSubmitForm({
      vinculos: vinculosIds,
      jornadaNormal: null,
      jornadaSabadoFeriado: null,
      jornadaSemanaFeriado: null,
      modelo2Relatorio: data,
    })
  }

  return (
    <ActionDialog
      title="Informe dados para o Acordo de Compensação de Jornada de Trabalho"
      isOpen={isOpen}
      onCancelClick={onClose}
      onClose={onClose}
      dialogProps={{
        maxWidth: 'sm',
      }}
      okLabel="Imprimir"
      onOkClick={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isLoading ? <LinearProgress /> : <></>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Domingo"
            value={data.domingo}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const domingo = e.target.value
              setData((prev) => ({ ...prev, domingo }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Segunda-Feira"
            value={data.segunda}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const segunda = e.target.value
              setData((prev) => ({ ...prev, segunda }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Terça-Feira"
            value={data.terca}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const terca = e.target.value
              setData((prev) => ({ ...prev, terca }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Quarta-Feira"
            value={data.quarta}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const quarta = e.target.value
              setData((prev) => ({ ...prev, quarta }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Quinta-Feira"
            value={data.quinta}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const quinta = e.target.value
              setData((prev) => ({ ...prev, quinta }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Sexta-Feira"
            value={data.sexta}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const sexta = e.target.value
              setData((prev) => ({ ...prev, sexta }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Sábado"
            value={data.sabado}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const sabado = e.target.value
              setData((prev) => ({ ...prev, sabado }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Intervalo Alimentação"
            value={data.intervaloAlimentacao}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const intervaloAlimentacao = e.target.value
              setData((prev) => ({ ...prev, intervaloAlimentacao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Intervalo Lanche"
            value={data.intervaloLanche}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const intervaloLanche = e.target.value
              setData((prev) => ({ ...prev, intervaloLanche }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Motivo da Compensação"
            value={data.motivoCompensacao}
            inputProps={{
              maxLength: 255,
            }}
            rows={4}
            multiline
            onChange={(e) => {
              const motivoCompensacao = e.target.value
              setData((prev) => ({ ...prev, motivoCompensacao }))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Local e Data"
            value={data.localData}
            inputProps={{
              maxLength: 55,
            }}
            onChange={(e) => {
              const localData = e.target.value
              setData((prev) => ({ ...prev, localData }))
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
