import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'

import { PageHeader, Finder, Button } from '~/components'

import Table from './Table'
import Form from './Form'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import { useVinculoConsultaGetVinculosRescisaoTSV } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetVinculosRescisaoTSV'

const PAGE_TITLE = 'Rescisão TSV'

export default function RescisaoTSV() {
  const [query, setQuery] = useState('')
  const [rowsSelecteds, setRowsSelected] = useState<number[]>([])

  const {
    close: closeForm,
    isOpen: isOpenForm,
    data: dataForm,
    open: openForm,
  } = useDialog<string[]>([])

  const { estabelecimento, anoMes } = useAmbiente()
  const { data, isLoading, isFetching, refetch } = useVinculoConsultaGetVinculosRescisaoTSV(
    estabelecimento.id,
    anoMes,
  )

  useEffect(() => {
    setRowsSelected([])
  }, [estabelecimento, anoMes])

  function handleOpenForm() {
    const ids = rowsSelecteds.map((index) => data[index].id)
    openForm(ids)
  }

  function handleAfterSubmit() {
    closeForm()
    setRowsSelected([])
    refetch()
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE}>
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Table
        query={query}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelecteds}
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          vinculosSelecionados={dataForm}
          onAfterSubmit={handleAfterSubmit}
        />
      )}

      <Box display="flex" justifyContent="flex-end" py={1}>
        <Button variant="contained" onClick={handleOpenForm} disabled={!(rowsSelecteds.length > 0)}>
          Gerar Rescisão
        </Button>
      </Box>
    </Box>
  )
}
