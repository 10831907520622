import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import { Finder } from 'mio-library-ui'

import { ActionDialog, PageHeader, PrintButton, PDFViewer } from '~/components'

import Table from './components/Table'
import StepContainer from './components/StepContainer'
import FormModelo2 from './components/FormModelo2'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useDialogNotification from '~/hooks/useDialogNotification'

import api from '~/services/api-pessoal'

import { IndModeloCompensacaoHorasTrabalhadasEnum } from '~/@types/enums/IndModeloCompensacaoHorasTrabalhadasEnum'

export default function CompensacaoHorasTrabalhadas() {
  const [collection, setCollection] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelected] = useState([])

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const {
    close: closeContainer,
    isOpen: isOpenContainer,
    open: openContainer,
    data: dataContainer,
  } = useDialog()
  const {
    close: closeFormModelo2,
    isOpen: isOpenFormModelo2,
    open: openFormModelo2,
    data: dataFormModelo2,
  } = useDialog(null)
  const {
    close: closePDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
    data: dataPDFViewer,
  } = useDialog()

  const parametrosSistema = useSelector((state) => state.app.parametrosSistema)

  useEffect(() => {
    async function getCollection() {
      setLoading(true)
      try {
        const response = await api.get('/Vinculo/ObterVinculosPorEstabelecimento', {
          params: {
            estabelecimentoId: estabelecimento.id,
          },
        })
        setCollection(response?.data?.data || [])
        setRowsSelected([])
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoading(false)
      }
    }
    getCollection()
    //eslint-disable-next-line
  }, [estabelecimento])

  function handlePrint() {
    const ids = rowsSelected.map((index) => collection[index].id)
    if (
      parametrosSistema.indModeloCompensacaoHorasTrabalhadas ===
      IndModeloCompensacaoHorasTrabalhadasEnum.Modelo_01
    ) {
      openContainer(ids)
    } else {
      openFormModelo2(ids)
    }
  }

  function handleAfterSubmit(dt) {
    !dt.jornadaNormal.isUse && delete dt.jornadaNormal
    !dt.jornadaSabadoFeriado.isUse && delete dt.jornadaSabadoFeriado
    !dt.jornadaSemanaFeriado.isUse && delete dt.jornadaSemanaFeriado
    openPDFViewer(dt)
    closeContainer()
    setRowsSelected([])
  }

  function afterSubmitFormModelo2(dt) {
    openPDFViewer(dt)
    closeFormModelo2()
    setRowsSelected([])
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title="Acordo Compensação de Horas Trabalhadas">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>
      <Table
        query={query}
        isLoading={isLoading}
        data={collection}
        onRowSelected={setRowsSelected}
        rowsSelected={rowsSelected}
      />
      <Box display="flex" justifyContent="flex-end" py={1}>
        <PrintButton onClick={handlePrint} disabled={rowsSelected.length > 0 ? false : true} />
      </Box>

      <ActionDialog
        title="Informe dados para o acordo de compensação de horas trabalhadas"
        isOpen={isOpenContainer}
        onCancelClick={closeContainer}
        onClose={closeContainer}
        customActions={<></>}
        dialogProps={{
          maxWidth: 'md',
        }}
      >
        <StepContainer onAfterSubmit={handleAfterSubmit} data={dataContainer} />
      </ActionDialog>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="CompensacaoHorasTrabalhadas"
        title="Acordo Compensação de Horas Trabalhadas"
        isSalvarDocumento
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/CompensacaoHorasTrabalhadas/ObterRelatorio',
          data: dataPDFViewer,
          params: {
            estabelecimentoId: estabelecimento.id,
            anoMes,
          },
        }}
      />

      {isOpenFormModelo2 && (
        <FormModelo2
          isOpen={isOpenFormModelo2}
          onClose={closeFormModelo2}
          vinculosIds={dataFormModelo2}
          onAfterSubmitForm={afterSubmitFormModelo2}
        />
      )}
    </Box>
  )
}
