import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoPlanoSaude } from './VinculoPlanoSaude'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: VinculoPlanoSaude[]
  params: {
    anoMes: string
  }
}

export default function useProcessarPlanoSaude() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/VinculoPlanoSaude/ProcessarPlanoSaude', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Plano de Saúde processados para o recibo com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
