import { useState, useEffect, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'

import {
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Tooltip,
  Box,
  IconButton,
  useTheme,
} from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import { ButtonBox, TextField, ContentDivider, PageHeader } from 'mio-library-ui'
import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import {
  CollapseContent,
  NumeroInscricaoTextField,
  DatePicker,
  SimpleHoraTextField,
  ContainerForm,
  Checkbox as CheckboxComponent,
  CurrencyTextField,
  MaskedTextField,
  Fieldset,
  DatePickerNew,
  TextFieldLocked,
} from '~/components'
import {
  AutoCompleteTitulacao,
  AutoCompleteSindicato,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import { CategoriaEnum, CategoriaValues } from '~/@types/enums/CategoriaEnum'
import { IndMultiploVinculoValues } from '~/@types/enums/IndMultiploVinculoEnum'
import { IndGrauExposicaoValues } from '~/@types/enums/IndGrauExposicaoEnum'
import { tipoAdmissaoValues } from '~/values/tipoAdmissaoValues'
import { tipoContratoTrabalhoValues } from '~/values/tipoContratoTrabalhoValues'
import { JornadaTipoValues, JornadaTipoEnum } from '~/@types/enums/JornadaTipoEnum'
import { jornadaTempoParcialValues } from '~/values/jornadaTempoParcialValues'
import { indUnidadeSalarioValues } from '~/values/indUnidadeSalarioValues'
import { indPensaoAlimenticiaValues } from '~/values/indPensaoAlimenticiaValues'
import { indDSRValues } from '~/values/indDSRValues'
import { naturezaAtividadeValues } from '~/values/naturezaAtividadeValues'
import { DayOfWeekValues } from '~/@types/enums/DayOfWeekEnum'
import {
  tipoInscricaoConsts,
  tipoInscricaoValues as _tipoInscricaoValues,
} from '~/values/tipoInscricaoValues'
import { IndSimplesValues } from '~/@types/enums/IndSimplesEnum'
import { IndComissionistaValues } from '~/@types/enums/IndComissionistaEnum'
import { copyToClipboard } from '~/hooks/useUtils'
import {
  AprendizIndContratacaoEnum,
  AprendizIndContratacaoValues,
} from '~/@types/enums/AprendizIndContratacaoEnum'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'
import { isCNPJ, isCPF } from 'brazilian-values'

const tipoInscricaoValues = _tipoInscricaoValues.filter(
  (obj) => obj.value === tipoInscricaoConsts.CNPJ_1 || obj.value === tipoInscricaoConsts.CPF_2,
)

const schema = yup.object().shape({
  matricula: yup.string().required('Informe a Matrícula'),
  indSimples: yup.string().required('Informe o Indicador Simples Nacional'),
  indGrauExposicao: yup.string().required('Informe o Indicador Grau Exposição'),
  indMultiplosVinculos: yup.string().required('Informe o Indicador Múltiplos Vínculos'),
  tipoAdmissao: yup.string().required('Informe o Tipo de Admissão'),
  tipoContrato: yup.string().required('Informe o Tipo de Contrato'),
  tipoJornada: yup.string().required('Informe o Tipo de Jornada'),
  descricaoTipoJornada: yup
    .string()
    .when(['tipoJornada'], (tipoJornada, schema, { value }) => {
      if (value) return
      return schema.test(
        'descricao-tipo-jornada-required',
        'Informe a Descrição da Jornada',
        () => parseInt(tipoJornada) !== JornadaTipoEnum.DemaisTipos_9,
      )
    })
    .nullable(),
  jornadaParcial: yup.string().required('Informe a Jornada Parcial'),
  unidadeSalarioFixo: yup.string().required('Informe a Unidade Salário Fixo'),
  sindicatoId: yup.string().required('Informe o Sindicato').nullable(),
  indPensaoAlimenticia: yup.string().required('Informe o Indicador Pensão Alimenícia'),
  indDSR: yup.string().required('Informe o Indicador Cálculo DSR'),
  naturezaAtividade: yup.string().required('Informe a Natureza Atividade'),

  aprendizIndContratacao: yup.mixed().when(['categoria'], (categoria, schema) => {
    if (categoria === CategoriaEnum.Empregado_Aprendiz_103) {
      return schema
        .required('Informe o Indicador de Modalidade de Contratação')
        .oneOf(
          Object.values(AprendizIndContratacaoEnum),
          'Informe o Indicador de Modalidade de Contratação',
        )
    } else {
      return schema
    }
  }),
  aprendizCNPJQualificadora: yup
    .mixed()
    .when(['categoria', 'aprendizIndContratacao'], (categoria, aprendizIndContratacao, schema) => {
      if (
        categoria === CategoriaEnum.Empregado_Aprendiz_103 &&
        aprendizIndContratacao == AprendizIndContratacaoEnum.Contratacao_Direta
      ) {
        return schema
          .required('Informe o CNPJ Qualificadora')
          .test({
            message: 'Informe um CNPJ válido',
            test: (cnpj) => isCNPJ(cnpj),
          })
          .nullable()
      } else {
        return schema.nullable()
      }
    }),
  aprendizCNPJAtividadesPraticas: yup
    .mixed()
    .when(['categoria'], (categoria, schema, { value }) => {
      if (categoria === CategoriaEnum.Empregado_Aprendiz_103 && value) {
        return schema
          .required('Informe o CNPJ Atividades Práticas')
          .test({
            message: 'Informe um CNPJ válido',
            test: (cnpj) => isCNPJ(cnpj),
          })
          .nullable()
      } else {
        return schema.nullable()
      }
    }),
  aprendizTpInscricaoContratacao: yup
    .mixed()
    .when(['categoria', 'aprendizIndContratacao'], (categoria, aprendizIndContratacao, schema) => {
      if (
        categoria === CategoriaEnum.Empregado_Aprendiz_103 &&
        aprendizIndContratacao == AprendizIndContratacaoEnum.Contratacao_Indireta
      ) {
        return schema
          .required('Informe o Tipo de Inscrição Contratante')
          .oneOf(Object.values(TipoInscricaoEnum), 'Informe o Tipo de Inscrição Contratante')
          .test({
            message: 'Informe o Tipo de Inscrição Contratante',
            test: (tipo) => tipo !== TipoInscricaoEnum.NaoInformar_0,
          })
      } else {
        return schema
      }
    }),
  aprendizNrInscricaoContratacao: yup
    .mixed()
    .when(
      ['categoria', 'aprendizIndContratacao', 'aprendizTpInscricaoContratacao'],
      (categoria, aprendizIndContratacao, aprendizTpInscricaoContratacao, schema) => {
        if (
          categoria === CategoriaEnum.Empregado_Aprendiz_103 &&
          aprendizIndContratacao == AprendizIndContratacaoEnum.Contratacao_Indireta
        ) {
          if (aprendizTpInscricaoContratacao === TipoInscricaoEnum.CPF_2) {
            return schema
              .required('Informe o Número Inscrição Contratante')
              .test({
                message: 'Informe um CPF válido',
                test: (cpf) => isCPF(cpf),
              })
              .nullable()
          } else {
            return schema
              .required('Informe o Número Inscrição Contratante')
              .test({
                message: 'Informe um CNPJ válido',
                test: (cnpj) => isCNPJ(cnpj),
              })
              .nullable()
          }
        } else {
          return schema
        }
      },
    ),
})

const HEIGHT_HEADER = '70px'

const Form = (props) => {
  const { vinculo: _data, onClickClose, otherLocale, hasAdmissaoPreliminar = false } = props

  const [data, setData] = useState({})
  const [collapseItens, setCollapseItens] = useState({
    primaria: true,
    admissao: true,
    contrato: true,
    outras: true,
    desligamento: true,
    eSocial: true,
    parametros: true,
    aprendiz: true,
  })
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const history = useHistory()
  const theme = useTheme()

  useEffect(() => {
    const formatData = () => {
      const newData = {
        ..._data,
        horasMes: _data?.horasMes ? _data.horasMes.toFixed(2) : null,
        horasSemana: _data?.horasSemana ? _data.horasSemana.toFixed(2) : null,
      }
      setData(newData)
    }
    formatData()
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        await api.put(`/vinculo/${data.id}`, data)
        notification.put()
        if (!otherLocale) {
          history.push({
            pathname: '/funcionario',
          })
        } else {
          onClickClose()
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/vinculo', data)
        notification.post()
        onClickClose()
        history.push({
          pathname: `/funcionario/${response.data.data.id}`,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    delete data.estabelecimento
    delete data.pessoaFisica
    if (data.id) {
      update()
      return
    }
    insert()

    // eslint-disable-next-line
  }, [data, onClickClose, otherLocale])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const handleCollapseAll = (value) => {
    let newItens = {}
    for (const item in collapseItens) {
      newItens = { ...newItens, [item]: value }
    }
    setCollapseItens(newItens)
  }

  const toggleCollapse = useCallback(
    (item) => {
      setCollapseItens({ ...collapseItens, [item]: !collapseItens[item] })
    },
    [collapseItens],
  )

  return (
    <Box height="100%">
      <Box height={HEIGHT_HEADER}>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Tooltip title="Expandir todos" arrow>
              <IconButton size="small" onClick={() => handleCollapseAll(true)}>
                <CollapseOpenIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Retrair todos" arrow>
              <IconButton size="small" onClick={() => handleCollapseAll(false)}>
                <CollapseCloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        </PageHeader>
      </Box>

      <ContainerForm
        handleSubmit={handleValidate}
        onCancel={
          _data?.id && !otherLocale
            ? () =>
                history.push({
                  pathname: '/funcionario',
                })
            : onClickClose
        }
        isLoading={isSubmitting}
        removeHeight={HEIGHT_HEADER}
      >
        <Grid container spacing={0}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CollapseContent
              title="Informações primárias"
              isOpen={collapseItens.primaria}
              onClick={() => toggleCollapse('primaria')}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextField
                    label="Pessoa Física"
                    fullWidth
                    value={data?.pessoaFisica?.nome || ''}
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <Box display="flex" gridGap={theme.spacing(0.5)} alignItems="center">
                    <NumeroInscricaoTextField
                      label="CPF"
                      value={data?.pessoaFisica?.nrInscricao || ''}
                      disabled
                    />
                    <IconButton
                      size="small"
                      title="Copiar para a Área de Transferência"
                      onClick={() => copyToClipboard(data?.pessoaFisica?.nrInscricao)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <MaskedTextField
                    disabled
                    mask="999.99999.99-9"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="PIS-PASEP"
                    value={data?.pessoaFisica?.pis || ''}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          {/* admissao */}
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CollapseContent
              title="Admissão"
              isOpen={collapseItens.admissao}
              onClick={() => toggleCollapse('admissao')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Data Admissão"
                    size="small"
                    value={data?.dtAdmissao || null}
                    disabled
                  />
                </Grid>
                <Grid item xl={2} lg={3} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Opção FGTS"
                    size="small"
                    value={data?.dtOpcaoFGTS || null}
                    disabled
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <TextFieldLocked
                    label="Matrícula"
                    required
                    name="matricula"
                    validationErrors={validationErrors}
                    value={data?.matricula || ''}
                    disabledLocked={data?.reciboEsocial ? true : false}
                    onChange={(e) => {
                      const matricula = e.target.value
                      setData((prev) => ({ ...prev, matricula }))
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <TextField
                    label="Registro Livro"
                    fullWidth
                    value={data.registroLivro || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    onChange={(e) => {
                      const registroLivro = e?.target?.value || null
                      setData({ ...data, registroLivro })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <TextField
                    label="Registro Folha"
                    fullWidth
                    value={data.registroFolha || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    onChange={(e) => {
                      const registroFolha = e?.target?.value || null
                      setData({ ...data, registroFolha })
                    }}
                  />
                </Grid>

                <Grid item xl={8} lg={10} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Categoria"
                    options={CategoriaValues}
                    renderOption={(option) => option.name}
                    optionId="value"
                    disabled
                    value={data.categoria}
                  />
                </Grid>

                <Grid item xl={4} lg={3} md={4} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Tipo de Admissão"
                    options={tipoAdmissaoValues}
                    required
                    optionId="value"
                    renderOption={(option) => option.name}
                    validationErrors={validationErrors}
                    name="tipoAdmissao"
                    value={data.tipoAdmissao}
                    onChange={(e, obj) => {
                      const tipoAdmissao = obj ? obj.value : ''
                      setData({ ...data, tipoAdmissao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                  <TextField
                    label="Indicador de Admissão"
                    fullWidth
                    value={data?.indAdmissao || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      const indAdmissao = e?.target?.value || ''
                      setData({ ...data, indAdmissao })
                    }}
                    select
                  >
                    <MenuItem value="1">Normal</MenuItem>
                    <MenuItem value="2">Decorrente de Ação Fiscal</MenuItem>
                    <MenuItem value="3">Decorrente de Decisão Judicial</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <CheckboxComponent
                    label="Primeiro Emprego"
                    value={data?.indPrimeiroEmprego === 'S'}
                    onChange={(e, value) => {
                      const indPrimeiroEmprego = value ? 'S' : 'N'
                      setData({ ...data, indPrimeiroEmprego })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Regime Trabalhista"
                    fullWidth
                    value={data?.tipoRegimeTrabalhista || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      const tipoRegimeTrabalhista = e?.target?.value || ''
                      setData({ ...data, tipoRegimeTrabalhista })
                    }}
                    select
                  >
                    <MenuItem value="1">CLT</MenuItem>
                    <MenuItem value="2">Estatutário</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador Grau Exposição"
                    options={IndGrauExposicaoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="indGrauExposicao"
                    value={data.indGrauExposicao}
                    onChange={(e, obj) => {
                      const indGrauExposicao = obj ? obj.value : ''
                      setData({ ...data, indGrauExposicao })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador Múltiplos Vínculos"
                    options={IndMultiploVinculoValues}
                    renderOption={(option) => option.name}
                    optionId="value"
                    required
                    validationErrors={validationErrors}
                    name="indMultiplosVinculos"
                    value={data.indMultiplosVinculos}
                    onChange={(e, obj) => {
                      const indMultiplosVinculos = obj ? obj.value : ''
                      setData({ ...data, indMultiplosVinculos })
                    }}
                  />
                </Grid>
                <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Regime Previdência"
                    fullWidth
                    value={data?.tipoRegimePrevidencia || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      const tipoRegimePrevidencia = e?.target?.value || ''
                      setData({ ...data, tipoRegimePrevidencia })
                    }}
                    select
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  >
                    <MenuItem value="1">Regime Geral da Previdência Social - RGPS</MenuItem>
                    <MenuItem value="2">Regime Próprio de Previdência Social - RPPS </MenuItem>
                    <MenuItem value="3">
                      Regime Próprio de Previdência Social no Exterior - RPPE
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Natureza Atividade"
                    options={naturezaAtividadeValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="naturezaAtividade"
                    value={data.naturezaAtividade}
                    onChange={(e, obj) => {
                      const naturezaAtividade = obj ? obj.value : ''
                      setData({ ...data, naturezaAtividade })
                    }}
                    disabled={data?.isFinalizado || hasAdmissaoPreliminar ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Unidade Salário Fixo"
                    options={indUnidadeSalarioValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="unidadeSalarioFixo"
                    value={data.unidadeSalarioFixo}
                    onChange={(e, obj) => {
                      const unidadeSalarioFixo = obj ? obj.value : ''
                      setData({ ...data, unidadeSalarioFixo })
                    }}
                    disabled={data?.isFinalizado || hasAdmissaoPreliminar ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Descrição Salário Variável"
                    fullWidth
                    value={data?.descricaoSalarioVariavel || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const descricaoSalarioVariavel = e?.target?.value || null
                      setData({ ...data, descricaoSalarioVariavel })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <AutoCompleteSindicato
                    name="sindicatoId"
                    label="Sindicato"
                    required
                    value={data?.sindicato || null}
                    onChange={(e, sindicatoObj) => {
                      const sindicato = sindicatoObj || null
                      const sindicatoId = sindicatoObj?.id || ''
                      setData({
                        ...data,
                        sindicatoId,
                        sindicato,
                      })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                    validationErrors={validationErrors}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextField
                    label="Número Processo Trabalhista Admissão Judicial"
                    fullWidth
                    value={data?.nrProcessoTrabalhista || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{
                      maxLength: 20,
                    }}
                    onChange={(e) => {
                      const nrProcessoTrabalhista = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        nrProcessoTrabalhista,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Informações de Transferência" />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Data Transferência"
                    size="small"
                    value={data.dtTransferencia || null}
                    onChange={(date) => {
                      const dtTransferencia = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtTransferencia })
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <NumeroInscricaoTextField
                    label="CNPJ do Empregador Anterior"
                    typeMask={tipoInscricaoConsts.CNPJ_1}
                    value={data?.cnpjEmpregoAnterior || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e, value) => {
                      const cnpjEmpregoAnterior = value
                      setData({ ...data, cnpjEmpregoAnterior })
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <NumeroInscricaoTextField
                    label="CNPJ Sucessora"
                    typeMask={tipoInscricaoConsts.CNPJ_1}
                    value={data?.cnpjSucessora || ''}
                    onChange={(e, value) => {
                      const cnpjSucessora = value
                      setData({ ...data, cnpjSucessora })
                    }}
                  />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <TextField
                    label="Matrícula Emprego Anterior"
                    fullWidth
                    value={data.matriculaEmpregoAnterior || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 30,
                    }}
                    onChange={(e) => {
                      const matriculaEmpregoAnterior = e?.target?.value || null
                      setData({ ...data, matriculaEmpregoAnterior })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          {/* contrato */}
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CollapseContent
              title="Contrato"
              isOpen={collapseItens.contrato}
              onClick={() => toggleCollapse('contrato')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Regime Jornada"
                    fullWidth
                    value={data?.tipoRegimeJornada || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      const tipoRegimeJornada = e?.target?.value || ''
                      setData({ ...data, tipoRegimeJornada })
                    }}
                    select
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  >
                    <MenuItem value="1">Submetido a Horário de Trabalho</MenuItem>
                    <MenuItem value="2">Atividade Externa</MenuItem>
                    <MenuItem value="3">Funções Específicas</MenuItem>
                    <MenuItem value="4">Teletrabalho</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={3} lg={6} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Jornada Parcial"
                    options={jornadaTempoParcialValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="jornadaParcial"
                    value={data.jornadaParcial}
                    onChange={(e, obj) => {
                      const jornadaParcial = obj ? obj.value : ''
                      setData({ ...data, jornadaParcial })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Tipo de Contrato"
                    options={tipoContratoTrabalhoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="tipoContrato"
                    value={data.tipoContrato}
                    onChange={(e, obj) => {
                      const tipoContrato = obj ? obj.value : ''
                      setData({ ...data, tipoContrato })
                    }}
                    disabled={data?.isFinalizado || hasAdmissaoPreliminar ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
                  <DatePicker
                    label="Término Contrato"
                    size="small"
                    value={data.dtTerminoContrato || null}
                    onChange={(date) => {
                      const dtTerminoContrato = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtTerminoContrato })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
                  <CheckboxComponent
                    label="Contrato de Trabalho possuí Cláusula Assecuratória"
                    value={data?.isClausulaAssecuratoria || false}
                    onChange={(e, value) => {
                      const isClausulaAssecuratoria = value
                      setData({ ...data, isClausulaAssecuratoria })
                    }}
                  />
                </Grid>

                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                  <TextField
                    label="Objeto Determinante Para Fim do Contrato"
                    fullWidth
                    value={data.objetoDeterminante || ''}
                    variant="outlined"
                    size="small"
                    multiline
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(e) => {
                      const objetoDeterminante = e.target.value
                      setData({ ...data, objetoDeterminante })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Tipo de Jornada"
                    options={JornadaTipoValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="tipoJornada"
                    value={data.tipoJornada}
                    onChange={(e, obj) => {
                      const tipoJornada = obj ? obj.value : ''
                      const descricaoTipoJornada = ''
                      setData((oldState) => ({ ...oldState, tipoJornada, descricaoTipoJornada }))
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Descrição da Jornada"
                    fullWidth
                    value={data.descricaoTipoJornada || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    name="descricaoTipoJornada"
                    validationErrors={validationErrors}
                    required={JornadaTipoEnum.DemaisTipos_9 === data?.tipoJornada}
                    disabled={
                      JornadaTipoEnum.DemaisTipos_9 !== data?.tipoJornada || data?.isFinalizado
                    }
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                    onChange={(e) => {
                      const descricaoTipoJornada = e.target.value
                      setData({ ...data, descricaoTipoJornada })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Contrato de Experiência / Prazo Determinado" />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <TextField
                    label="Número de Dias"
                    fullWidth
                    value={data?.contratoExperienciaDias || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 6,
                    }}
                    onlyNumber
                    onBlur={() => {
                      if (data?.contratoExperienciaDias && data?.dtAdmissao) {
                        const contratoExperienciaDtVencimento = moment(data?.dtAdmissao)
                          .add(data?.contratoExperienciaDias, 'day')
                          .subtract(1, 'day')
                        setData((oldState) => ({
                          ...oldState,
                          contratoExperienciaDtVencimento,
                        }))
                      }
                    }}
                    onChange={(e) => {
                      const contratoExperienciaDias = e.target.value
                      setData((oldState) => ({
                        ...oldState,
                        contratoExperienciaDias,
                      }))
                    }}
                    disabled={hasAdmissaoPreliminar || data?.isFinalizado}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={8} sm={12} xs={12}>
                  <DatePicker
                    label="Vencimento Experiência"
                    size="small"
                    value={data.contratoExperienciaDtVencimento || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(date) => {
                      const contratoExperienciaDtVencimento = date
                        ? date.format('yyyy-MM-DD')
                        : null
                      setData({
                        ...data,
                        contratoExperienciaDtVencimento,
                      })
                    }}
                    disabled={hasAdmissaoPreliminar || data?.isFinalizado}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <TextField
                    label="Dias Prorrogação"
                    fullWidth
                    value={data.contratoExperienciaDiasProrrogacao || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 6,
                    }}
                    onlyNumber
                    onBlur={() => {
                      if (
                        data?.contratoExperienciaDiasProrrogacao &&
                        data?.contratoExperienciaDtVencimento
                      ) {
                        const contratoExperienciaDtProrrogacao = moment(
                          data?.contratoExperienciaDtVencimento,
                        ).add(data?.contratoExperienciaDiasProrrogacao, 'day')
                        setData((oldState) => ({
                          ...oldState,
                          contratoExperienciaDtProrrogacao,
                        }))
                      }
                    }}
                    onChange={(e) => {
                      const contratoExperienciaDiasProrrogacao = e.target.value
                      setData({
                        ...data,
                        contratoExperienciaDiasProrrogacao,
                      })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={8} sm={12} xs={12}>
                  <DatePicker
                    label="Vencimento Prorrogação do Contrato"
                    size="small"
                    value={data.contratoExperienciaDtProrrogacao || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(date) => {
                      const contratoExperienciaDtProrrogacao = date
                        ? date.format('yyyy-MM-DD')
                        : null
                      setData({
                        ...data,
                        contratoExperienciaDtProrrogacao,
                      })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Descrição Justificativa Prorrogação de Contrato"
                    fullWidth
                    value={data?.justificativaProrrogacaoContrato || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const justificativaProrrogacaoContrato = e?.target?.value || null
                      setData({ ...data, justificativaProrrogacaoContrato })
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Jornada de Trabalho" />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <SimpleHoraTextField
                    label="Horas Semana"
                    value={data?.horasSemana || ''}
                    onChange={(e) => {
                      const horasSemana = e.target.value.replace(':', '.')
                      setData((oldState) => ({ ...oldState, horasSemana }))
                    }}
                    disabled={data?.isFinalizado ? true : false}
                    title={
                      data?.isFinalizado
                        ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                        : ''
                    }
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    label="Dias Semana"
                    fullWidth
                    value={data.diasSemana || ''}
                    inputProps={{
                      maxLength: 9,
                    }}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    onChange={(e) => {
                      const diasSemana = e.target.value
                      setData({ ...data, diasSemana })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <SimpleHoraTextField
                    label="Horas Mês"
                    value={data?.horasMes || ''}
                    onChange={(e) => {
                      const horasMes = e.target.value.replace(':', '.')
                      setData((oldState) => ({ ...oldState, horasMes }))
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Dia Descanso Semanal"
                    options={DayOfWeekValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data.diaDescansoSemanal}
                    onChange={(e, obj) => {
                      const diaDescansoSemanal = obj.value
                      setData({ ...data, diaDescansoSemanal })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador Cálculo DSR"
                    options={indDSRValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="indDSR"
                    value={data.indDSR}
                    onChange={(e, obj) => {
                      const indDSR = obj ? obj.value : ''
                      setData((oldState) => ({ ...oldState, indDSR }))
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    label="Cartão de Ponto"
                    fullWidth
                    value={data.cartaoPonto || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 10,
                    }}
                    onlyNumber
                    onChange={(e) => {
                      const cartaoPonto = e.target.value
                      setData({ ...data, cartaoPonto })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Quadro Horário"
                    fullWidth
                    value={data.quadroHorario || ''}
                    variant="outlined"
                    inputProps={{
                      maxLength: 10,
                    }}
                    size="small"
                    onlyNumber
                    onChange={(e) => {
                      const quadroHorario = e.target.value
                      setData({ ...data, quadroHorario })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Quadro Horário Observação"
                    fullWidth
                    value={data.quadroHorarioObservacao || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 80,
                    }}
                    onChange={(e) => {
                      const quadroHorarioObservacao = e.target.value
                      setData({ ...data, quadroHorarioObservacao })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CheckboxComponent
                    label="Indicador Horário Nortuno"
                    value={data?.isHorarioNoturno || false}
                    onChange={(e, value) => {
                      const isHorarioNoturno = value
                      setData({ ...data, isHorarioNoturno })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <CheckboxComponent
                    label="TeleTrabalho"
                    value={data?.isTeleTrabalho || false}
                    onChange={(e, value) => {
                      const isTeleTrabalho = value
                      setData({ ...data, isTeleTrabalho })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          {/* parametros */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Parâmetros"
              isOpen={collapseItens.parametros}
              onClick={() => toggleCollapse('parametros')}
              top={1}
            >
              <Grid container spacing={1}>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Descontar Sindical"
                    control={
                      <Checkbox
                        name="Descontar Sindical"
                        color="secondary"
                        size="small"
                        checked={data?.isDescontarSindical || false}
                        onChange={(e) => {
                          const isDescontarSindical = e.target.checked
                          setData({ ...data, isDescontarSindical })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Escala Revezamento"
                    control={
                      <Checkbox
                        name="Escala Revezamento"
                        color="secondary"
                        size="small"
                        checked={data?.isEscalaRevezamento || false}
                        onChange={(e) => {
                          const isEscalaRevezamento = e.target.checked
                          setData({ ...data, isEscalaRevezamento })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Rescisão por tarefa, incluir na Média competência da Rescisão"
                    control={
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={data?.isRescisaoMediaVariavel || false}
                        onChange={(e) => {
                          const isRescisaoMediaVariavel = e.target.checked
                          setData({ ...data, isRescisaoMediaVariavel })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Usuário Vale Transporte"
                    control={
                      <Checkbox
                        name="Usuário Vale Transporte"
                        color="secondary"
                        size="small"
                        checked={data?.isUsuarioValeTransporte || false}
                        onChange={(e) => {
                          const isUsuarioValeTransporte = e.target.checked
                          setData({ ...data, isUsuarioValeTransporte })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Etiqueta Cartão de Ponto"
                    control={
                      <Checkbox
                        name="Usuário Vale Transporte"
                        color="secondary"
                        size="small"
                        checked={data?.isEtiquetaCartaoPonto || false}
                        onChange={(e) => {
                          const isEtiquetaCartaoPonto = e.target.checked
                          setData({ ...data, isEtiquetaCartaoPonto })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Horas de sábado compensadas durante a semana"
                    control={
                      <Checkbox
                        name="Usuário Vale Transporte"
                        color="secondary"
                        size="small"
                        checked={data?.isSabadoCompensado || false}
                        onChange={(e) => {
                          const isSabadoCompensado = e.target.checked
                          setData({ ...data, isSabadoCompensado })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Expediente no Feriado"
                    control={
                      <Checkbox
                        name="Usuário Vale Transporte"
                        color="secondary"
                        size="small"
                        checked={data?.isExpedienteFeriado || false}
                        onChange={(e) => {
                          const isExpedienteFeriado = e.target.checked
                          setData({ ...data, isExpedienteFeriado })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <FormControlLabel
                    label="Desconto Vale Transporte"
                    control={
                      <Checkbox
                        name="Usuário Vale Transporte"
                        color="secondary"
                        size="small"
                        checked={data?.isDescontoValeTransporte || false}
                        onChange={(e) => {
                          const isDescontoValeTransporte = e.target.checked
                          setData({ ...data, isDescontoValeTransporte })
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Considerar Salário Família na Pensão Alimentícia"
                    value={data?.isSalarioFamiliaPensaoAlimenticia || false}
                    onChange={(e, value) => {
                      const isSalarioFamiliaPensaoAlimenticia = value
                      setData({ ...data, isSalarioFamiliaPensaoAlimenticia })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Não Contribuinte do I.N.S.S"
                    value={data?.isNaoContribuinteINSS || false}
                    onChange={(e, value) => {
                      const isNaoContribuinteINSS = value
                      setData({ ...data, isNaoContribuinteINSS })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Não Contribuinte do I.R.R.F"
                    value={data?.isNaoContribuinteIRRF || false}
                    onChange={(e, value) => {
                      const isNaoContribuinteIRRF = value
                      setData({ ...data, isNaoContribuinteIRRF })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Não Beneficiário do ATS"
                    value={data?.isATS || false}
                    onChange={(e, value) => {
                      const isATS = value
                      setData({ ...data, isATS })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Pagamento via Cheque"
                    value={data?.isPagamentoCheque || false}
                    onChange={(e, value) => {
                      const isPagamentoCheque = value
                      setData({ ...data, isPagamentoCheque })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Utilizar cálculo simplificado do IR"
                    value={data?.isCalcularIRSimplificado || false}
                    onChange={(e, value) => {
                      const isCalcularIRSimplificado = value
                      setData({ ...data, isCalcularIRSimplificado })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="Não importar Hora Extra do Ponto Eletrônico"
                    value={data?.isImportarHoraExtra || false}
                    onChange={(e, value) => {
                      const isImportarHoraExtra = value
                      setData({ ...data, isImportarHoraExtra })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <CheckboxComponent
                    label="FGTS do mês recolhido em Guia Rescisória"
                    value={data?.isFGTSGuiaRescisoria || false}
                    onChange={(e, value) => {
                      const isFGTSGuiaRescisoria = value
                      setData({ ...data, isFGTSGuiaRescisoria })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          {/* outras */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Outras informações"
              isOpen={collapseItens.outras}
              onClick={() => toggleCollapse('outras')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <CurrencyTextField
                    label="Aliq. Adiantamento Salário"
                    fullWidth
                    value={data.aliqAdiantamentoSalario || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    maximumValue="999"
                    onChange={(e, obj) => {
                      const aliqAdiantamentoSalario = obj || null
                      setData({ ...data, aliqAdiantamentoSalario })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <CurrencyTextField
                    label="Vr. Adiantamento Salário"
                    fullWidth
                    value={data.vrAdiantamentoSalario || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e, obj) => {
                      const vrAdiantamentoSalario = obj || null
                      setData({ ...data, vrAdiantamentoSalario })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador Pensão Alimenícia"
                    options={indPensaoAlimenticiaValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    required
                    validationErrors={validationErrors}
                    name="indPensaoAlimenticia"
                    value={data.indPensaoAlimenticia}
                    onChange={(e, obj) => {
                      const indPensaoAlimenticia = obj ? obj.value : ''
                      setData({ ...data, indPensaoAlimenticia })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <CurrencyTextField
                    label="Vr. Pensão Alimentícia"
                    fullWidth
                    value={data.vrPensaoAlimenticia || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e, obj) => {
                      const vrPensaoAlimenticia = obj || null
                      setData({ ...data, vrPensaoAlimenticia })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <CurrencyTextField
                    label="Aliq. Adicional Tempo de Serviço"
                    fullWidth
                    value={data.aliqAdicionalTempoServico || ''}
                    variant="outlined"
                    size="small"
                    maximumValue="999"
                    onlyNumber
                    onChange={(e, obj) => {
                      const aliqAdicionalTempoServico = obj || null
                      setData({ ...data, aliqAdicionalTempoServico })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Indicador Simples Nacional"
                    options={IndSimplesValues}
                    renderOption={(option) => option.name}
                    optionId="value"
                    required
                    validationErrors={validationErrors}
                    name="indSimples"
                    value={data.indSimples}
                    onChange={(e, obj) => {
                      const indSimples = obj ? obj.value : ''
                      setData({ ...data, indSimples })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Data Início da Inatividade"
                    size="small"
                    value={data?.dtInativo || null}
                    onChange={(date) => {
                      const dtInativo = date ? date.format('yyyy-MM-DD') : null
                      setData((oldState) => ({
                        ...oldState,
                        dtInativo,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Comissionista"
                    options={IndComissionistaValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data.indComissionista}
                    onChange={(e, obj) => {
                      const indComissionista = obj ? obj.value : ''
                      setData({ ...data, indComissionista })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <DatePickerNew
                    label="Data Laudo Moléstia Grave"
                    value={data.dtLaudoMolestiaGrave || null}
                    onChange={(date) => {
                      const dtLaudoMolestiaGrave = date
                      setData({
                        ...data,
                        dtLaudoMolestiaGrave,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Dados para Professor(a)" />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <AutoCompleteTitulacao
                    value={data?.titulacaoId || ''}
                    optionId="id"
                    onChange={(e, titulacao) => {
                      const titulacaoId = titulacao?.id || ''
                      setData((oldState) => ({
                        ...oldState,
                        titulacaoId,
                        titulacao,
                      }))
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <TextField
                    label="Tabela Salário Aula"
                    fullWidth
                    value={data?.tabelaSalarioAulaId || ''}
                    variant="outlined"
                    size="small"
                    disabled
                    onChange={(e, tabelaSalarioAula) => {
                      const tabelaSalarioAulaId = tabelaSalarioAula?.id || ''
                      setData({
                        ...data,
                        tabelaSalarioAulaId,
                        tabelaSalarioAula,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="FGTS/Admissão" />
                </Grid>

                <Grid item lg={4} xs={12}>
                  <TextField
                    label="Admissão FGTS"
                    fullWidth
                    value={data?.admissaoFGTS || ''}
                    variant="outlined"
                    size="small"
                    select
                    onChange={(e) => {
                      const admissaoFGTS = e?.target?.value || ''
                      setData((oldState) => ({ ...oldState, admissaoFGTS }))
                    }}
                  >
                    <MenuItem value="1A">1A - Diretor não-empregado Primeiro Emprego</MenuItem>
                    <MenuItem value="1B">1B - Diretor não-empregado Reemprego</MenuItem>
                    <MenuItem value="1C">
                      1C - Diretor não-empregado Oriundo de outro estabelecimento
                    </MenuItem>
                    <MenuItem value="2A">2A - Trabalhador Rural Primeiro Emprego</MenuItem>
                    <MenuItem value="2B">2B - Trabalhador Rural Reemprego</MenuItem>
                    <MenuItem value="2C">
                      2C - Trabalhador Rural Oriundo de outro estabelecimento
                    </MenuItem>
                    <MenuItem value="3A">3A - Menor aprendiz Primeiro Emprego</MenuItem>
                    <MenuItem value="3B">3B - Menor aprendiz Reemprego</MenuItem>
                    <MenuItem value="3C">
                      3C - Menor aprendiz Oriundo de outro estabelecimento
                    </MenuItem>
                    <MenuItem value="9A">9A - Outros Primeiro Emprego</MenuItem>
                    <MenuItem value="9B">9B - Outros Reemprego</MenuItem>
                    <MenuItem value="9C">9C - Outros Oriundo de outro estabelecimento</MenuItem>
                  </TextField>
                </Grid>

                <Grid item lg={4} xs={12}>
                  <TextField
                    label="Ocorrência FGTS"
                    fullWidth
                    value={data?.ocorrenciaFGTS || ''}
                    variant="outlined"
                    size="small"
                    select
                    onChange={(e) => {
                      const ocorrenciaFGTS = e?.target?.value || ''
                      setData((oldState) => ({ ...oldState, ocorrenciaFGTS }))
                    }}
                  >
                    <MenuItem value="00">
                      00 - Sem exposição a agente nocivo-trabalhador nunca esteve exposto
                    </MenuItem>
                    <MenuItem value="01">
                      01 - Sem exposição a agente nocivo-trabalhador já esteve exposto
                    </MenuItem>
                    <MenuItem value="02">
                      02 - Exposto a agente nocivo/aposent. especial 15 anos de serviço
                    </MenuItem>
                    <MenuItem value="03">
                      03 - Exposto a agente nocivo/aposent. especial 20 anos de serviço
                    </MenuItem>
                    <MenuItem value="04">
                      04 - Exposto a agente nocivo/aposent. especial 25 anos de serviço
                    </MenuItem>
                    <MenuItem value="05">
                      05 - Não exposto a agente nocivo-multiplos vínculos
                    </MenuItem>
                    <MenuItem value="06">
                      06 - Exposto a agente nocivo/aposent. especial 15 anos de serviço-mult.
                      vínculos
                    </MenuItem>
                    <MenuItem value="07">
                      07 - Exposto a agente nocivo/aposent. especial 20 anos de serviço-mult.
                      vínculos
                    </MenuItem>
                    <MenuItem value="08">
                      08 - Exposto a agente nocivo/aposent. especial 25 anos de serviço-mult.
                      vínculos
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item lg={4} xs={12}>
                  <TextField
                    label="Categoria FGTS"
                    fullWidth
                    value={data?.categoriaFGTS || ''}
                    variant="outlined"
                    size="small"
                    select
                    onChange={(e) => {
                      const categoriaFGTS = e?.target?.value || ''
                      setData((oldState) => ({ ...oldState, categoriaFGTS }))
                    }}
                  >
                    <MenuItem value="01">01 - Empregado</MenuItem>
                    <MenuItem value="02">02 - Trabalhador Avulso</MenuItem>
                    <MenuItem value="03">
                      03 - Trabalhador não vinculado ao RGPS, mas com direito ao FGTS
                    </MenuItem>
                    <MenuItem value="04">
                      04 - Empregado sob contrato de trabalho por prazo determinado (Lei 9.601,98)
                    </MenuItem>
                    <MenuItem value="05">
                      05 - Contribuinte individual - Diretor não empregado com FGTS (Lei 8.036/90,
                      art 16)
                    </MenuItem>
                    <MenuItem value="06">06 - Empregado Doméstico</MenuItem>
                    <MenuItem value="07">07 - Menor Aprendiz (Lei 10.097/2000)</MenuItem>
                    <MenuItem value="11">
                      11 - Contribuinte Individual - Diretor não empregado e demais empresários sem
                      FGTS
                    </MenuItem>
                    <MenuItem value="12">12 - Demais Agentes Públicos</MenuItem>
                    <MenuItem value="13">
                      13 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper.
                      máquina com contribuição sobre remuneração
                    </MenuItem>
                    <MenuItem value="14">
                      14 - Contribuinte Individual - Trab. autônomo ou equiparado, inclusive oper.
                      máquina com contribuição sobre salário-base
                    </MenuItem>
                    <MenuItem value="15">
                      15 - Contribuinte Individual - Transportador autônomo com contribuiçào sobre
                      remuneração
                    </MenuItem>
                    <MenuItem value="16">
                      16 - Contribuinte Individual - Transportador autônomo com contribuição sobre
                      salário-base
                    </MenuItem>
                    <MenuItem value="17">
                      17 - Contribuinte Individual - Cooperado que presta serviços a empresas
                      contratentes de Cooperativa de Trabalho
                    </MenuItem>
                    <MenuItem value="18">
                      18 - Contribuinte Individual - Transportador Cooperado que presta serviços a
                      empresas contratantes de Cooperativa de Trabalho
                    </MenuItem>
                    <MenuItem value="19">19 - Agente Político</MenuItem>
                    <MenuItem value="20">
                      20 - Servidor Público Ocupante, exclusivamente, de cargo em comissão, Servidor
                      Público Ocupante de cargo
                    </MenuItem>
                    <MenuItem value="21">
                      21 - Servidor Público titular de cargo efetivo, magistrado, membro do
                      Ministério Público e do Tribunal
                    </MenuItem>
                    <MenuItem value="22">
                      22 - Contrib individ contratado por contrib individ, por prod rural PF, por
                      missao diplomática/consulado
                    </MenuItem>
                    <MenuItem value="23">
                      23 - Contribuinte individual transportador autônomo contratado por contrib
                      individual, por produtor rural
                    </MenuItem>
                    <MenuItem value="24">
                      24 - Contribuinte individual cooperado que presta serviços à entidade
                      beneficente isenta da cota patronal
                    </MenuItem>
                    <MenuItem value="25">
                      25 - Contrib individ transportador cooperado que presta serviços à entidade
                      beneficente isenta cota patronal
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          {data.categoria === CategoriaEnum.Empregado_Aprendiz_103 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollapseContent
                title="Aprendiz"
                isOpen={collapseItens.aprendiz}
                onClick={() => toggleCollapse('aprendiz')}
                top={1}
              >
                <Fieldset
                  disabled={data?.isFinalizado ? true : false}
                  title={
                    data?.isFinalizado
                      ? 'Esse campo pode ser alterado em Alteração Contrato de Trabalho'
                      : ''
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <MUIAutoComplete
                        label="Indicador de Modalidade de Contratação"
                        options={AprendizIndContratacaoValues}
                        optionId="value"
                        name="aprendizIndContratacao"
                        validationErrors={validationErrors}
                        required
                        renderOption={(option) => option.name}
                        value={data.aprendizIndContratacao}
                        onChange={(e, obj) => {
                          const aprendizIndContratacao = obj ? obj.value : ''
                          setData({
                            ...data,
                            aprendizIndContratacao,
                            aprendizCNPJQualificadora: null,
                            aprendizTpInscricaoContratacao: TipoInscricaoEnum.NaoInformar_0,
                            aprendizNrInscricaoContratacao: null,
                          })
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <NumeroInscricaoTextField
                        typeMask={TipoInscricaoEnum.CNPJ_1}
                        label="CNPJ Qualificadora"
                        value={data?.aprendizCNPJQualificadora || ''}
                        disabled={
                          data?.aprendizIndContratacao !==
                          AprendizIndContratacaoEnum.Contratacao_Direta
                        }
                        required={
                          data?.aprendizIndContratacao ===
                          AprendizIndContratacaoEnum.Contratacao_Direta
                        }
                        name="aprendizCNPJQualificadora"
                        validationErrors={validationErrors}
                        onChange={(e, value) => {
                          const aprendizCNPJQualificadora = value || null
                          setData((oldState) => ({
                            ...oldState,
                            aprendizCNPJQualificadora,
                          }))
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <NumeroInscricaoTextField
                        typeMask={TipoInscricaoEnum.CNPJ_1}
                        label="CNPJ Atividades Práticas"
                        value={data?.aprendizCNPJAtividadesPraticas || ''}
                        validationErrors={validationErrors}
                        name="aprendizCNPJAtividadesPraticas"
                        onChange={(e, value) => {
                          const aprendizCNPJAtividadesPraticas = value || null
                          setData((oldState) => ({
                            ...oldState,
                            aprendizCNPJAtividadesPraticas,
                          }))
                        }}
                      />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <MUIAutoComplete
                        label="Tipo de Inscrição Contratante"
                        options={tipoInscricaoValues}
                        optionId="value"
                        renderOption={(option) => option.name}
                        value={data?.aprendizTpInscricaoContratacao || ''}
                        required={
                          data.aprendizIndContratacao ===
                          AprendizIndContratacaoEnum.Contratacao_Indireta
                        }
                        disabled={
                          data.aprendizIndContratacao !==
                          AprendizIndContratacaoEnum.Contratacao_Indireta
                        }
                        name="aprendizTpInscricaoContratacao"
                        validationErrors={validationErrors}
                        onChange={(e, obj) => {
                          const aprendizTpInscricaoContratacao = obj?.value
                          setData((oldState) => ({
                            ...oldState,
                            aprendizTpInscricaoContratacao,
                            aprendizNrInscricaoContratacao: null,
                          }))
                        }}
                      />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <NumeroInscricaoTextField
                        required={
                          data.aprendizIndContratacao ===
                          AprendizIndContratacaoEnum.Contratacao_Indireta
                        }
                        disabled={
                          data.aprendizIndContratacao !==
                          AprendizIndContratacaoEnum.Contratacao_Indireta
                        }
                        name="aprendizNrInscricaoContratacao"
                        validationErrors={validationErrors}
                        typeMask={data?.aprendizTpInscricaoContratacao || undefined}
                        label="Número Inscrição Contratante"
                        value={data?.aprendizNrInscricaoContratacao || ''}
                        onChange={(e, value) => {
                          const aprendizNrInscricaoContratacao = value || null
                          setData((oldState) => ({
                            ...oldState,
                            aprendizNrInscricaoContratacao,
                          }))
                        }}
                      />
                    </Grid>
                  </Grid>
                </Fieldset>
              </CollapseContent>
            </Grid>
          )}
        </Grid>
      </ContainerForm>
    </Box>
  )
}

export default Form
